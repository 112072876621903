// Reducers
import apiSlice from './apiSlice';

// Constants
import API_ENDPOINTS from '@app/constants/apiEndpoints';

export const cartsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentCart: builder.query({
      query: (arg) => ({
        url: API_ENDPOINTS.SHOP.GET_CURRENT_CART,
        params: {
          ...arg,
        },
      }),
      providesTags: (result) =>
        result && result.cartItems
          ? [
              ...result.cartItems.map((item) => ({
                type: 'Cart',
                id: item.id,
              })),
              'Cart',
            ]
          : ['Cart'],
    }),
    addOrUpdateToCart: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.SHOP.ADD_OR_UPDATE_CART,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) =>
        result && result.roomLineItems
          ? [
              ...result.roomLineItems.map((item) => ({
                type: 'Cart',
                cart: item.id,
              })),
              'Cart',
            ]
          : ['Cart'],
    }),
  }),
});

export const { useGetCurrentCartQuery, useAddOrUpdateToCartMutation } =
  cartsApiSlice;
