const ChevronSvg = () => (
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="9953692481543238867 1">
      <path
        id="Vector"
        d="M15.375 30.75L25.625 20.5L15.375 10.25"
        stroke="#918C8C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default ChevronSvg;
