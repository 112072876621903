import { useTranslation } from 'react-i18next';

//Components

//Styles
import './Footer.scss';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="footer-banner">
        <h2>{t('common.footer.save-time-and-money')}</h2>
        <p>{t('common.footer.send-the-best-deals')}</p>
      </div>
      <div className="footer-copyright">
        <p>{t('common.footer.copyright')}.</p>
      </div>
    </div>
  );
};

export default Footer;
