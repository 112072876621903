import Icon from '@ant-design/icons';
import SearchSvg from './Search';
import LanguageSvg from './Language';
import CartSvg from './Cart';
import Cart2Svg from './Cart2';
import Cart3Svg from './Cart3';
import AirportTaxisSvg from './AirportTaxis';
import AttractionsSvg from './Attractions';
import BedGraySvg from './BedGray';
import BedWhiteSvg from './BedWhite';
import CarSvg from './Car';
import FlightsSvg from './Flights';
import FlightsAndHotelSvg from './FlightsAndHotel';
import DateSvg from './Date';
import GroupSvg from './Group';
import LocationSvg from './Location';
import RecommendSvg from './Recommend';
import StarSvg from './Star';
import TopSvg from './Top';
import ChevronSvg from './Chevron';
import BuildingSvg from './Building';
import CandleSvg from './Candle';
import CoconutTreeSvg from './CoconutTree';
import HeartSvg from './Heart';
import PineSvg from './Pine';
import DistanceSvg from './Distance';
import DollarInCircleSvg from './DollarInCircle';
import DollarInTwoCirclesSvg from './DollarInTwoCircles';
import FilterSvg from './Filter';
import SofaSvg from './Sofa';
import SortingSvg from './Sorting';
import TrophySvg from './Trophy';
import WashingMachineSvg from './WashingMachine';
import WifiSvg from './Wifi';
import LikeSvg from './Like';
import GoogleSvg from './Google';
import FaceBookSvg from './FaceBook';
import AppleSvg from './Apple';
import UserIconSvg from './UserIcon';
import LockSvg from './Lock';
import DotSvg from './Dot';
import ArrowDown from './ArrowDown';
import Note from './Note';
import Light from './Light';
import QuestionAndAnswer from './QuestionAndAnswer';
import DotThree from './DotThree';
import StarGray from './StarGray';
import StarSmall from './StarSmall';
import Hours24FrontDesk from './24HoursFrontDesk';
import TickBlue from './TickBlue';
import Window from './Window';
import Shower from './Shower';
import CartBlue from './CartBlue';
import GruopHuman from './GruopHuman';
import BedBlack from './BedBlack';
import SofaBlack from './SofaBlack';
import NonSmocking from './NonSmocking';
import ArrowDownSvg from './ArrowDown';
import ArrowNextBlue from './ArrowNextBlue';
import ArrowPrevBlue from './ArrowPrevBlue';
import DollarInCircleBlue from './DollarInCircleBlue';
import AirportShuttle from './AirportShuttle';
import AirConditioning from './AirConditioning';
import CashSvg from './Cash';
import MoonSvg from './Moon';
import ContactSvg from './Contact';
import TagSvg from './Tag';
import GroupPeopleSvg from './GroupPeople';
import LargeBedSvg from './LargeBed';
import SingleBedSvg from './SingleBed';
import SmokingSvg from './Smoking';
import SinglePeopleSvg from './SinglePeople';
import ShieldSvg from './Shield';
import TickSvg from './Tick';
import AccountSvg from './Account';
import EmailSvg from './Email';
import BlackHeartSvg from './BlackHeart';
import ArrowDownWhiteSvg from './ArrowDownWhite';
import CalendarSvg from './Calendar';
import BarChartSvg from './BarChart';
import MoneyBagSvg from './MoneyBag';
import HandWithCoinSvg from './HandWithCoin';
import ComputerLinkSvg from './ComputerLink';
import InformationSvg from './Information';
import InformationWhiteBlueSvg from './InformationWhiteBlue';
import FaceBookAltSvg from './FaceBookAlt';
import GoogleAltSvg from './GoogleAlt';
import AppleAltSvg from './AppleAlt';
import SignOutSvg from './SignOut';
import ReviewSvg from './Review';
import MyAccountSvg from './MyAccount';
import BookingTripSvg from './BookingTrip';

const CustomIcon = (props) => {
  if (props.name === 'search') {
    return <Icon className="search-icon" component={SearchSvg} {...props} />;
  }

  if (props.name === 'language') {
    return (
      <Icon className="language-icon" component={LanguageSvg} {...props} />
    );
  }

  if (props.name === 'cart') {
    return <Icon className="cart-icon" component={CartSvg} {...props} />;
  }

  if (props.name === 'cart2') {
    return <Icon className="cart2-icon" component={Cart2Svg} {...props} />;
  }

  if (props.name === 'cart3') {
    return <Icon className="cart3-icon" component={Cart3Svg} {...props} />;
  }

  if (props.name === 'arrow-down') {
    return (
      <Icon className="arrow-down-icon" component={ArrowDownSvg} {...props} />
    );
  }
  if (props.name === 'airport-taxis') {
    return (
      <Icon
        className="airport-taxis-icon"
        component={AirportTaxisSvg}
        {...props}
      />
    );
  }

  if (props.name === 'attractions') {
    return (
      <Icon
        className="attractions-icon"
        component={AttractionsSvg}
        {...props}
      />
    );
  }

  if (props.name === 'bed-gray') {
    return <Icon className="bed-gray-icon" component={BedGraySvg} {...props} />;
  }

  if (props.name === 'bed-white') {
    return (
      <Icon className="bed-white-icon" component={BedWhiteSvg} {...props} />
    );
  }

  if (props.name === 'car') {
    return <Icon className="car-icon" component={CarSvg} {...props} />;
  }

  if (props.name === 'flights') {
    return <Icon className="flights-icon" component={FlightsSvg} {...props} />;
  }

  if (props.name === 'flights-and-hotel') {
    return (
      <Icon
        className="flights-and-hotel-icon"
        component={FlightsAndHotelSvg}
        {...props}
      />
    );
  }

  if (props.name === 'date') {
    return <Icon className="date-icon" component={DateSvg} {...props} />;
  }

  if (props.name === 'group') {
    return <Icon className="group-icon" component={GroupSvg} {...props} />;
  }

  if (props.name === 'location') {
    return (
      <Icon className="location-icon" component={LocationSvg} {...props} />
    );
  }

  if (props.name === 'recommend') {
    return (
      <Icon className="recommend-icon" component={RecommendSvg} {...props} />
    );
  }

  if (props.name === 'star') {
    return <Icon className="star-icon" component={StarSvg} {...props} />;
  }

  if (props.name === 'top') {
    return <Icon className="top-icon" component={TopSvg} {...props} />;
  }

  if (props.name === 'chevron') {
    return <Icon className="chevron-icon" component={ChevronSvg} {...props} />;
  }

  if (props.name === 'building') {
    return (
      <Icon className="building-icon" component={BuildingSvg} {...props} />
    );
  }

  if (props.name === 'candle') {
    return <Icon className="candle-icon" component={CandleSvg} {...props} />;
  }

  if (props.name === 'coconut-tree') {
    return (
      <Icon
        className="coconut-tree-icon"
        component={CoconutTreeSvg}
        {...props}
      />
    );
  }

  if (props.name === 'heart') {
    return <Icon className="heart-icon" component={HeartSvg} {...props} />;
  }

  if (props.name === 'pine') {
    return <Icon className="pine-icon" component={PineSvg} {...props} />;
  }

  if (props.name === 'distance') {
    return (
      <Icon className="distance-icon" component={DistanceSvg} {...props} />
    );
  }
  if (props.name === 'dollar-in-circle') {
    return (
      <Icon
        className="dollar-in-circle-icon"
        component={DollarInCircleSvg}
        {...props}
      />
    );
  }

  if (props.name === 'dollar-in-two-circles') {
    return (
      <Icon
        className="dollar-in-two-circles-icon"
        component={DollarInTwoCirclesSvg}
        {...props}
      />
    );
  }

  if (props.name === 'filter') {
    return <Icon className="filter-icon" component={FilterSvg} {...props} />;
  }

  if (props.name === 'sofa') {
    return <Icon className="sofa-icon" component={SofaSvg} {...props} />;
  }

  if (props.name === 'sorting') {
    return <Icon className="sorting-icon" component={SortingSvg} {...props} />;
  }

  if (props.name === 'trophy') {
    return <Icon className="trophy-icon" component={TrophySvg} {...props} />;
  }

  if (props.name === 'washing-machine') {
    return (
      <Icon
        className="washing-machine-icon"
        component={WashingMachineSvg}
        {...props}
      />
    );
  }

  if (props.name === 'wifi') {
    return <Icon className="wifi-icon" component={WifiSvg} {...props} />;
  }

  if (props.name === 'like') {
    return <Icon className="like-icon" component={LikeSvg} {...props} />;
  }

  if (props.name === 'google') {
    return <Icon className="google-icon" component={GoogleSvg} {...props} />;
  }

  if (props.name === 'facebook') {
    return (
      <Icon className="facebook-icon" component={FaceBookSvg} {...props} />
    );
  }

  if (props.name === 'apple') {
    return <Icon className="apple-icon" component={AppleSvg} {...props} />;
  }

  if (props.name === 'user') {
    return <Icon className="user-icon" component={UserIconSvg} {...props} />;
  }

  if (props.name === 'lock') {
    return <Icon className="lock-icon" component={LockSvg} {...props} />;
  }

  if (props.name === 'dot') {
    return <Icon className="dot-icon" component={DotSvg} {...props} />;
  }

  if (props.name === '24-hour-front-desk') {
    return (
      <Icon
        className="24-hour-front-desk"
        component={Hours24FrontDesk}
        {...props}
      />
    );
  }

  if (props.name === 'non-smocking') {
    return <Icon className="non-smocking" component={NonSmocking} {...props} />;
  }

  if (props.name === 'tick-blue') {
    return <Icon className="tick-blue" component={TickBlue} {...props} />;
  }

  if (props.name === 'window') {
    return <Icon className="window" component={Window} {...props} />;
  }

  if (props.name === 'shower') {
    return <Icon className="shower" component={Shower} {...props} />;
  }

  if (props.name === 'cart-blue') {
    return <Icon className="cart-blue" component={CartBlue} {...props} />;
  }

  if (props.name === 'gruop-human') {
    return <Icon className="gruop-human" component={GruopHuman} {...props} />;
  }

  if (props.name === 'bed-black') {
    return <Icon className="bed-black" component={BedBlack} {...props} />;
  }

  if (props.name === 'sofa-black') {
    return <Icon className="sofa-black" component={SofaBlack} {...props} />;
  }

  if (props.name === 'arrow-down') {
    return <Icon className="arrow-down" component={ArrowDown} {...props} />;
  }

  if (props.name === 'note') {
    return <Icon className="note" component={Note} {...props} />;
  }

  if (props.name === 'light') {
    return <Icon className="light" component={Light} {...props} />;
  }

  if (props.name === 'question-answer') {
    return (
      <Icon
        className="question-answer"
        component={QuestionAndAnswer}
        {...props}
      />
    );
  }

  if (props.name === 'dot-three') {
    return <Icon className="dot-three" component={DotThree} {...props} />;
  }

  if (props.name === 'star-gray') {
    return <Icon className="star-gray" component={StarGray} {...props} />;
  }

  if (props.name === 'star-small') {
    return <Icon className="star-small" component={StarSmall} {...props} />;
  }

  if (props.name === 'arrow-prev-blue') {
    return (
      <Icon className="arrow-prev-blue" component={ArrowPrevBlue} {...props} />
    );
  }

  if (props.name === 'arrow-next-blue') {
    return (
      <Icon className="arrow-next-blue" component={ArrowNextBlue} {...props} />
    );
  }

  if (props.name === 'dollar-in-circle-blue') {
    return (
      <Icon
        className="dollar-in-circle-blue"
        component={DollarInCircleBlue}
        {...props}
      />
    );
  }

  if (props.name === 'airport-shuttle') {
    return (
      <Icon className="airport-shuttle" component={AirportShuttle} {...props} />
    );
  }

  if (props.name === 'air-conditioning') {
    return (
      <Icon
        className="air-conditioning"
        component={AirConditioning}
        {...props}
      />
    );
  }

  if (props.name === 'cash') {
    return <Icon className="cash-icon" component={CashSvg} {...props} />;
  }

  if (props.name === 'moon') {
    return <Icon className="moon-icon" component={MoonSvg} {...props} />;
  }

  if (props.name === 'contact') {
    return <Icon className="contact-icon" component={ContactSvg} {...props} />;
  }

  if (props.name === 'tag') {
    return <Icon className="tag-icon" component={TagSvg} {...props} />;
  }

  if (props.name === 'group-people') {
    return (
      <Icon
        className="group-people-icon"
        component={GroupPeopleSvg}
        {...props}
      />
    );
  }

  if (props.name === 'single-people') {
    return (
      <Icon
        className="single-people-icon"
        component={SinglePeopleSvg}
        {...props}
      />
    );
  }

  if (props.name === 'smoking') {
    return <Icon className="smoking-icon" component={SmokingSvg} {...props} />;
  }

  if (props.name === 'large-bed') {
    return (
      <Icon className="large-bed-icon" component={LargeBedSvg} {...props} />
    );
  }

  if (props.name === 'single-bed') {
    return (
      <Icon className="single-bed-icon" component={SingleBedSvg} {...props} />
    );
  }

  if (props.name === 'shield') {
    return <Icon className="shield-icon" component={ShieldSvg} {...props} />;
  }

  if (props.name === 'tick') {
    return <Icon className="tick-icon" component={TickSvg} {...props} />;
  }

  if (props.name === 'account') {
    return <Icon className="account-icon" component={AccountSvg} {...props} />;
  }

  if (props.name === 'email') {
    return <Icon className="email-icon" component={EmailSvg} {...props} />;
  }

  if (props.name === 'black-heart') {
    return (
      <Icon className="black-heart" component={BlackHeartSvg} {...props} />
    );
  }

  if (props.name === 'arrow-down-white') {
    return (
      <Icon
        className="arrow-down-white"
        component={ArrowDownWhiteSvg}
        {...props}
      />
    );
  }

  if (props.name === 'calendar') {
    return (
      <Icon className="calendar-icon" component={CalendarSvg} {...props} />
    );
  }

  if (props.name === 'bar-chart') {
    return (
      <Icon className="bar-chart-icon" component={BarChartSvg} {...props} />
    );
  }

  if (props.name === 'money-bag') {
    return (
      <Icon className="money-bag-icon" component={MoneyBagSvg} {...props} />
    );
  }

  if (props.name === 'hand-with-coin') {
    return (
      <Icon
        className="hand-with-coin-icon"
        component={HandWithCoinSvg}
        {...props}
      />
    );
  }

  if (props.name === 'computer-link') {
    return (
      <Icon
        className="computer-link-icon"
        component={ComputerLinkSvg}
        {...props}
      />
    );
  }

  if (props.name === 'information') {
    return (
      <Icon
        className="information-icon"
        component={InformationSvg}
        {...props}
      />
    );
  }

  if (props.name === 'information-white-blue') {
    return (
      <Icon
        className="information-white-blue-icon"
        component={InformationWhiteBlueSvg}
        {...props}
      />
    );
  }

  if (props.name === 'facebook-alt') {
    return (
      <Icon
        className="facebook-alt-icon"
        component={FaceBookAltSvg}
        {...props}
      />
    );
  }

  if (props.name === 'google-alt') {
    return (
      <Icon className="google-alt-icon" component={GoogleAltSvg} {...props} />
    );
  }

  if (props.name === 'apple-alt') {
    return (
      <Icon className="apple-alt-icon" component={AppleAltSvg} {...props} />
    );
  }

  if (props.name === 'sign-out') {
    return <Icon className="sign-out-icon" component={SignOutSvg} {...props} />;
  }

  if (props.name === 'review') {
    return <Icon className="review-icon" component={ReviewSvg} {...props} />;
  }

  if (props.name === 'my-account') {
    return (
      <Icon className="my-account-icon" component={MyAccountSvg} {...props} />
    );
  }

  if (props.name === 'booking-trip') {
    return (
      <Icon
        className="booking-trip-icon"
        component={BookingTripSvg}
        {...props}
      />
    );
  }

  return <></>;
};

export default CustomIcon;
