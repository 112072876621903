// Libs
import {
  ProConfigProvider,
  ProLayout,
  nanoid,
} from '@ant-design/pro-components';
import {
  Avatar,
  Button,
  ConfigProvider,
  Dropdown,
  Input,
  Layout,
  Menu,
  Space,
  theme,
} from 'antd';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { useAuthUser, useSignOut } from 'react-auth-kit';

// Settings
import defaultSettings from './_defaultSettings';
import defaultProps from './_defaultProps';
import { getRoute } from './routes';

// Styles
import './ProMainLayout.scss';

// Components
import CustomIcon from '@app/components/common/Icons/Index';
import CartBadge from '@app/components/common/Cart/CartBadge';
import Footer from '@app/components/common/Footer/Footer';
import LanguageDropdown from '@app/components/common/LanguageDropdown/LanguageDropdown';

// Hooks
import useLanguage, { getLocalByLanguageCode } from '@app/hooks/useLanguage';

// Constants
import { ROUTES } from '@app/constants';

export default ({ isTransparent = true, isBookingPage = false }) => {
  if (typeof document === 'undefined') {
    return <div />;
  }

  // Using
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);
  const [collapsed, setCollapsed] = useState(true);
  const { t } = useTranslation();
  const { language, setLanguage } = useLanguage();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const authUserData = useAuthUser();
  const user = authUserData();

  // Memos
  const userLoginOptions = useMemo(
    () => [
      {
        key: nanoid(),
        label: (
          <Menu
            mode="vertical"
            items={[
              {
                key: nanoid(),
                label: t('common.profile'),
                onClick: () => {
                  setPathname(ROUTES.USER.PROFILE);
                  navigate(ROUTES.USER.PROFILE);
                },
              },
            ]}
          />
        ),
        icon: <CustomIcon name="my-account" size="20" />,
        danger: false,
      },
      {
        key: nanoid(),
        label: (
          <Menu
            mode="vertical"
            items={[
              {
                key: nanoid(),
                label: t('common.booking-trip'),
                onClick: () => {
                  setPathname(ROUTES.USER.BOOKING_TRIP);
                  navigate(ROUTES.USER.BOOKING_TRIP);
                },
              },
            ]}
          />
        ),
        icon: <CustomIcon name="booking-trip" size="20" />,
        danger: false,
      },
      {
        key: nanoid(),
        label: (
          <Menu
            mode="vertical"
            items={[
              {
                key: nanoid(),
                label: t('common.review'),
                onClick: () => {
                  setPathname(ROUTES.USER.REVIEW);
                  navigate(ROUTES.USER.REVIEW);
                },
              },
            ]}
          />
        ),
        icon: <CustomIcon name="review" size="20" />,
        danger: false,
      },
      {
        key: nanoid(),
        label: (
          <Menu
            mode="vertical"
            items={[
              {
                key: nanoid(),
                label: t('common.logout'),
                onClick: () => {
                  signOut();
                  navigate(ROUTES.USER.LOGIN, {
                    state: {
                      isLogout: true,
                      referrer: pathname,
                    },
                  });
                },
              },
            ]}
          />
        ),
        icon: <CustomIcon name="sign-out" size="20" />,
        danger: true,
      },
    ],
    [navigate, setPathname, setLanguage, signOut, user, language]
  );

  // Rendering
  return (
    <div id="main-layout">
      <ProConfigProvider>
        <ConfigProvider
          locale={getLocalByLanguageCode(language)}
          getTargetContainer={() => {
            return document.getElementById('main-layout') || document.body;
          }}
          theme={{
            algorithm: theme.defaultAlgorithm,
          }}
        >
          <ProLayout
            {...defaultProps}
            {...defaultSettings}
            avatarProps={{
              src: !!user && user.avatar,
              size: 'small',
              title: (!!user && !!user.name && user.name.toUpperCase()) || '',
              render: (_props, defaultDom, siderProps) => {
                if (siderProps.isMobile) {
                  return <></>;
                }

                if (!user || !user.name) {
                  return (
                    <>
                      <div className="register">
                        <Button
                          className="header-button"
                          type="link"
                          onClick={() =>
                            navigate(ROUTES.USER.SIGN_UP, {
                              state: { referrer: pathname },
                            })
                          }
                        >
                          {t('common.header.right.register')}
                        </Button>
                      </div>
                      <div className="sign-in">
                        <Button
                          className="header-button"
                          type="link"
                          onClick={() =>
                            navigate(ROUTES.USER.LOGIN, {
                              state: { referrer: pathname },
                            })
                          }
                        >
                          {t('common.header.right.sign-in')}
                        </Button>
                      </div>
                    </>
                  );
                }

                return (
                  <Dropdown
                    trigger={['click']}
                    className="avatar"
                    rootClassName="root-avatar"
                    arrow
                    menu={{
                      items: userLoginOptions,
                      className: 'menu-avatar',
                    }}
                    icon={<MdOutlineKeyboardArrowDown />}
                  >
                    <Space>
                      {defaultDom}
                      <MdOutlineKeyboardArrowDown />
                    </Space>
                  </Dropdown>
                );
              },
            }}
            menuContentRender={() => (
              <Space direction="vertical" size="large">
                <div className="logo">
                  <span className="logo-best">
                    <Link
                      to={ROUTES.HOME}
                      onClick={() => setPathname(ROUTES.HOME)}
                    >
                      Sapphire
                    </Link>
                  </span>
                  <span className="logo-booking">
                    <Link
                      to={ROUTES.HOME}
                      onClick={() => setPathname(ROUTES.HOME)}
                    >
                      Booking
                    </Link>
                  </span>
                </div>
                {!user || !user.name ? (
                  <Space direction="vertical">
                    <Space>
                      <div className="register">
                        <Button
                          className="header-button"
                          type="link"
                          onClick={() =>
                            navigate(ROUTES.USER.SIGN_UP, {
                              state: { referrer: pathname },
                            })
                          }
                        >
                          {t('common.header.right.register')}
                        </Button>
                      </div>
                      <div className="sign-in">
                        <Button
                          className="header-button"
                          type="link"
                          onClick={() =>
                            navigate(ROUTES.USER.LOGIN, {
                              state: { referrer: pathname },
                            })
                          }
                        >
                          {t('common.header.right.sign-in')}
                        </Button>
                      </div>
                    </Space>
                  </Space>
                ) : (
                  <Dropdown
                    trigger={['click']}
                    className="avatar"
                    rootClassName="root-avatar"
                    placement="bottomCenter"
                    arrow
                    menu={{
                      items: userLoginOptions,
                      className: 'menu-avatar',
                    }}
                    icon={<MdOutlineKeyboardArrowDown />}
                  >
                    <Space>
                      <Avatar src={!!user && user.avatar} size="small" />
                      {(!!user && !!user.name && user.name.toUpperCase()) || ''}
                      <MdOutlineKeyboardArrowDown />
                    </Space>
                  </Dropdown>
                )}
                <LanguageDropdown isHeader />
              </Space>
            )}
            route={getRoute(t)}
            menuItemRender={() => {
              if (isBookingPage) {
                return <></>;
              }

              return (
                <div className="search-wrapper">
                  <Input
                    type="search"
                    className="search-input"
                    placeholder={t('common.header.left.search')}
                    suffix={<CustomIcon name="search" />}
                  />
                </div>
              );
            }}
            headerContentRender={(props) => {
              if (isBookingPage && props.isMobile) {
                return (
                  <>
                    <div className="navigation-search">
                      <div className="logo">
                        <span className="logo-best">
                          <Link
                            to={ROUTES.HOME}
                            onClick={() => setPathname(ROUTES.HOME)}
                          >
                            Sapphire
                          </Link>
                        </span>
                        <span className="logo-booking">
                          <Link
                            to={ROUTES.HOME}
                            onClick={() => setPathname(ROUTES.HOME)}
                          >
                            Booking
                          </Link>
                        </span>
                      </div>
                      <Space style={{ marginLeft: 10 }} className="mini-cart">
                        <CartBadge
                          onClick={() => {
                            setPathname(ROUTES.CART);
                            navigate(ROUTES.CART, {
                              state: { referrer: pathname },
                            });
                          }}
                        />
                      </Space>
                    </div>
                  </>
                );
              }

              if (isBookingPage) {
                return <></>;
              }

              if (props.isMobile) {
                return (
                  <>
                    <div className="navigation-search">
                      <div className="logo">
                        <span className="logo-best">
                          <Link
                            to={ROUTES.HOME}
                            onClick={() => setPathname(ROUTES.HOME)}
                          >
                            Sapphire
                          </Link>
                        </span>
                        <span className="logo-booking">
                          <Link
                            to={ROUTES.HOME}
                            onClick={() => setPathname(ROUTES.HOME)}
                          >
                            Booking
                          </Link>
                        </span>
                      </div>
                      <Space style={{ marginLeft: 10 }} className="mini-cart">
                        <CartBadge
                          onClick={() => {
                            setPathname(ROUTES.CART);
                            navigate(ROUTES.CART, {
                              state: { referrer: pathname },
                            });
                          }}
                        />
                      </Space>
                    </div>
                  </>
                );
              }

              return (
                <>
                  <div className="navigation-search">
                    <div className="search-wrapper">
                      <Input
                        type="search"
                        className="search-input"
                        placeholder={t('common.header.left.search')}
                        suffix={<CustomIcon name="search" />}
                      />
                    </div>
                    <Space>
                      <LanguageDropdown isHeader />
                      <CartBadge
                        onClick={() => {
                          setPathname(ROUTES.CART);
                          navigate(ROUTES.CART, {
                            state: { referrer: pathname },
                          });
                        }}
                      />
                    </Space>
                    {props.isMobile ? (
                      !user || !user.name ? (
                        <>
                          <div className="register">
                            <Button
                              className="header-button"
                              type="link"
                              onClick={() =>
                                navigate(ROUTES.USER.SIGN_UP, {
                                  state: { referrer: pathname },
                                })
                              }
                            >
                              {t('common.header.right.register')}
                            </Button>
                          </div>
                          <div className="sign-in">
                            <Button
                              className="header-button"
                              type="link"
                              onClick={() =>
                                navigate(ROUTES.USER.LOGIN, {
                                  state: { referrer: pathname },
                                })
                              }
                            >
                              {t('common.header.right.sign-in')}
                            </Button>
                          </div>
                        </>
                      ) : (
                        <Dropdown
                          trigger={['click']}
                          className="avatar"
                          rootClassName="root-avatar"
                          arrow
                          menu={{
                            items: userLoginOptions,
                            className: 'menu-avatar',
                          }}
                          icon={<MdOutlineKeyboardArrowDown />}
                        >
                          <Space>
                            <Avatar src={!!user && user.avatar} size="small" />
                            {(!!user &&
                              !!user.name &&
                              user.name.toUpperCase()) ||
                              ''}
                            <MdOutlineKeyboardArrowDown />
                          </Space>
                        </Dropdown>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              );
            }}
            collapsed={collapsed}
            onCollapse={setCollapsed}
            location={{
              pathname,
            }}
            actionsRender={(props) => {
              if (props.isMobile || isBookingPage) return [];
              if (typeof window === 'undefined') return [];
              return [];
            }}
            footerRender={() => (
              <Layout.Footer className="front-footer">
                <Footer />
              </Layout.Footer>
            )}
            className={[
              (isTransparent && 'header-transparent') || '',
              (isBookingPage && 'booking-page') || '',
            ].join(' ')}
            headerTitleRender={() => (
              <>
                <span className="logo-best">
                  <Link
                    to={ROUTES.HOME}
                    onClick={() => setPathname(ROUTES.HOME)}
                  >
                    Sapphire
                  </Link>
                </span>
                <span className="logo-booking">
                  <Link
                    to={ROUTES.HOME}
                    onClick={() => setPathname(ROUTES.HOME)}
                  >
                    Booking
                  </Link>
                </span>
              </>
            )}
          >
            <Outlet />
          </ProLayout>
        </ConfigProvider>
      </ProConfigProvider>
    </div>
  );
};
