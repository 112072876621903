// Libs
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { lazy } from 'react';
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit';

// Layouts
import UserAuthLayout from '@app/layouts/UserAuthLayout/UserAuthLayout';
import ProMainLayout from '@app/layouts/ProMainLayout/ProMainLayout';

// Hocs
import withLoading from '@app/hocs/withLoading.hoc';

// Constants
import { PATH_NAME, ROUTES } from './constants';

// Pages
const Home = lazy(() => import('@app/pages/Home/Home'));
const List = lazy(() => import('@app/pages/List/List'));
const Login = lazy(() => import('@app/pages/Auth/Login/Login'));
const SignUp = lazy(() => import('@app/pages/Auth/SignUp/SignUp'));
const Booking = lazy(() => import('@app/pages/Booking/Booking'));
const UserProfile = lazy(() => import('@app/pages/Profile/Profile'));
const Cart = lazy(() => import('@app/pages/Cart/Cart'));
const PropertyDetail = lazy(() => import('@app/pages/Property/PropertyDetail'));
const NotFound = lazy(() => import('@app/pages/NotFound'));
const BookingTrip = lazy(() => import('@app/pages/Profile/BookingTrip'));
const Review = lazy(() => import('@app/pages/Profile/Review'));

// Pages with loading
const HomePage = withLoading(Home);
const ListPage = withLoading(List);
const LoginPage = withLoading(Login);
const SignUpPage = withLoading(SignUp);
const BookingPage = withLoading(Booking);
const UserProfilePage = withLoading(UserProfile);
const CartPage = withLoading(Cart);
const PropertyDetailPage = withLoading(PropertyDetail);
const NotFoundPage = withLoading(NotFound);
const BookingTripPage = withLoading(BookingTrip);
const ReviewPage = withLoading(Review);

// Common variables
const PrivateUserRoute = ({ Component, isTransparent = false }) => {
  const isAuthenticated = useIsAuthenticated();
  const authUserData = useAuthUser();
  const user = authUserData();

  return isAuthenticated() && !!user && !user.role ? (
    <Component isTransparent={isTransparent} />
  ) : (
    <Navigate to={['', PATH_NAME.USER.INDEX, PATH_NAME.USER.LOGIN].join('/')} />
  );
};

export const MainRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<NotFoundPage />}>
      <Route path={PATH_NAME.HOME} element={<ProMainLayout />}>
        <Route index element={<HomePage />} />
        <Route path={PATH_NAME.SEARCH} element={<ListPage />} />
        <Route path={ROUTES.PROPERTY_DETAIL} element={<PropertyDetailPage />} />
      </Route>

      <Route
        path={PATH_NAME.HOME}
        element={<ProMainLayout isTransparent={false} isBookingPage={true} />}
      >
        <Route path={PATH_NAME.BOOKING.INDEX} element={<BookingPage />} />
      </Route>

      <Route
        path={PATH_NAME.HOME}
        element={<ProMainLayout isTransparent={false} />}
      >
        <Route path={PATH_NAME.BOOKING.CART} element={<CartPage />} />
        <Route path={PATH_NAME.NOT_FOUND} element={<NotFoundPage />} />
      </Route>

      <Route path={PATH_NAME.USER.INDEX} element={<UserAuthLayout />}>
        <Route path={PATH_NAME.USER.SIGN_UP} element={<SignUpPage />} />
      </Route>

      <Route path={ROUTES.USER.LOGIN} element={<LoginPage />} />

      <Route
        path={ROUTES.USER.PROFILE}
        element={<PrivateUserRoute Component={ProMainLayout} />}
      >
        <Route index element={<UserProfilePage />} />
        <Route
          path={PATH_NAME.USER.BOOKING_TRIP}
          element={<BookingTripPage />}
        />
        <Route path={PATH_NAME.USER.REVIEW} element={<ReviewPage />} />
      </Route>
    </Route>
  )
);
