const Hour24FrontDesk = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="18396963651634472858 1" clipPath="url(#clip0_125_334)">
      <path
        id="Vector"
        d="M1.74981 10.5C1.74957 8.85688 2.21209 7.24688 3.08438 5.85439C3.95668 4.4619 5.2035 3.34321 6.68206 2.62643C8.16063 1.90964 9.81116 1.62374 11.4447 1.80146C13.0782 1.97918 14.6286 2.61333 15.9185 3.63131H14.5875C14.4722 3.63242 14.362 3.67901 14.2808 3.76093C14.1997 3.84286 14.1542 3.9535 14.1542 4.0688C14.1542 4.1841 14.1997 4.29474 14.2808 4.37667C14.362 4.45859 14.4722 4.50518 14.5875 4.50629H17.0623C17.1783 4.50629 17.2896 4.4602 17.3717 4.37815C17.4537 4.2961 17.4998 4.18482 17.4998 4.06878V1.59386C17.4987 1.47856 17.4521 1.36836 17.3702 1.28723C17.2883 1.20609 17.1776 1.16058 17.0623 1.16058C16.947 1.16058 16.8364 1.20609 16.7544 1.28723C16.6725 1.36836 16.6259 1.47856 16.6248 1.59386V3.07534C14.7171 1.50162 12.2769 0.722595 9.81019 0.899827C7.34352 1.07706 5.0397 2.19695 3.37658 4.02723C1.71346 5.85751 0.818653 8.25773 0.877752 10.7301C0.93685 13.2024 1.94532 15.5571 3.69399 17.3058C3.77635 17.3863 3.88712 17.4311 4.00227 17.4304C4.11742 17.4298 4.22766 17.3837 4.30909 17.3023C4.39051 17.2209 4.43655 17.1106 4.43721 16.9955C4.43788 16.8803 4.39311 16.7696 4.31263 16.6872C3.49758 15.8766 2.85139 14.9123 2.41148 13.8503C1.97158 12.7883 1.74668 11.6496 1.74981 10.5Z"
        fill="#1E79E4"
      />
      <path
        id="Vector_2"
        d="M6.67627 18.3731C6.42804 18.2524 6.18236 18.118 5.94619 17.9741C5.84716 17.9136 5.72816 17.895 5.61539 17.9222C5.50261 17.9495 5.40529 18.0205 5.34484 18.1195C5.28438 18.2185 5.26575 18.3375 5.29303 18.4503C5.32031 18.5631 5.39127 18.6604 5.4903 18.7209C5.7503 18.8796 6.02072 19.0272 6.29396 19.1601C6.34563 19.1852 6.40175 19.1999 6.45909 19.2033C6.51644 19.2067 6.5739 19.1988 6.6282 19.18C6.68249 19.1612 6.73255 19.1319 6.77552 19.0938C6.81849 19.0557 6.85353 19.0094 6.87864 18.9578C6.90375 18.9061 6.91843 18.85 6.92185 18.7926C6.92528 18.7353 6.91737 18.6778 6.89859 18.6235C6.8798 18.5692 6.85051 18.5192 6.81238 18.4762C6.77424 18.4332 6.72802 18.3982 6.67635 18.3731H6.67627Z"
        fill="#1E79E4"
      />
      <path
        id="Vector_3"
        d="M18.3769 6.68427C18.4019 6.73598 18.4369 6.78225 18.4798 6.82044C18.5228 6.85862 18.5728 6.88798 18.6271 6.90683C18.6813 6.92569 18.7388 6.93367 18.7961 6.93031C18.8535 6.92696 18.9096 6.91235 18.9613 6.8873C19.0131 6.86225 19.0593 6.82727 19.0975 6.78434C19.1357 6.74141 19.1651 6.69138 19.1839 6.63711C19.2028 6.58284 19.2107 6.52538 19.2074 6.46803C19.204 6.41067 19.1894 6.35453 19.1644 6.30283C19.0325 6.03044 18.8852 5.76003 18.7265 5.49892C18.6966 5.44983 18.6573 5.4071 18.611 5.37317C18.5646 5.33924 18.512 5.31478 18.4562 5.30118C18.3434 5.27372 18.2243 5.29218 18.1252 5.35249C18.0261 5.41281 17.9549 5.51004 17.9275 5.62279C17.9 5.73555 17.9185 5.8546 17.9788 5.95374C18.1231 6.1911 18.257 6.43691 18.3769 6.68427Z"
        fill="#1E79E4"
      />
      <path
        id="Vector_4"
        d="M11.5381 19.1887C11.2619 19.2215 10.9825 19.2411 10.7074 19.2477C10.5934 19.2521 10.4857 19.3007 10.4071 19.3833C10.3286 19.4659 10.2853 19.5759 10.2867 19.69C10.288 19.804 10.3338 19.9129 10.4142 19.9937C10.4947 20.0744 10.6035 20.1206 10.7175 20.1223C10.7209 20.1223 10.7246 20.1223 10.728 20.1221C11.0305 20.115 11.3374 20.0934 11.6408 20.0576C11.7559 20.0438 11.8609 19.985 11.9327 19.8939C12.0044 19.8028 12.0371 19.687 12.0235 19.5719C12.0099 19.4567 11.9512 19.3517 11.8602 19.2798C11.7692 19.2079 11.6534 19.1751 11.5383 19.1886L11.5381 19.1887Z"
        fill="#1E79E4"
      />
      <path
        id="Vector_5"
        d="M9.04795 19.1293C8.77581 19.0838 8.50215 19.0246 8.23469 18.953C8.12257 18.923 8.00313 18.9388 7.90265 18.9969C7.80218 19.055 7.72889 19.1506 7.69892 19.2627C7.66895 19.3748 7.68475 19.4943 7.74285 19.5948C7.80094 19.6952 7.89657 19.7685 8.00869 19.7985C8.30306 19.8771 8.60428 19.9422 8.90398 19.9922C8.96065 20.0017 9.01863 19.9999 9.0746 19.9869C9.13057 19.974 9.18345 19.9501 9.2302 19.9168C9.27696 19.8834 9.31668 19.8411 9.3471 19.7924C9.37752 19.7436 9.39804 19.6894 9.40749 19.6327C9.41694 19.576 9.41514 19.5181 9.40219 19.4621C9.38924 19.4061 9.36539 19.3532 9.332 19.3065C9.29861 19.2597 9.25634 19.22 9.20761 19.1896C9.15887 19.1592 9.10462 19.1386 9.04795 19.1292V19.1293Z"
        fill="#1E79E4"
      />
      <path
        id="Vector_6"
        d="M19.6954 10.2891C19.5794 10.2863 19.467 10.3296 19.383 10.4096C19.299 10.4896 19.2501 10.5996 19.2472 10.7156C19.2401 10.9923 19.2201 11.2717 19.1871 11.5464C19.1734 11.6616 19.206 11.7776 19.2778 11.8688C19.3495 11.96 19.4545 12.019 19.5697 12.0328C19.5872 12.0348 19.6047 12.0358 19.6223 12.0358C19.7292 12.0356 19.8324 11.9963 19.9123 11.9253C19.9923 11.8543 20.0434 11.7564 20.0561 11.6503C20.092 11.3484 20.1143 11.0414 20.1218 10.7375C20.1232 10.68 20.1134 10.6228 20.0927 10.5692C20.0721 10.5156 20.0411 10.4666 20.0015 10.4249C19.9619 10.3833 19.9145 10.3498 19.8619 10.3265C19.8094 10.3032 19.7528 10.2905 19.6954 10.2891Z"
        fill="#1E79E4"
      />
      <path
        id="Vector_7"
        d="M18.3526 15.2933C18.2566 15.2282 18.1387 15.2039 18.0248 15.2256C17.9108 15.2473 17.8102 15.3134 17.7449 15.4093C17.5893 15.6381 17.4212 15.8622 17.2448 16.0751C17.2073 16.1192 17.1789 16.1704 17.1613 16.2256C17.1437 16.2808 17.1372 16.3389 17.1423 16.3966C17.1473 16.4543 17.1638 16.5104 17.1907 16.5617C17.2176 16.613 17.2545 16.6584 17.2991 16.6953C17.3437 16.7323 17.3952 16.76 17.4506 16.7768C17.506 16.7937 17.5642 16.7994 17.6219 16.7936C17.6795 16.7878 17.7354 16.7706 17.7863 16.743C17.8372 16.7154 17.8822 16.678 17.9185 16.6329C18.1125 16.3988 18.2977 16.1525 18.4686 15.9008C18.5009 15.8533 18.5235 15.7999 18.5351 15.7437C18.5468 15.6875 18.5472 15.6295 18.5365 15.573C18.5257 15.5166 18.5039 15.4629 18.4724 15.4149C18.4408 15.3669 18.4002 15.3256 18.3526 15.2933Z"
        fill="#1E79E4"
      />
      <path
        id="Vector_8"
        d="M18.9556 8.24284C19.0265 8.50924 19.0855 8.78289 19.131 9.0563C19.1479 9.15844 19.2005 9.25125 19.2794 9.31825C19.3584 9.38525 19.4585 9.4221 19.562 9.42225C19.5861 9.4222 19.6102 9.42019 19.634 9.41626C19.6907 9.40686 19.745 9.38638 19.7938 9.356C19.8425 9.32561 19.8848 9.28592 19.9183 9.23918C19.9517 9.19244 19.9756 9.13957 19.9886 9.0836C20.0016 9.02762 20.0034 8.96964 19.994 8.91295C19.9442 8.61219 19.8791 8.31085 19.8012 8.01767C19.7713 7.90554 19.6982 7.80987 19.5978 7.7517C19.4974 7.69353 19.378 7.67762 19.2658 7.70749C19.1537 7.73735 19.058 7.81052 18.9999 7.91092C18.9417 8.01132 18.9258 8.13072 18.9557 8.24284H18.9556Z"
        fill="#1E79E4"
      />
      <path
        id="Vector_9"
        d="M16.0678 17.2507C15.8539 17.4276 15.6299 17.5957 15.4019 17.7502C15.3544 17.7824 15.3137 17.8237 15.2821 17.8717C15.2505 17.9197 15.2286 17.9734 15.2178 18.0298C15.207 18.0862 15.2074 18.1442 15.2189 18.2005C15.2305 18.2568 15.2531 18.3102 15.2853 18.3577C15.3176 18.4053 15.3588 18.446 15.4068 18.4776C15.4548 18.5092 15.5085 18.531 15.5649 18.5419C15.6214 18.5527 15.6793 18.5523 15.7356 18.5407C15.7919 18.5292 15.8453 18.5066 15.8929 18.4744C16.1437 18.3045 16.39 18.1197 16.6254 17.9254C16.7148 17.8514 16.7712 17.745 16.7822 17.6294C16.7932 17.5139 16.7578 17.3987 16.6839 17.3093C16.61 17.2198 16.5035 17.1634 16.388 17.1524C16.2724 17.1414 16.1573 17.1768 16.0678 17.2507Z"
        fill="#1E79E4"
      />
      <path
        id="Vector_10"
        d="M19.3834 12.8877C19.2728 12.8526 19.1529 12.8627 19.0498 12.916C18.9468 12.9692 18.8691 13.0611 18.8337 13.1716C18.75 13.4341 18.6522 13.6963 18.543 13.9511C18.5191 14.0041 18.5061 14.0613 18.5045 14.1194C18.503 14.1774 18.5131 14.2353 18.5342 14.2894C18.5553 14.3436 18.5869 14.393 18.6273 14.4347C18.6677 14.4765 18.716 14.5098 18.7694 14.5327C18.8228 14.5556 18.8802 14.5677 18.9384 14.5681C18.9965 14.5686 19.0541 14.5575 19.1078 14.5354C19.1616 14.5134 19.2104 14.4808 19.2515 14.4397C19.2925 14.3986 19.325 14.3497 19.3469 14.2959C19.4674 14.0154 19.5751 13.7264 19.6671 13.4373C19.6846 13.3826 19.6911 13.325 19.6863 13.2677C19.6815 13.2105 19.6655 13.1547 19.6391 13.1037C19.6128 13.0526 19.5766 13.0073 19.5327 12.9702C19.4889 12.9332 19.4381 12.9051 19.3834 12.8877Z"
        fill="#1E79E4"
      />
      <path
        id="Vector_11"
        d="M13.9427 18.5465C13.6904 18.6546 13.4281 18.7522 13.163 18.8368C13.1082 18.8543 13.0575 18.8823 13.0136 18.9194C12.9697 18.9564 12.9335 19.0018 12.9072 19.0528C12.8808 19.1039 12.8648 19.1596 12.8599 19.2169C12.8551 19.2741 12.8616 19.3317 12.8791 19.3865C12.8965 19.4412 12.9246 19.492 12.9616 19.5358C12.9987 19.5797 13.0441 19.6159 13.0951 19.6423C13.1461 19.6686 13.2019 19.6847 13.2591 19.6895C13.3163 19.6943 13.374 19.6878 13.4287 19.6704C13.7203 19.5774 14.0091 19.47 14.2871 19.351C14.3937 19.3053 14.4779 19.2191 14.521 19.1114C14.5642 19.0036 14.5627 18.8832 14.517 18.7765C14.4714 18.6698 14.3852 18.5856 14.2774 18.5425C14.1697 18.4994 14.0492 18.5008 13.9425 18.5465H13.9427Z"
        fill="#1E79E4"
      />
      <path
        id="Vector_12"
        d="M6.80021 9.60621C6.80021 8.88918 6.32874 8.31463 5.59903 8.14237C5.19816 8.04742 4.77693 8.09116 4.40412 8.26644C4.03131 8.44173 3.72892 8.73822 3.54632 9.1075C3.52024 9.15894 3.50459 9.21502 3.50026 9.27252C3.49593 9.33003 3.50301 9.38782 3.52109 9.44258C3.53917 9.49733 3.5679 9.54798 3.60562 9.5916C3.64334 9.63521 3.68931 9.67095 3.74089 9.69674C3.79247 9.72253 3.84863 9.73787 3.90616 9.74188C3.96369 9.74588 4.02144 9.73848 4.0761 9.72009C4.13075 9.70171 4.18124 9.6727 4.22464 9.63473C4.26805 9.59677 4.30352 9.5506 4.32902 9.49888C4.42243 9.30502 4.57956 9.1491 4.77414 9.05719C4.96872 8.96529 5.18895 8.94297 5.39801 8.99397C5.52172 9.02314 5.92523 9.15365 5.92523 9.60621C5.92523 10.0625 5.39822 10.4145 4.84024 10.7871C4.21196 11.2065 3.50018 11.6818 3.50018 12.4688C3.50018 12.5848 3.54627 12.6961 3.62831 12.7781C3.71035 12.8602 3.82162 12.9063 3.93765 12.9063H6.3627C6.478 12.9052 6.58819 12.8586 6.66933 12.7767C6.75046 12.6947 6.79598 12.5841 6.79598 12.4688C6.79598 12.3535 6.75046 12.2429 6.66933 12.1609C6.58819 12.079 6.478 12.0324 6.3627 12.0313H4.62938C4.81268 11.8576 5.07178 11.6846 5.32599 11.5148C6.01714 11.0533 6.80021 10.5304 6.80021 9.60621Z"
        fill="#1E79E4"
      />
      <path
        id="Vector_13"
        d="M10.9374 11.0468C10.9374 10.9308 10.8913 10.8195 10.8092 10.7375C10.7272 10.6555 10.6159 10.6094 10.4999 10.6094H10.1444V8.28513C10.1444 8.19453 10.1162 8.10616 10.0639 8.03223C10.0115 7.9583 9.93742 7.90245 9.85193 7.87241C9.76645 7.84237 9.67375 7.8396 9.58663 7.8645C9.4995 7.88939 9.42226 7.94072 9.36556 8.0114L7.15071 10.7732C7.09919 10.8376 7.06688 10.9152 7.05752 10.997C7.04816 11.0789 7.06212 11.1618 7.09779 11.2361C7.13346 11.3104 7.1894 11.3732 7.25916 11.4171C7.32891 11.461 7.40966 11.4843 7.49209 11.4844H9.26942V12.7148C9.26886 12.7726 9.27976 12.83 9.3015 12.8835C9.32323 12.9371 9.35537 12.9858 9.39605 13.0269C9.43673 13.068 9.48515 13.1006 9.5385 13.1229C9.59186 13.1451 9.6491 13.1566 9.70691 13.1566C9.76472 13.1566 9.82196 13.1451 9.87532 13.1229C9.92867 13.1006 9.97709 13.068 10.0178 13.0269C10.0585 12.9858 10.0906 12.9371 10.1123 12.8835C10.1341 12.83 10.145 12.7726 10.1444 12.7148V11.4844H10.4999C10.6159 11.4844 10.7272 11.4383 10.8093 11.3562C10.8913 11.2742 10.9374 11.1629 10.9374 11.0468ZM9.26942 10.6094H8.40399L9.26942 9.52992V10.6094Z"
        fill="#1E79E4"
      />
      <path
        id="Vector_14"
        d="M13.1767 7.61009C13.1203 7.59871 13.0623 7.59856 13.0059 7.60965C12.9495 7.62075 12.8958 7.64288 12.848 7.67476C12.8001 7.70665 12.7591 7.74766 12.7271 7.79546C12.6952 7.84325 12.673 7.89688 12.6618 7.95327L11.6775 12.8751C11.6547 12.9889 11.678 13.1071 11.7424 13.2036C11.8067 13.3002 11.9068 13.3672 12.0206 13.39C12.0489 13.3956 12.0778 13.3985 12.1067 13.3985C12.2077 13.3984 12.3056 13.3633 12.3837 13.2992C12.4619 13.2351 12.5154 13.146 12.5352 13.0469L13.5196 8.125C13.5309 8.06867 13.531 8.01067 13.5199 7.9543C13.5088 7.89793 13.4867 7.84431 13.4548 7.79649C13.423 7.74867 13.382 7.7076 13.3343 7.67561C13.2866 7.64363 13.233 7.62137 13.1767 7.61009Z"
        fill="#1E79E4"
      />
      <path
        id="Vector_15"
        d="M15.4218 12.9065C15.5082 12.9064 15.5927 12.8808 15.6646 12.8329C15.7365 12.785 15.7925 12.7168 15.8258 12.6371L17.4664 8.69959C17.4942 8.63311 17.5051 8.56081 17.4981 8.48911C17.4912 8.4174 17.4667 8.34852 17.4267 8.28858C17.3868 8.22863 17.3327 8.17948 17.2691 8.1455C17.2056 8.11151 17.1347 8.09373 17.0627 8.09375H14.8478C14.7325 8.09487 14.6223 8.14145 14.5412 8.22338C14.4601 8.3053 14.4146 8.41594 14.4146 8.53124C14.4146 8.64655 14.4601 8.75719 14.5412 8.83911C14.6223 8.92104 14.7325 8.96762 14.8478 8.96874H16.4064L15.0183 12.3006C14.9907 12.3671 14.9799 12.4393 14.9868 12.511C14.9938 12.5826 15.0183 12.6515 15.0582 12.7114C15.0981 12.7713 15.1522 12.8205 15.2156 12.8545C15.279 12.8886 15.3499 12.9065 15.4218 12.9066V12.9065Z"
        fill="#1E79E4"
      />
    </g>
    <defs>
      <clipPath id="clip0_125_334">
        <rect width="21" height="21" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Hour24FrontDesk;
