const MoneyBagSvg = () => (
  <svg
    width="63"
    height="66"
    viewBox="0 0 63 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_705_581)">
      <path
        d="M26.4172 41.3561L28.4414 39.2509L24.768 35.4316L22.7437 37.5396C21.565 36.7274 20.2503 36.123 18.8455 35.744V32.597H12.3245V35.744C10.8883 36.1297 9.54363 36.7518 8.34529 37.5898L6.40206 35.5702L2.72862 39.3867L4.70451 41.4403C3.94787 42.6437 3.38202 43.9829 3.02657 45.4104H0V52.181H3.02526C3.38986 53.6425 3.9727 55.0129 4.75287 56.238L2.72731 58.3432L6.40075 62.1584L8.42631 60.0546C9.60766 60.8681 10.9275 61.4753 12.3376 61.8528V64.993H18.8324V61.8528C20.2098 61.4848 21.5009 60.8912 22.6614 60.1062L24.768 62.2943L28.4414 58.4777L26.3675 56.3195C27.1777 55.0672 27.7788 53.6601 28.1487 52.1607H31.17V45.4321H28.1487C27.7867 43.9639 27.2025 42.5867 26.4172 41.3561ZM15.585 54.3487C12.6342 54.3487 10.2428 51.8632 10.2428 48.7964C10.2428 45.7296 12.6355 43.2427 15.585 43.2427C18.5345 43.2427 20.9272 45.7296 20.9272 48.7964C20.9272 51.8632 18.5358 54.3487 15.585 54.3487Z"
        fill="#B2BCD1"
      />
      <path
        d="M38.8829 44.9934C37.086 44.9934 35.4551 44.389 34.2908 43.7248L33.4609 47.0918C34.5129 47.7234 36.3098 48.2408 38.1628 48.3278V51.0605H40.8758V48.1267C44.0592 47.5536 45.7998 45.3655 45.7998 42.8053C45.7998 40.2193 44.4721 38.6383 41.1803 37.4309C38.8293 36.5073 37.8623 35.9069 37.8623 34.9576C37.8623 34.1521 38.4412 33.3467 40.2381 33.3467C42.2309 33.3467 43.5051 34.0082 44.2225 34.3246L45.0262 31.0758C44.1127 30.6154 42.8674 30.2133 41.0143 30.1264V27.5961H38.304V30.3274C35.3427 30.9318 33.6282 32.9162 33.6282 35.4452C33.6282 38.2349 35.6472 39.6719 38.6085 40.7096C40.6549 41.4281 41.5409 42.118 41.5409 43.2114C41.5396 44.3591 40.4602 44.9934 38.8829 44.9934Z"
        fill="#3366CC"
      />
      <path
        d="M61.3744 33.1552L61.3966 33.1457C61.3966 33.1457 61.2568 32.6893 60.9314 31.8975C60.9079 31.8432 60.8856 31.7902 60.8621 31.7372C60.6491 31.2279 60.3629 30.6004 59.9931 29.8602C59.9578 29.7923 59.9225 29.7257 59.8872 29.6592C57.8159 25.6009 53.2604 18.6428 44.2212 12.2605L52.2725 0H27.7044L34.9768 12.3583C31.8078 14.7624 23.8088 21.3144 19.7329 28.976H21.7245H25.4319C30.3899 22.3697 39.2044 14.0914 39.2044 14.0914C39.2044 14.0914 57.0645 27.5037 57.7179 40.6335C57.7715 43.5197 57.3363 55.3483 42.5799 59.7055C40.1845 60.1007 36.5908 60.3832 32.7788 59.1676V64.0843C34.9455 64.7892 37.2389 65.194 39.6304 65.194C52.3862 65.194 62.7269 54.4465 62.7269 41.189C62.7269 38.3667 62.2343 35.6706 61.3744 33.1552Z"
        fill="#3366CC"
      />
    </g>
    <defs>
      <clipPath id="clip0_705_581">
        <rect width="62.7269" height="65.194" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default MoneyBagSvg;
