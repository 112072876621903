const DistanceSvg = () => (
  <svg
    width="18"
    height="26"
    viewBox="0 0 18 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="3239396491579886886 1">
      <g id="Group">
        <g id="Group_2">
          <g id="Group_3">
            <path
              id="Vector"
              d="M15 3.80801C13.3458 3.80801 12 5.18255 12 6.872C12 8.33748 14.1259 11.3035 14.624 11.9786C14.6238 12.1966 14.624 12.4108 14.6188 12.6073C14.6133 12.8186 14.7766 12.9947 14.9839 13.0004C14.9872 13.0004 14.9905 13.0004 14.9941 13.0004C15.1963 13.0004 15.3633 12.8354 15.3687 12.6275C15.3739 12.4259 15.3738 12.2057 15.3739 11.9814C15.8687 11.311 17.9999 8.33918 17.9999 6.872C18 5.18255 16.6542 3.80801 15 3.80801ZM15 11.2099C14.1756 10.0516 12.75 7.8471 12.75 6.872C12.75 5.60481 13.7593 4.574 15 4.574C16.2407 4.574 17.25 5.60481 17.25 6.872C17.25 7.8471 15.8243 10.0515 15 11.2099Z"
              fill="#1B1B47"
            />
            <path
              id="Vector_2"
              d="M15 6.10601C14.5865 6.10601 14.25 6.44974 14.25 6.872C14.25 7.29426 14.5865 7.63799 15 7.63799C15.4134 7.63799 15.75 7.29429 15.75 6.872C15.75 6.44974 15.4134 6.10601 15 6.10601Z"
              fill="#1B1B47"
            />
            <path
              id="Vector_3"
              d="M4.5 12.234C3.25928 12.234 2.25 13.2648 2.25 14.532C2.25 15.7992 3.25928 16.83 4.5 16.83C5.74072 16.83 6.75 15.7992 6.75 14.532C6.75 13.2648 5.74072 12.234 4.5 12.234ZM4.5 16.064C3.67275 16.064 2.99999 15.3769 2.99999 14.532C2.99999 13.687 3.67275 13 4.5 13C5.32725 13 6.00001 13.687 6.00001 14.532C6.00001 15.377 5.32725 16.064 4.5 16.064Z"
              fill="#1B1B47"
            />
            <path
              id="Vector_4"
              d="M10.1803 17.6117C9.98618 17.5347 9.7701 17.6331 9.69505 17.8305C9.52696 18.2741 9.31678 18.7914 9.0333 19.2652C8.92526 19.4459 8.98129 19.6815 9.15782 19.7919C9.21899 19.8301 9.28636 19.848 9.353 19.848C9.47937 19.848 9.60277 19.7829 9.67346 19.6647C9.98769 19.1389 10.2143 18.5823 10.3945 18.1073C10.4692 17.9102 10.3733 17.6884 10.1803 17.6117Z"
              fill="#1B1B47"
            />
            <path
              id="Vector_5"
              d="M8.06576 20.4068C7.69003 20.7108 7.26304 20.9397 6.75985 21.1069C6.56282 21.1724 6.45514 21.3886 6.51925 21.5898C6.5709 21.7514 6.71773 21.8542 6.87593 21.8542C6.9144 21.8542 6.95356 21.8483 6.99202 21.8355C7.57319 21.6425 8.09101 21.3639 8.53158 21.0074C8.69384 20.8758 8.72129 20.6349 8.59275 20.4692C8.46309 20.3039 8.22763 20.2744 8.06576 20.4068Z"
              fill="#1B1B47"
            />
            <path
              id="Vector_6"
              d="M11.4786 14.8465C10.8597 15.2053 10.5159 15.828 10.3108 16.2648C10.221 16.4556 10.2997 16.6845 10.4865 16.7757C10.5389 16.8016 10.5942 16.8135 10.6484 16.8135C10.7883 16.8135 10.9223 16.7335 10.9868 16.5962C11.2472 16.0415 11.513 15.7075 11.8492 15.5123C12.029 15.4079 12.0924 15.1742 11.9898 14.9902C11.8881 14.8062 11.6599 14.7415 11.4786 14.8465Z"
              fill="#1B1B47"
            />
            <path
              id="Vector_7"
              d="M14.8609 13.8984C14.6906 13.7806 14.4566 13.8258 14.3402 14.0009C14.2168 14.1872 13.9641 14.4433 13.1998 14.5133C12.9936 14.5323 12.8413 14.7182 12.8599 14.9292C12.8775 15.1282 13.0412 15.2778 13.2331 15.2778C13.2445 15.2778 13.2559 15.2774 13.2672 15.2762C14.0791 15.2018 14.6332 14.925 14.9613 14.4302C15.0774 14.2552 15.0323 14.017 14.8609 13.8984Z"
              fill="#1B1B47"
            />
            <path
              id="Vector_8"
              d="M5.38857 21.431C6.57184 20.1422 9 17.1774 9 14.532C9 11.955 7.0232 9.93604 4.5 9.93604C1.9768 9.93604 0 11.955 0 14.532C0 17.9524 4.06932 21.9201 4.24255 22.0873C4.3147 22.1572 4.40738 22.192 4.5 22.192C4.7732 22.192 5.03282 22.1823 5.27893 22.1632C5.48548 22.1471 5.64036 21.9635 5.62461 21.7526C5.61336 21.6015 5.51772 21.4838 5.38857 21.431ZM4.5 21.2689C3.61412 20.3458 0.74999 17.1576 0.74999 14.532C0.74999 12.3843 2.39723 10.702 4.5 10.702C6.60277 10.702 8.25001 12.3843 8.25001 14.532C8.25001 17.1576 5.38588 20.3458 4.5 21.2689Z"
              fill="#1B1B47"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default DistanceSvg;
