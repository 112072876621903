const DollarInCircleBlue = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="18562337021586788050 1" clipPath="url(#clip0_70_439)">
      <g id="Layer_1">
        <path
          id="Vector"
          d="M9.9999 0.400002C4.7067 0.400002 0.399902 4.7068 0.399902 10C0.399902 15.2932 4.7067 19.6 9.9999 19.6C15.2931 19.6 19.5999 15.2932 19.5999 10C19.5999 4.7068 15.2931 0.400002 9.9999 0.400002ZM9.9999 18.8C5.1475 18.8 1.1999 14.8524 1.1999 10C1.1999 5.1476 5.1475 1.2 9.9999 1.2C14.8523 1.2 18.7999 5.1476 18.7999 10C18.7999 14.8524 14.8523 18.8 9.9999 18.8Z"
          fill="#1E79E4"
        />
        <path
          id="Vector_2"
          d="M9.1999 5.2H10.7999C11.9027 5.2 12.7999 6.0972 12.7999 7.2H13.5999C13.5999 5.656 12.3435 4.4 10.7999 4.4H10.3999V3.2H9.5999V4.4H9.1999C7.6563 4.4 6.3999 5.656 6.3999 7.2V7.6C6.3999 9.144 7.6563 10.4 9.1999 10.4H10.7999C11.9027 10.4 12.7999 11.2972 12.7999 12.4V12.8C12.7999 13.9028 11.9027 14.8 10.7999 14.8H9.1999C8.0971 14.8 7.1999 13.9028 7.1999 12.8H6.3999C6.3999 14.344 7.6563 15.6 9.1999 15.6H9.5999V16.8H10.3999V15.6H10.7999C12.3435 15.6 13.5999 14.344 13.5999 12.8V12.4C13.5999 10.856 12.3435 9.6 10.7999 9.6H9.1999C8.0971 9.6 7.1999 8.7028 7.1999 7.6V7.2C7.1999 6.0972 8.0971 5.2 9.1999 5.2Z"
          fill="#1E79E4"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_70_439">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DollarInCircleBlue;
