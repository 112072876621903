const StarGray = () => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="20627715151555590645 5" clipPath="url(#clip0_568_307)">
      <path
        id="Vector"
        d="M6.33251 0.293701L8.18639 4.05052L12.3325 4.65293L9.33251 7.5772L10.0405 11.7064L6.33251 9.75688L2.62428 11.7064L3.33253 7.5772L0.33252 4.65293L4.47839 4.05052L6.33251 0.293701Z"
        fill="#D2D2D2"
      />
    </g>
    <defs>
      <clipPath id="clip0_568_307">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0.33252)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default StarGray;
