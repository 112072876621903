const TrophySvg = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="18126058801577437001 1">
      <path
        id="Vector"
        d="M11.7812 2.4375H9.75V2.03125C9.75 1.7875 9.5875 1.625 9.34375 1.625H3.65625C3.4125 1.625 3.25 1.7875 3.25 2.03125V2.4375H1.21875C0.975 2.4375 0.8125 2.6 0.8125 2.84375V3.94062C0.8125 5.72813 2.19375 7.19062 3.98125 7.27187C4.30625 7.71875 4.75312 8.04375 5.28125 8.24687V9.34375H4.875C4.18438 9.34375 3.65625 9.87187 3.65625 10.5625V11.7812C3.65625 12.025 3.81875 12.1875 4.0625 12.1875H8.9375C9.18125 12.1875 9.34375 12.025 9.34375 11.7812V10.5625C9.34375 9.87187 8.81563 9.34375 8.125 9.34375H7.71875V8.2875C8.24687 8.08437 8.69375 7.71875 9.01875 7.3125C10.7656 7.19063 12.1875 5.76875 12.1875 3.98125V2.84375C12.1875 2.6 12.025 2.4375 11.7812 2.4375ZM1.625 3.94062V3.25H3.25V5.28125C3.25 5.6875 3.33125 6.05312 3.45312 6.37812C2.39688 6.09375 1.625 5.11875 1.625 3.94062ZM11.375 3.94062C11.375 5.07812 10.6031 6.05312 9.54688 6.37812C9.66875 6.05312 9.75 5.6875 9.75 5.28125V3.25H11.375V3.94062Z"
        fill="black"
      />
    </g>
  </svg>
);

export default TrophySvg;
