const NonSmocking = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="10209795581536057696 (1) 1">
      <path
        id="Vector"
        d="M7.52467 11.1881C7.52467 11.1093 7.49337 11.0338 7.43767 10.9781C7.38196 10.9224 7.30641 10.8911 7.22764 10.8911H3.94447C4.08497 10.615 4.15821 10.3097 4.15821 9.99998C4.15821 9.69025 4.08497 9.38492 3.94447 9.10889H9.60387C9.68265 9.10889 9.7582 9.0776 9.81391 9.02189C9.86961 8.96619 9.9009 8.89064 9.9009 8.81186C9.9009 8.73308 9.86961 8.65753 9.81391 8.60183C9.7582 8.54613 9.68265 8.51483 9.60387 8.51483H1.8811C1.2712 8.51483 0.791992 9.16632 0.791992 9.99998C0.791992 10.8336 1.2712 11.4851 1.8811 11.4851H7.22764C7.30641 11.4851 7.38196 11.4538 7.43767 11.3981C7.49337 11.3424 7.52467 11.2669 7.52467 11.1881ZM1.8811 9.10889C1.94266 9.11634 2.0016 9.13814 2.05319 9.17254C2.10478 9.20693 2.14758 9.25296 2.17813 9.30691C2.30657 9.51527 2.37458 9.75522 2.37458 9.99998C2.37458 10.2447 2.30657 10.4847 2.17813 10.693C2.14758 10.747 2.10478 10.793 2.05319 10.8274C2.0016 10.8618 1.94266 10.8836 1.8811 10.8911C1.81955 10.8836 1.7606 10.8618 1.70901 10.8274C1.65742 10.793 1.61462 10.747 1.58407 10.693C1.45564 10.4847 1.38762 10.2447 1.38762 9.99998C1.38762 9.75522 1.45564 9.51527 1.58407 9.30691C1.61462 9.25296 1.65742 9.20693 1.70901 9.17254C1.7606 9.13814 1.81955 9.11634 1.8811 9.10889ZM2.75635 9.10889H3.06922C3.30288 9.10889 3.56427 9.47523 3.56427 9.99998C3.56427 10.5247 3.30288 10.8911 3.06922 10.8911H2.75635C2.89685 10.615 2.97009 10.3097 2.97009 9.99998C2.97009 9.69025 2.89685 9.38492 2.75635 9.10889Z"
        fill="#1E79E4"
      />
      <path
        id="Vector_2"
        d="M18.3167 8.51483H12.5741C12.4954 8.51483 12.4198 8.54613 12.3641 8.60183C12.3084 8.65753 12.2771 8.73308 12.2771 8.81186C12.2771 8.89064 12.3084 8.96619 12.3641 9.02189C12.4198 9.0776 12.4954 9.10889 12.5741 9.10889H12.7722C13.0058 9.10889 13.2672 9.47523 13.2672 9.99998C13.2672 10.5247 13.0058 10.8911 12.7722 10.8911H10.1979C10.1191 10.8911 10.0436 10.9224 9.98788 10.9781C9.93217 11.0338 9.90088 11.1093 9.90088 11.1881C9.90088 11.2669 9.93217 11.3424 9.98788 11.3981C10.0436 11.4538 10.1191 11.4851 10.1979 11.4851H18.3167C18.9266 11.4851 19.4058 10.8336 19.4058 9.99998C19.4058 9.16632 18.9266 8.51483 18.3167 8.51483ZM18.3167 10.8911H13.6474C13.7879 10.615 13.8612 10.3097 13.8612 9.99998C13.8612 9.69025 13.7879 9.38492 13.6474 9.10889H18.3167C18.5504 9.10889 18.8118 9.47523 18.8118 9.99998C18.8118 10.5247 18.5504 10.8911 18.3167 10.8911Z"
        fill="#1E79E4"
      />
      <path
        id="Vector_3"
        d="M1.38627 9.99998C1.37892 10.2459 1.44811 10.4881 1.58429 10.693C1.61485 10.747 1.65764 10.793 1.70923 10.8274C1.76082 10.8618 1.81977 10.8836 1.88132 10.8911C1.94288 10.8836 2.00182 10.8618 2.05341 10.8274C2.10501 10.793 2.1478 10.747 2.17835 10.693C2.30679 10.4847 2.3748 10.2447 2.3748 9.99998C2.3748 9.75521 2.30679 9.51526 2.17835 9.30691C2.1478 9.25295 2.10501 9.20693 2.05341 9.17253C2.00182 9.13814 1.94288 9.11634 1.88132 9.10889C1.81977 9.11634 1.76082 9.13814 1.70923 9.17253C1.65764 9.20693 1.61485 9.25295 1.58429 9.30691C1.44811 9.51185 1.37892 9.75402 1.38627 9.99998Z"
        fill="white"
      />
      <path
        id="Vector_4"
        d="M16.9428 3.05745C16.8871 3.00182 16.8116 2.97058 16.7329 2.97058C16.6542 2.97058 16.5787 3.00182 16.523 3.05745L3.25567 16.3248C3.22649 16.352 3.20308 16.3848 3.18685 16.4212C3.17061 16.4576 3.16188 16.497 3.16118 16.5368C3.16048 16.5767 3.16781 16.6163 3.18275 16.6533C3.19769 16.6903 3.21993 16.7239 3.24813 16.7521C3.27634 16.7803 3.30993 16.8026 3.34692 16.8175C3.3839 16.8324 3.42352 16.8398 3.4634 16.8391C3.50328 16.8384 3.54262 16.8296 3.57905 16.8134C3.61549 16.7972 3.64828 16.7738 3.67547 16.7446L16.9428 3.47725C16.9984 3.42156 17.0297 3.34606 17.0297 3.26735C17.0297 3.18864 16.9984 3.11314 16.9428 3.05745Z"
        fill="#1E79E4"
      />
      <path
        id="Vector_5"
        d="M1.88101 8.11884H3.26715C3.34593 8.11884 3.42148 8.08754 3.47718 8.03184C3.53289 7.97614 3.56418 7.90059 3.56418 7.82181V7.42577C3.56471 6.97953 3.74221 6.55171 4.05775 6.23617C4.37329 5.92063 4.80111 5.74313 5.24735 5.7426H6.03943C6.64323 5.74208 7.22214 5.50199 7.64909 5.07504C8.07604 4.64809 8.31613 4.06917 8.31666 3.46537V3.06933C8.31804 3.05286 8.31804 3.0363 8.31666 3.01983C8.31804 3.00336 8.31804 2.9868 8.31666 2.97032C8.31666 2.70773 8.21234 2.4559 8.02666 2.27022C7.84098 2.08454 7.58915 1.98022 7.32656 1.98022C7.06397 1.98022 6.81213 2.08454 6.62645 2.27022C6.44077 2.4559 6.33646 2.70773 6.33646 2.97032C6.33507 2.9868 6.33507 3.00336 6.33646 3.01983C6.33507 3.0363 6.33507 3.05286 6.33646 3.06933V3.46537C6.33594 3.91162 6.15843 4.33943 5.84289 4.65497C5.52735 4.97052 5.09953 5.14802 4.65329 5.14854H3.86121C3.25741 5.14907 2.6785 5.38916 2.25155 5.81611C1.8246 6.24305 1.58451 6.82197 1.58398 7.42577V7.82181C1.58398 7.90059 1.61528 7.97614 1.67098 8.03184C1.72669 8.08754 1.80224 8.11884 1.88101 8.11884ZM6.93052 3.46537V3.06933C6.93191 3.05286 6.93191 3.0363 6.93052 3.01983C6.93191 3.00336 6.93191 2.9868 6.93052 2.97032C6.93052 2.86529 6.97224 2.76455 7.04652 2.69028C7.12079 2.61601 7.22152 2.57428 7.32656 2.57428C7.43159 2.57428 7.53233 2.61601 7.6066 2.69028C7.68087 2.76455 7.7226 2.86529 7.7226 2.97032C7.72121 2.9868 7.72121 3.00336 7.7226 3.01983C7.72121 3.0363 7.72121 3.05286 7.7226 3.06933V3.46537C7.72383 3.88676 7.56715 4.29331 7.28344 4.60488C6.99973 4.91645 6.60958 5.11042 6.18992 5.14854C6.42399 4.9352 6.6108 4.67522 6.73835 4.38533C6.8659 4.09545 6.93136 3.78208 6.93052 3.46537ZM2.17804 7.42577C2.17681 7.00439 2.3335 6.59783 2.61721 6.28626C2.90091 5.9747 3.29106 5.78073 3.71072 5.7426C3.47665 5.95595 3.28984 6.21592 3.16229 6.50581C3.03474 6.79569 2.96928 7.10907 2.97012 7.42577V7.52478H2.17804V7.42577Z"
        fill="#1E79E4"
      />
    </g>
  </svg>
);

export default NonSmocking;
