// Libs
import { configureStore } from '@reduxjs/toolkit';

// Reducers
import apiSlice from '@app/slices/apiSlice';

export default configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});
