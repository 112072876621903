const TagSvg = () => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="21236253941586787874 1" clipPath="url(#clip0_592_237)">
      <g id="Layer_1">
        <path
          id="Vector"
          d="M0.316406 16.2L10.8 26.6836L26.46 11.0236V0.539993H15.9764L0.316406 16.2ZM25.38 1.61999V10.5764L10.8 25.1564L1.84353 16.2L16.4235 1.61999H25.38Z"
          fill="#068B03"
        />
        <path
          id="Vector_2"
          d="M21.5999 7.55999C22.7912 7.55999 23.7599 6.59123 23.7599 5.39999C23.7599 4.20875 22.7912 3.23999 21.5999 3.23999C20.4087 3.23999 19.4399 4.20875 19.4399 5.39999C19.4399 6.59123 20.4087 7.55999 21.5999 7.55999ZM21.5999 4.31999C22.1956 4.31999 22.6799 4.80437 22.6799 5.39999C22.6799 5.99561 22.1956 6.47999 21.5999 6.47999C21.0043 6.47999 20.5199 5.99561 20.5199 5.39999C20.5199 4.80437 21.0043 4.31999 21.5999 4.31999Z"
          fill="#068B03"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_592_237">
        <rect width="27" height="27" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TagSvg;
