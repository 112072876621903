const TickSvg = () => (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="19301342491536572528 (1) 1" clipPath="url(#clip0_616_190)">
      <path
        id="Vector"
        d="M18 0.276427L7.49482 14.7398C7.27186 15.0306 6.93695 15.0884 6.65753 14.8564C6.60131 14.7986 6.60131 14.7986 6.54485 14.7398L0.0247731 5.79735C-0.0309664 5.73959 0.0247731 5.68183 0.0247731 5.68183C0.0802723 5.62407 0.136012 5.68183 0.136012 5.68183L6.94367 10.8437L17.7211 0.0436382C17.7766 -0.0148716 17.8885 -0.0148716 17.944 0.0436382C18 0.101148 18 0.217667 18 0.276427Z"
        fill="#1E79E4"
      />
    </g>
    <defs>
      <clipPath id="clip0_616_190">
        <rect width="18" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TickSvg;
