export default () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.3081 19.9654C24.2095 19.9302 24.1049 19.9148 24.0003 19.92C23.8958 19.9252 23.7932 19.9511 23.6987 19.996C23.6041 20.0409 23.5193 20.104 23.4492 20.1817C23.379 20.2595 23.3249 20.3503 23.2899 20.449C22.579 22.4304 21.2738 24.1442 19.5526 25.3561C17.8313 26.568 15.7778 27.2191 13.6727 27.2203C0.115957 26.6599 0.116722 7.33946 13.6728 6.77963C15.7779 6.78088 17.8313 7.43194 19.5526 8.64387C21.2739 9.85581 22.5791 11.5695 23.2899 13.551C23.3248 13.6499 23.3788 13.741 23.4489 13.819C23.519 13.8969 23.6038 13.9603 23.6984 14.0055C23.7931 14.0506 23.8957 14.0766 24.0004 14.082C24.1051 14.0874 24.2099 14.0721 24.3086 14.037C24.4074 14.0018 24.4983 13.9475 24.5761 13.8772C24.6538 13.8068 24.7169 13.7218 24.7618 13.627C24.8066 13.5322 24.8323 13.4295 24.8374 13.3248C24.8425 13.2201 24.8268 13.1154 24.7913 13.0167C23.9697 10.7255 22.4608 8.74387 20.4708 7.34232C18.4808 5.94078 16.1067 5.18769 13.6727 5.18591C-1.99837 5.83401 -1.99699 28.1669 13.6728 28.8141C16.1068 28.8123 18.4809 28.0592 20.4709 26.6577C22.4609 25.2561 23.9697 23.2745 24.7913 20.9833C24.8622 20.7843 24.8511 20.5652 24.7605 20.3743C24.6698 20.1834 24.5071 20.0364 24.3081 19.9654Z"
      fill="#575757"
    />
    <path
      d="M32.0835 17.2918C32.1372 17.1528 32.1513 17.0017 32.1244 16.8551C32.0974 16.7086 32.0304 16.5723 31.9309 16.4615L28.2121 12.4065C28.0685 12.2541 27.8706 12.1643 27.6612 12.1567C27.4519 12.149 27.248 12.2241 27.0936 12.3657C26.9392 12.5073 26.8468 12.704 26.8363 12.9132C26.8258 13.1224 26.8981 13.3273 27.0376 13.4836L29.5316 16.2031H17.4922C17.2808 16.2031 17.0782 16.2871 16.9287 16.4365C16.7793 16.586 16.6953 16.7886 16.6953 17C16.6953 17.2113 16.7793 17.414 16.9287 17.5635C17.0782 17.7129 17.2808 17.7969 17.4922 17.7969H29.5316L27.0376 20.5164C26.8947 20.6722 26.8196 20.8783 26.8288 21.0894C26.8379 21.3005 26.9306 21.4994 27.0863 21.6422C27.2421 21.785 27.4482 21.8601 27.6593 21.851C27.8705 21.8418 28.0693 21.7492 28.2121 21.5934L31.9309 17.5385C31.994 17.4645 32.0455 17.3813 32.0835 17.2918Z"
      fill="#575757"
    />
  </svg>
);
