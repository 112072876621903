const LocationSvg = () => (
  <svg
    width="14"
    height="21"
    viewBox="0 0 14 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="18545250741556274015 2" clipPath="url(#clip0_45_373)">
      <g id="Icons">
        <g id="Rounded">
          <g id="Communication">
            <g id="-Round-/-Communication-/-location_on">
              <g id="Group">
                <path
                  id="&#240;&#159;&#148;&#185;Icon-Color"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7 0.721313C3.13 0.721313 0 3.85131 0 7.72131C0 11.8913 4.42 17.6413 6.24 19.8313C6.64 20.3113 7.37 20.3113 7.77 19.8313C9.58 17.6413 14 11.8913 14 7.72131C14 3.85131 10.87 0.721313 7 0.721313ZM7 10.2213C5.62 10.2213 4.5 9.10131 4.5 7.72131C4.5 6.34131 5.62 5.22131 7 5.22131C8.38 5.22131 9.5 6.34131 9.5 7.72131C9.5 9.10131 8.38 10.2213 7 10.2213Z"
                  fill="#125AAF"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_45_373">
        <rect
          width="14"
          height="20"
          fill="white"
          transform="translate(0 0.721313)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default LocationSvg;
