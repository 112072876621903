const ShieldSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="1837669441605802265 1" clipPath="url(#clip0_616_182)">
      <path
        id="Vector"
        d="M1.66699 2.49999V9.99999C1.66699 9.99999 1.66699 16.6664 10.0002 19.1664C18.3334 16.6668 18.3334 9.99999 18.3334 9.99999V2.49999L10.0002 0.833588L1.66699 2.49999Z"
        fill="white"
      />
      <path
        id="Vector_2"
        d="M9.9999 20C9.91881 19.9999 9.83815 19.988 9.76045 19.9648C0.937402 17.3172 0.833496 10.073 0.833496 10V2.5C0.833554 2.3074 0.900331 2.12077 1.02247 1.97186C1.1446 1.82294 1.31456 1.72094 1.50342 1.6832L9.83662 0.0164016C9.94437 -0.00554857 10.0554 -0.00554857 10.1632 0.0164016L18.4964 1.6832C18.6852 1.72094 18.8552 1.82294 18.9773 1.97186C19.0995 2.12077 19.1663 2.3074 19.1663 2.5V10C19.1663 10.0727 19.0644 17.3172 10.2394 19.9648C10.1617 19.988 10.081 19.9999 9.9999 20ZM2.4999 3.1832V10C2.50186 10.2418 2.63623 15.9648 9.9999 18.2945C17.3636 15.9648 17.4979 10.2418 17.4999 9.99648V3.1832L9.9999 1.68359L2.4999 3.1832Z"
        fill="#1E79E4"
      />
      <path
        id="Vector_3"
        d="M9.16626 12.5C9.0019 12.5001 8.8412 12.4515 8.70454 12.3601L6.20454 10.6934C6.11349 10.6326 6.03528 10.5546 5.9744 10.4637C5.91351 10.3727 5.87112 10.2707 5.84967 10.1634C5.82821 10.0561 5.8281 9.94557 5.84935 9.83822C5.87059 9.73086 5.91278 9.62874 5.97349 9.53769C6.0342 9.44663 6.11225 9.36843 6.20318 9.30754C6.29412 9.24665 6.39615 9.20427 6.50347 9.18281C6.61078 9.16136 6.72127 9.16125 6.82862 9.1825C6.93598 9.20374 7.0381 9.24592 7.12915 9.30663L9.0061 10.5566L12.6928 6.1328C12.8343 5.963 13.0374 5.85636 13.2575 5.83632C13.4776 5.81628 13.6967 5.8845 13.8665 6.02597C14.0363 6.16743 14.1429 6.37056 14.1629 6.59066C14.183 6.81076 14.1148 7.0298 13.9733 7.1996L9.80649 12.1996C9.72838 12.2936 9.63052 12.3693 9.51986 12.4212C9.40921 12.4731 9.28849 12.5 9.16626 12.5Z"
        fill="#1E79E4"
      />
    </g>
    <defs>
      <clipPath id="clip0_616_182">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ShieldSvg;
