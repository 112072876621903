export default {
  USER: {
    LOGIN: '/users/login',
    SIGN_UP: '/users/register',
  },
  SHOP: {
    GET_PROPERTY_DETAIL: '/shop/properties/getPropertyDetail',
    SEARCH_PROPERTY: '/shop/properties/searchProperty',
    GET_DESTINATIONS: '/shop/properties/getDestinations',
    GET_CURRENT_CART: '/shop/carts/current',
    ADD_OR_UPDATE_CART: '/shop/carts/addOrUpdateCart',
  },
};
