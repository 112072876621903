const BuildingSvg = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="217855911639220226 1" clipPath="url(#clip0_46_686)">
      <g id="Outline">
        <path
          id="Vector"
          d="M6.82812 3.26562H6.23438V3.85938H6.82812V3.26562Z"
          fill="black"
        />
        <path
          id="Vector_2"
          d="M8.01562 3.26562H7.42188V3.85938H8.01562V3.26562Z"
          fill="black"
        />
        <path
          id="Vector_3"
          d="M9.20312 3.26562H8.60938V3.85938H9.20312V3.26562Z"
          fill="black"
        />
        <path
          id="Vector_4"
          d="M10.3906 3.26562H9.79688V3.85938H10.3906V3.26562Z"
          fill="black"
        />
        <path
          id="Vector_5"
          d="M11.5781 3.26562H10.9844V3.85938H11.5781V3.26562Z"
          fill="black"
        />
        <path
          id="Vector_6"
          d="M12.7656 3.26562H12.1719V3.85938H12.7656V3.26562Z"
          fill="black"
        />
        <path
          id="Vector_7"
          d="M6.82812 4.45312H6.23438V5.04688H6.82812V4.45312Z"
          fill="black"
        />
        <path
          id="Vector_8"
          d="M8.01562 4.45312H7.42188V5.04688H8.01562V4.45312Z"
          fill="black"
        />
        <path
          id="Vector_9"
          d="M9.20312 4.45312H8.60938V5.04688H9.20312V4.45312Z"
          fill="black"
        />
        <path
          id="Vector_10"
          d="M10.3906 4.45312H9.79688V5.04688H10.3906V4.45312Z"
          fill="black"
        />
        <path
          id="Vector_11"
          d="M11.5781 4.45312H10.9844V5.04688H11.5781V4.45312Z"
          fill="black"
        />
        <path
          id="Vector_12"
          d="M12.7656 4.45312H12.1719V5.04688H12.7656V4.45312Z"
          fill="black"
        />
        <path
          id="Vector_13"
          d="M6.82812 5.64062H6.23438V6.23438H6.82812V5.64062Z"
          fill="black"
        />
        <path
          id="Vector_14"
          d="M8.01562 5.64062H7.42188V6.23438H8.01562V5.64062Z"
          fill="black"
        />
        <path
          id="Vector_15"
          d="M9.20312 5.64062H8.60938V6.23438H9.20312V5.64062Z"
          fill="black"
        />
        <path
          id="Vector_16"
          d="M10.3906 5.64062H9.79688V6.23438H10.3906V5.64062Z"
          fill="black"
        />
        <path
          id="Vector_17"
          d="M11.5781 5.64062H10.9844V6.23438H11.5781V5.64062Z"
          fill="black"
        />
        <path
          id="Vector_18"
          d="M12.7656 5.64062H12.1719V6.23438H12.7656V5.64062Z"
          fill="black"
        />
        <path
          id="Vector_19"
          d="M6.82812 6.82812H6.23438V7.42188H6.82812V6.82812Z"
          fill="black"
        />
        <path
          id="Vector_20"
          d="M8.01562 6.82812H7.42188V7.42188H8.01562V6.82812Z"
          fill="black"
        />
        <path
          id="Vector_21"
          d="M9.20312 6.82812H8.60938V7.42188H9.20312V6.82812Z"
          fill="black"
        />
        <path
          id="Vector_22"
          d="M10.3906 6.82812H9.79688V7.42188H10.3906V6.82812Z"
          fill="black"
        />
        <path
          id="Vector_23"
          d="M11.5781 6.82812H10.9844V7.42188H11.5781V6.82812Z"
          fill="black"
        />
        <path
          id="Vector_24"
          d="M12.7656 6.82812H12.1719V7.42188H12.7656V6.82812Z"
          fill="black"
        />
        <path
          id="Vector_25"
          d="M6.82812 8.01562H6.23438V8.60938H6.82812V8.01562Z"
          fill="black"
        />
        <path
          id="Vector_26"
          d="M8.01562 8.01562H7.42188V8.60938H8.01562V8.01562Z"
          fill="black"
        />
        <path
          id="Vector_27"
          d="M9.20312 8.01562H8.60938V8.60938H9.20312V8.01562Z"
          fill="black"
        />
        <path
          id="Vector_28"
          d="M10.3906 8.01562H9.79688V8.60938H10.3906V8.01562Z"
          fill="black"
        />
        <path
          id="Vector_29"
          d="M11.5781 8.01562H10.9844V8.60938H11.5781V8.01562Z"
          fill="black"
        />
        <path
          id="Vector_30"
          d="M12.7656 8.01562H12.1719V8.60938H12.7656V8.01562Z"
          fill="black"
        />
        <path
          id="Vector_31"
          d="M6.82812 9.20312H6.23438V9.79688H6.82812V9.20312Z"
          fill="black"
        />
        <path
          id="Vector_32"
          d="M8.01562 9.20312H7.42188V9.79688H8.01562V9.20312Z"
          fill="black"
        />
        <path
          id="Vector_33"
          d="M9.20312 9.20312H8.60938V9.79688H9.20312V9.20312Z"
          fill="black"
        />
        <path
          id="Vector_34"
          d="M10.3906 9.20312H9.79688V9.79688H10.3906V9.20312Z"
          fill="black"
        />
        <path
          id="Vector_35"
          d="M11.5781 9.20312H10.9844V9.79688H11.5781V9.20312Z"
          fill="black"
        />
        <path
          id="Vector_36"
          d="M12.7656 9.20312H12.1719V9.79688H12.7656V9.20312Z"
          fill="black"
        />
        <path
          id="Vector_37"
          d="M6.82812 10.3906H6.23438V10.9844H6.82812V10.3906Z"
          fill="black"
        />
        <path
          id="Vector_38"
          d="M8.01562 10.3906H7.42188V10.9844H8.01562V10.3906Z"
          fill="black"
        />
        <path
          id="Vector_39"
          d="M9.20312 10.3906H8.60938V10.9844H9.20312V10.3906Z"
          fill="black"
        />
        <path
          id="Vector_40"
          d="M10.3906 10.3906H9.79688V10.9844H10.3906V10.3906Z"
          fill="black"
        />
        <path
          id="Vector_41"
          d="M11.5781 10.3906H10.9844V10.9844H11.5781V10.3906Z"
          fill="black"
        />
        <path
          id="Vector_42"
          d="M12.7656 10.3906H12.1719V10.9844H12.7656V10.3906Z"
          fill="black"
        />
        <path
          id="Vector_43"
          d="M6.82812 11.5781H6.23438V12.1719H6.82812V11.5781Z"
          fill="black"
        />
        <path
          id="Vector_44"
          d="M8.01562 11.5781H7.42188V12.1719H8.01562V11.5781Z"
          fill="black"
        />
        <path
          id="Vector_45"
          d="M9.20312 11.5781H8.60938V12.1719H9.20312V11.5781Z"
          fill="black"
        />
        <path
          id="Vector_46"
          d="M10.3906 11.5781H9.79688V12.1719H10.3906V11.5781Z"
          fill="black"
        />
        <path
          id="Vector_47"
          d="M11.5781 11.5781H10.9844V12.1719H11.5781V11.5781Z"
          fill="black"
        />
        <path
          id="Vector_48"
          d="M12.7656 11.5781H12.1719V12.1719H12.7656V11.5781Z"
          fill="black"
        />
        <path
          id="Vector_49"
          d="M6.82812 12.7656H6.23438V13.3594H6.82812V12.7656Z"
          fill="black"
        />
        <path
          id="Vector_50"
          d="M8.01562 12.7656H7.42188V13.3594H8.01562V12.7656Z"
          fill="black"
        />
        <path
          id="Vector_51"
          d="M9.20312 12.7656H8.60938V13.3594H9.20312V12.7656Z"
          fill="black"
        />
        <path
          id="Vector_52"
          d="M10.3906 12.7656H9.79688V13.3594H10.3906V12.7656Z"
          fill="black"
        />
        <path
          id="Vector_53"
          d="M11.5781 12.7656H10.9844V13.3594H11.5781V12.7656Z"
          fill="black"
        />
        <path
          id="Vector_54"
          d="M12.7656 12.7656H12.1719V13.3594H12.7656V12.7656Z"
          fill="black"
        />
        <path
          id="Vector_55"
          d="M6.82812 13.9531H6.23438V14.5469H6.82812V13.9531Z"
          fill="black"
        />
        <path
          id="Vector_56"
          d="M8.01562 13.9531H7.42188V14.5469H8.01562V13.9531Z"
          fill="black"
        />
        <path
          id="Vector_57"
          d="M9.20312 13.9531H8.60938V14.5469H9.20312V13.9531Z"
          fill="black"
        />
        <path
          id="Vector_58"
          d="M10.3906 13.9531H9.79688V14.5469H10.3906V13.9531Z"
          fill="black"
        />
        <path
          id="Vector_59"
          d="M11.5781 13.9531H10.9844V14.5469H11.5781V13.9531Z"
          fill="black"
        />
        <path
          id="Vector_60"
          d="M12.7656 13.9531H12.1719V14.5469H12.7656V13.9531Z"
          fill="black"
        />
        <path
          id="Vector_61"
          d="M4.15625 18.4062H18.4062V17.8125H17.8125V8.3125C17.8125 8.23376 17.7812 8.15825 17.7255 8.10258C17.6699 8.0469 17.5944 8.01562 17.5156 8.01562H13.9531V2.375C13.9531 2.29626 13.9218 2.22075 13.8662 2.16508C13.8105 2.1094 13.735 2.07812 13.6562 2.07812H12.1719V0.890625H11.5781V2.07812H8.90625V1.48438H8.3125V2.07812H7.71875V0.59375H7.125V2.07812H5.34375C5.26501 2.07812 5.1895 2.1094 5.13383 2.16508C5.07815 2.22075 5.04688 2.29626 5.04688 2.375V17.8125H4.45312V6.23438C4.45311 6.15565 4.42182 6.08015 4.36614 6.02448L3.81277 5.47111L2.9447 3.44553C2.9218 3.39215 2.88375 3.34667 2.83526 3.3147C2.78676 3.28274 2.72996 3.2657 2.67188 3.2657C2.61379 3.2657 2.55699 3.28274 2.50849 3.3147C2.46 3.34667 2.42195 3.39215 2.39905 3.44553L1.53098 5.47111L0.977609 6.02448C0.921931 6.08015 0.890642 6.15565 0.890625 6.23438V17.8125H0.59375V18.4062H4.15625ZM8.60938 17.8125V16.0312H9.20312V17.8125H8.60938ZM9.79688 17.8125V16.0312H10.3906V17.8125H9.79688ZM17.2188 8.60938V17.8125H13.9531V8.60938H17.2188ZM5.64062 2.67188H13.3594V17.8125H10.9844V15.7344C10.9844 15.6556 10.9531 15.5801 10.8974 15.5245C10.8417 15.4688 10.7662 15.4375 10.6875 15.4375H8.3125C8.23376 15.4375 8.15825 15.4688 8.10258 15.5245C8.0469 15.5801 8.01562 15.6556 8.01562 15.7344V17.8125H5.64062V2.67188ZM1.48438 6.35728L1.99114 5.85052C2.01784 5.82383 2.0392 5.79229 2.05408 5.75759L2.67188 4.31627L3.28967 5.75759C3.30455 5.79229 3.32591 5.82383 3.35261 5.85052L3.85938 6.35728V17.8125H1.48438V6.35728Z"
          fill="black"
        />
        <path
          id="Vector_62"
          d="M3.26562 6.53125H2.07812V7.125H3.26562V6.53125Z"
          fill="black"
        />
        <path
          id="Vector_63"
          d="M3.26562 7.71875H2.07812V8.3125H3.26562V7.71875Z"
          fill="black"
        />
        <path
          id="Vector_64"
          d="M3.26562 8.90625H2.07812V9.5H3.26562V8.90625Z"
          fill="black"
        />
        <path
          id="Vector_65"
          d="M3.26562 10.0938H2.07812V10.6875H3.26562V10.0938Z"
          fill="black"
        />
        <path
          id="Vector_66"
          d="M3.26562 11.2812H2.07812V11.875H3.26562V11.2812Z"
          fill="black"
        />
        <path
          id="Vector_67"
          d="M3.26562 12.4688H2.07812V13.0625H3.26562V12.4688Z"
          fill="black"
        />
        <path
          id="Vector_68"
          d="M3.26562 13.6562H2.07812V14.25H3.26562V13.6562Z"
          fill="black"
        />
        <path
          id="Vector_69"
          d="M3.26562 14.8438H2.07812V15.4375H3.26562V14.8438Z"
          fill="black"
        />
        <path
          id="Vector_70"
          d="M3.26562 16.0312H2.07812V16.625H3.26562V16.0312Z"
          fill="black"
        />
        <path
          id="Vector_71"
          d="M16.625 9.20312H14.25V9.79688H16.625V9.20312Z"
          fill="black"
        />
        <path
          id="Vector_72"
          d="M16.625 10.3906H14.25V10.9844H16.625V10.3906Z"
          fill="black"
        />
        <path
          id="Vector_73"
          d="M16.625 11.5781H14.25V12.1719H16.625V11.5781Z"
          fill="black"
        />
        <path
          id="Vector_74"
          d="M16.625 12.7656H14.25V13.3594H16.625V12.7656Z"
          fill="black"
        />
        <path
          id="Vector_75"
          d="M16.625 13.9531H14.25V14.5469H16.625V13.9531Z"
          fill="black"
        />
        <path
          id="Vector_76"
          d="M16.625 15.1406H14.25V15.7344H16.625V15.1406Z"
          fill="black"
        />
        <path
          id="Vector_77"
          d="M16.625 16.3281H14.25V16.9219H16.625V16.3281Z"
          fill="black"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_46_686">
        <rect width="19" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BuildingSvg;
