// Constants
import { ROUTES } from '@app/constants';

export const getRoute = () => {
  return {
    path: ROUTES.HOME,
    routes: [
      {
        name: 'search',
        path: '/search',
        icon: null,
        component: null,
      },
    ],
  };
};
