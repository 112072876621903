const EmailSvg = () => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="11685872171607043059 (1) 1" clipPath="url(#clip0_616_247)">
      <g id="Layer 2">
        <g id="Layer 2_2">
          <path
            id="Vector"
            d="M22.984 0.5H1.01575C0.735223 0.5 0.507812 0.723858 0.507812 1V15C0.507812 15.2761 0.735223 15.5 1.01575 15.5H22.984C23.2645 15.5 23.4919 15.2761 23.4919 15V1C23.4919 0.723858 23.2645 0.5 22.984 0.5Z"
            stroke="#1E79E4"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M23.4919 1L12.3173 9.89C12.2257 9.96614 12.1096 10.0079 11.9897 10.0079C11.8698 10.0079 11.7537 9.96614 11.6621 9.89L0.507812 1"
            stroke="#1E79E4"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M23.1109 15.5L14.7046 8"
            stroke="#1E79E4"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_4"
            d="M1.01562 15.5L9.14261 8.25"
            stroke="#1E79E4"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_616_247">
        <rect width="24" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EmailSvg;
