const BarChartSvg = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60 58.4998H56.07V31.9998C56.07 31.602 55.912 31.2204 55.6307 30.9391C55.3494 30.6578 54.9678 30.4998 54.57 30.4998H45.76V12.9998C45.7612 12.6886 45.6656 12.3848 45.4864 12.1303C45.3072 11.8759 45.0534 11.6835 44.76 11.5798L19.07 2.5798C18.8437 2.50314 18.6024 2.4813 18.3659 2.51607C18.1295 2.55084 17.9047 2.64122 17.71 2.7798C17.5142 2.91713 17.3547 3.09985 17.245 3.31232C17.1352 3.52479 17.0786 3.76068 17.08 3.9998V20.1798H9.43C9.03218 20.1798 8.65064 20.3378 8.36934 20.6191C8.08804 20.9004 7.93 21.282 7.93 21.6798V58.4998H4C3.60218 58.4998 3.22064 58.6578 2.93934 58.9391C2.65804 59.2204 2.5 59.602 2.5 59.9998C2.5 60.3976 2.65804 60.7792 2.93934 61.0605C3.22064 61.3418 3.60218 61.4998 4 61.4998H60C60.3978 61.4998 60.7794 61.3418 61.0607 61.0605C61.342 60.7792 61.5 60.3976 61.5 59.9998C61.5 59.602 61.342 59.2204 61.0607 58.9391C60.7794 58.6578 60.3978 58.4998 60 58.4998ZM26.24 58.4998H10.93V23.1798H26.24V58.4998ZM53.07 58.4998H38.57V33.4998H53.07V58.4998Z"
      fill="#3366CC"
    />
  </svg>
);

export default BarChartSvg;
