const CartBlue = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="5343179461574330916 2">
      <path
        id="Vector"
        d="M4.3125 4.3125H21.5625L19.4062 13.6562H6.46875M19.4062 16.5312H7.1875L3.59375 1.4375H1.4375"
        stroke="#1E79E4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M17.9688 20.8438C18.7627 20.8438 19.4062 20.2002 19.4062 19.4062C19.4062 18.6123 18.7627 17.9688 17.9688 17.9688C17.1748 17.9688 16.5312 18.6123 16.5312 19.4062C16.5312 20.2002 17.1748 20.8438 17.9688 20.8438Z"
        stroke="#1E79E4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M8.625 20.8438C9.41891 20.8438 10.0625 20.2002 10.0625 19.4062C10.0625 18.6123 9.41891 17.9688 8.625 17.9688C7.83109 17.9688 7.1875 18.6123 7.1875 19.4062C7.1875 20.2002 7.83109 20.8438 8.625 20.8438Z"
        stroke="#1E79E4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default CartBlue;
