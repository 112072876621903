const SinglePeopleSvg = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="2210446561641072514 1">
      <path
        id="Vector"
        d="M11.8355 9.60695C11.3127 8.38213 10.3444 7.40149 9.12625 6.86328C9.24902 6.76921 9.36549 6.66718 9.47489 6.55785C9.97818 6.05421 10.3158 5.409 10.4427 4.70839C10.5696 4.00778 10.4798 3.28513 10.1851 2.63695C9.89045 1.98878 9.40505 1.44593 8.79373 1.08091C8.1824 0.71589 7.47426 0.54608 6.76388 0.594157C6.05349 0.642235 5.37469 0.905912 4.81812 1.34997C4.26155 1.79403 3.85372 2.39733 3.64909 3.0793C3.44446 3.76127 3.45277 4.48944 3.67292 5.16656C3.89307 5.84368 4.31457 6.43751 4.88113 6.86875C3.6579 7.41749 2.68683 8.40769 2.16207 9.64141C1.88952 10.2818 1.74935 10.9708 1.75 11.6668V12.25C1.75036 12.5593 1.87341 12.8559 2.09214 13.0746C2.31087 13.2934 2.60743 13.4164 2.91676 13.4168H11.0832C11.3926 13.4164 11.6891 13.2934 11.9079 13.0746C12.1266 12.8559 12.2496 12.5593 12.25 12.25V11.6465C12.2513 10.9456 12.1103 10.2517 11.8355 9.60695ZM5.35008 2.43359C5.73186 2.05187 6.23418 1.81434 6.77146 1.76146C7.30874 1.70859 7.84773 1.84364 8.29659 2.14362C8.74546 2.44359 9.07642 2.88993 9.2331 3.40657C9.38977 3.92321 9.36246 4.47819 9.15581 4.97695C8.94916 5.47571 8.57597 5.88739 8.09982 6.14184C7.62367 6.39629 7.07402 6.47777 6.54453 6.37239C6.01504 6.26702 5.53847 5.98131 5.19602 5.56394C4.85357 5.14658 4.66643 4.62339 4.66649 4.08351C4.66573 3.77697 4.72575 3.47331 4.84308 3.19011C4.96042 2.90691 5.13274 2.64978 5.35008 2.43359ZM11.0832 12.25H2.91676V11.6668C2.91629 11.1272 3.02493 10.5932 3.23614 10.0967C3.6524 9.11962 4.42677 8.33889 5.40039 7.91465C5.8967 7.69909 6.43157 7.58644 6.97266 7.58351C7.77862 7.57701 8.5685 7.80903 9.2429 8.25038C9.91731 8.69173 10.4461 9.32269 10.7628 10.0639C10.9759 10.5642 11.0852 11.1027 11.0841 11.6465L11.0832 12.25Z"
        fill="black"
      />
    </g>
  </svg>
);

export default SinglePeopleSvg;
