const SingleBedSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="365045851580120692 1">
      <path
        id="Vector"
        d="M15.3028 19.3639C15.5409 19.3639 15.734 19.1707 15.734 18.9326V2.96158C15.7325 1.66549 14.6822 0.615299 13.3863 0.6138H6.19935C4.90325 0.615299 3.85306 1.66549 3.85156 2.96158V18.9326C3.85156 19.1707 4.04458 19.3639 4.28285 19.3639C4.52097 19.3639 4.71413 19.1707 4.71413 18.9326V17.7666H14.8716V18.9326C14.8716 19.1707 15.0645 19.3639 15.3028 19.3639ZM6.19935 1.47622H13.3863C14.2063 1.47712 14.8706 2.14158 14.8716 2.96158V6.34682C14.6738 6.25241 14.4573 6.20356 14.238 6.20371H12.1405V5.71803C12.1396 4.95107 11.518 4.32931 10.7511 4.32857H8.83455C8.06744 4.32931 7.44584 4.95107 7.4451 5.71803V6.20371H5.34757C5.12818 6.20356 4.91194 6.25256 4.71413 6.34682V2.96158C4.71503 2.14158 5.37949 1.47712 6.19935 1.47622ZM11.2781 6.20371H8.30751V5.71803C8.30766 5.42701 8.54354 5.19128 8.83455 5.19098H10.7511C11.0419 5.19128 11.2778 5.42701 11.2781 5.71803V6.20371ZM4.71413 7.72099C4.70859 7.36539 4.99196 7.07242 5.34757 7.06612H14.238C14.5936 7.07242 14.8769 7.36539 14.8716 7.72099V14.9711C14.6738 14.8766 14.4573 14.8277 14.238 14.828H5.34757C5.12818 14.8277 4.91194 14.8769 4.71413 14.9711V7.72099ZM4.71413 16.9041V16.3453C4.70859 15.9897 4.99196 15.6967 5.34757 15.6905H14.238C14.5936 15.6967 14.8769 15.9897 14.8716 16.3453V16.9041H4.71413Z"
        fill="black"
      />
      <path
        id="Vector_2"
        d="M15.3028 19.3639C15.5409 19.3639 15.734 19.1707 15.734 18.9326V2.96158C15.7325 1.66549 14.6822 0.615299 13.3863 0.6138H6.19935C4.90325 0.615299 3.85306 1.66549 3.85156 2.96158V18.9326C3.85156 19.1707 4.04458 19.3639 4.28285 19.3639C4.52097 19.3639 4.71413 19.1707 4.71413 18.9326V17.7666H14.8716V18.9326C14.8716 19.1707 15.0645 19.3639 15.3028 19.3639ZM6.19935 1.47622H13.3863C14.2063 1.47712 14.8706 2.14158 14.8716 2.96158V6.34682C14.6738 6.25241 14.4573 6.20356 14.238 6.20371H12.1405V5.71803C12.1396 4.95107 11.518 4.32931 10.7511 4.32857H8.83455C8.06744 4.32931 7.44584 4.95107 7.4451 5.71803V6.20371H5.34757C5.12818 6.20356 4.91194 6.25256 4.71413 6.34682V2.96158C4.71503 2.14158 5.37949 1.47712 6.19935 1.47622ZM11.2781 6.20371H8.30751V5.71803C8.30766 5.42701 8.54354 5.19128 8.83455 5.19098H10.7511C11.0419 5.19128 11.2778 5.42701 11.2781 5.71803V6.20371ZM4.71413 7.72099C4.70859 7.36539 4.99196 7.07242 5.34757 7.06612H14.238C14.5936 7.07242 14.8769 7.36539 14.8716 7.72099V14.9711C14.6738 14.8766 14.4573 14.8277 14.238 14.828H5.34757C5.12818 14.8277 4.91194 14.8769 4.71413 14.9711V7.72099ZM4.71413 16.9041V16.3453C4.70859 15.9897 4.99196 15.6967 5.34757 15.6905H14.238C14.5936 15.6967 14.8769 15.9897 14.8716 16.3453V16.9041H4.71413Z"
        fill="black"
      />
    </g>
  </svg>
);

export default SingleBedSvg;
