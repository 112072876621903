const ContactSvg = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="20476358401586787991 1">
      <g id="Layer_1_1_">
        <path
          id="Vector"
          d="M22.54 3.22H0.459961V19.78H22.54V3.22ZM21.62 18.86H1.37996V4.14H21.62V18.86Z"
          fill="#125AAF"
        />
        <path
          id="Vector_2"
          d="M10.5797 12.42C10.5797 11.2935 9.76415 10.3578 8.69327 10.1619C9.00515 9.76902 9.19973 9.27912 9.19973 8.74C9.19973 7.47178 8.16795 6.44 6.89973 6.44C5.63151 6.44 4.59973 7.47178 4.59973 8.74C4.59973 9.27912 4.79385 9.76902 5.10619 10.1619C4.03531 10.3578 3.21973 11.2935 3.21973 12.42V15.64H10.5797V12.42ZM6.89973 7.36C7.66057 7.36 8.27973 7.97916 8.27973 8.74C8.27973 9.50084 7.66057 10.12 6.89973 10.12C6.13889 10.12 5.51973 9.50084 5.51973 8.74C5.51973 7.97916 6.13889 7.36 6.89973 7.36ZM9.65973 14.72H4.13973V12.42C4.13973 11.6592 4.75889 11.04 5.51973 11.04H8.27973C9.04057 11.04 9.65973 11.6592 9.65973 12.42V14.72Z"
          fill="#125AAF"
        />
        <path
          id="Vector_3"
          d="M15.18 7.82H11.96V8.74H15.18V7.82Z"
          fill="#125AAF"
        />
        <path
          id="Vector_4"
          d="M19.7801 7.82H16.5601V8.74H19.7801V7.82Z"
          fill="#125AAF"
        />
        <path
          id="Vector_5"
          d="M13.34 10.12H11.96V11.04H13.34V10.12Z"
          fill="#125AAF"
        />
        <path
          id="Vector_6"
          d="M19.7797 10.12H14.7197V11.04H19.7797V10.12Z"
          fill="#125AAF"
        />
        <path
          id="Vector_7"
          d="M15.64 12.42H11.96V13.34H15.64V12.42Z"
          fill="#125AAF"
        />
        <path
          id="Vector_8"
          d="M19.7799 12.42H17.9399V13.34H19.7799V12.42Z"
          fill="#125AAF"
        />
        <path
          id="Vector_9"
          d="M12.88 14.72H11.96V15.64H12.88V14.72Z"
          fill="#125AAF"
        />
        <path
          id="Vector_10"
          d="M14.7198 14.72H13.7998V15.64H14.7198V14.72Z"
          fill="#125AAF"
        />
        <path
          id="Vector_11"
          d="M16.5601 14.72H15.6401V15.64H16.5601V14.72Z"
          fill="#125AAF"
        />
        <path
          id="Vector_12"
          d="M19.7799 14.72H18.8599V15.64H19.7799V14.72Z"
          fill="#125AAF"
        />
      </g>
    </g>
  </svg>
);

export default ContactSvg;
