const AirConditioning = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="16494481931535956911 1">
      <g id="Group">
        <path
          id="Vector"
          d="M19.0251 15.4556C18.9221 15.3057 18.7173 15.2684 18.5678 15.3712L17.0186 16.4386V14.5568C17.0186 14.3753 16.8712 14.228 16.6897 14.228C16.5082 14.228 16.3609 14.3753 16.3609 14.5568V16.4375L14.8147 15.3682C14.6652 15.2645 14.4606 15.302 14.3572 15.4515C14.2537 15.601 14.2912 15.8057 14.4405 15.909L16.1107 17.0643L14.4383 18.2166C14.2885 18.3196 14.2511 18.5244 14.3539 18.6739C14.4177 18.7666 14.5205 18.8162 14.6248 18.8162C14.6893 18.8162 14.7542 18.7973 14.8112 18.7581L16.3607 17.6904V19.5707C16.3607 19.7522 16.508 19.8996 16.6895 19.8996C16.871 19.8996 17.0183 19.7522 17.0183 19.5707V17.692L18.5639 18.7609C18.6211 18.8006 18.6862 18.8194 18.7509 18.8194C18.8552 18.8194 18.9576 18.7699 19.0214 18.6778C19.1249 18.5283 19.0874 18.3235 18.9381 18.2203L17.2683 17.0652L18.9407 15.9129C19.0905 15.8099 19.1282 15.6051 19.0251 15.4556Z"
          fill="#1E79E4"
        />
        <path
          id="Vector_2"
          d="M17.8027 1.70514H3.75188C2.49001 1.70514 1.46338 2.62435 1.46338 3.75447V10.1861C1.46338 11.316 2.49001 12.2354 3.75188 12.2354H17.8027C19.0646 12.2354 20.0912 11.3162 20.0912 10.1861V3.75447C20.0912 2.62435 19.0646 1.70514 17.8027 1.70514ZM19.4336 10.1861C19.4336 10.9534 18.702 11.5778 17.8027 11.5778H3.75188C2.85261 11.5778 2.12106 10.9534 2.12106 10.1861V3.75447C2.12106 2.98717 2.85261 2.36282 3.75188 2.36282H17.8027C18.7022 2.36282 19.4336 2.98717 19.4336 3.75447V10.1861Z"
          fill="#1E79E4"
        />
        <path
          id="Vector_3"
          d="M16.6894 8.40973H4.99193C4.81019 8.40973 4.66309 8.55683 4.66309 8.73857C4.66309 8.92031 4.81019 9.06741 4.99193 9.06741H16.6894C16.8709 9.06741 17.0182 8.92031 17.0182 8.73857C17.0182 8.55683 16.8709 8.40973 16.6894 8.40973Z"
          fill="#1E79E4"
        />
        <path
          id="Vector_4"
          d="M4.99193 5.53308H6.66265C6.84439 5.53308 6.99149 5.38598 6.99149 5.20424V3.53373C6.99149 3.352 6.84439 3.2049 6.66265 3.2049H4.99193C4.81019 3.2049 4.66309 3.352 4.66309 3.53373V5.20424C4.66309 5.38598 4.81019 5.53308 4.99193 5.53308ZM5.32076 3.86257H6.33381V4.8754H5.32076V3.86257Z"
          fill="#1E79E4"
        />
        <path
          id="Vector_5"
          d="M10.7781 14.2282C10.5963 14.2282 10.4492 14.3755 10.4492 14.557V20.2848C10.4492 20.4663 10.5963 20.6136 10.7781 20.6136C10.9598 20.6136 11.1069 20.4663 11.1069 20.2848V14.557C11.1069 14.3755 10.9598 14.2282 10.7781 14.2282Z"
          fill="#1E79E4"
        />
        <path
          id="Vector_6"
          d="M6.11293 14.1607C5.93119 14.1607 5.78409 14.308 5.78409 14.4895V18.0474C5.78409 18.7447 5.21761 19.3121 4.52091 19.3121C3.82421 19.3121 3.25729 18.7447 3.25729 18.0474C3.25729 17.8658 3.11019 17.7185 2.92845 17.7185C2.74671 17.7185 2.59961 17.8658 2.59961 18.0474C2.59961 19.1073 3.46161 19.9698 4.52091 19.9698C5.57999 19.9698 6.44177 19.1073 6.44177 18.0474V14.4895C6.44177 14.308 6.29445 14.1607 6.11293 14.1607Z"
          fill="#1E79E4"
        />
      </g>
    </g>
  </svg>
);

export default AirConditioning;
