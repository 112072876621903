const GoogleAltSvg = () => (
  <svg
    width="33"
    height="34"
    viewBox="0 0 33 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_967_390)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.89999 17C6.89999 15.9203 7.07774 14.8852 7.39499 13.9143L1.84199 9.63635C0.759744 11.8532 0.149994 14.351 0.149994 17C0.149994 19.6467 0.758994 22.1431 1.83974 24.3584L7.38974 20.0721C7.07549 19.1057 6.89999 18.0744 6.89999 17Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.65 7.17781C18.975 7.17781 21.075 8.00892 22.725 9.36892L27.525 4.53336C24.6 1.96447 20.85 0.377808 16.65 0.377808C10.1295 0.377808 4.5255 4.13972 1.842 9.63638L7.39499 13.9143C8.67449 9.99603 12.3217 7.17781 16.65 7.17781Z"
        fill="#EA4335"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.65 26.8222C12.3217 26.8222 8.67449 24.004 7.39499 20.0857L1.842 24.3629C4.5255 29.8603 10.1295 33.6222 16.65 33.6222C20.6745 33.6222 24.5167 32.1806 27.4005 29.4795L22.1295 25.3685C20.6422 26.3137 18.7695 26.8222 16.65 26.8222Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4 17C32.4 16.0178 32.25 14.96 32.025 13.9778H16.65V20.4H25.5C25.0575 22.5896 23.853 24.273 22.1295 25.3685L27.4005 29.4795C30.4297 26.6432 32.4 22.4181 32.4 17Z"
        fill="#4285F4"
      />
    </g>
    <defs>
      <clipPath id="clip0_967_390">
        <rect width="33" height="34" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default GoogleAltSvg;
