// Libs
import ReactDOM from 'react-dom/client';
import { AuthProvider } from 'react-auth-kit';
import { Provider } from 'react-redux';

// App
import App from './App';

// Report
import reportWebVitals from './reportWebVitals';

// Store
import authConfig from '@app/store/auth';

// Translation
import './i18n';

// Styles
import './index.scss';

// Stores
import store from '@app/store/store';

const start = () => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <AuthProvider {...authConfig}>
      <Provider store={store}>
        <App />
      </Provider>
    </AuthProvider>
  );
};

start();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
