const Shower = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="19579264551530272914 1">
      <path
        id="Vector"
        d="M13.0208 22.3958H14.0625V24.4791H13.0208V22.3958ZM13.0208 20.8333H14.0625V18.75H13.0208V20.8333ZM8.85417 17.1875H9.89583V15.1041H8.85417V17.1875ZM8.85417 20.8333H9.89583V18.75H8.85417V20.8333ZM8.85417 24.4791H9.89583V22.3958H8.85417V24.4791ZM13.0208 17.1875H14.0625V15.1041H13.0208V17.1875ZM25 13.0208V13.5416H6.25V13.0208C6.25 8.59892 8.55521 6.5885 14.1031 6.29371C14.2344 5.56663 14.6729 5.32652 15.0823 5.24788C14.976 4.16871 14.4349 4.16663 13.0208 4.16663H1.04167V6.24996H0V1.04163H1.04167V3.12496H13.0208C14.4542 3.12496 15.9312 3.12704 16.1229 5.24006C16.5469 5.31038 17.013 5.54319 17.1479 6.30413C23.588 6.75985 25 10.1458 25 13.0208ZM23.9406 12.5C23.7 8.99631 20.9734 7.29163 15.625 7.29163C9.03281 7.29163 7.44479 9.27027 7.3026 12.5H23.9406ZM21.3542 20.8333H22.3958V18.75H21.3542V20.8333ZM21.3542 17.1875H22.3958V15.1041H21.3542V17.1875ZM17.1875 24.4791H18.2292V22.3958H17.1875V24.4791ZM21.3542 24.4791H22.3958V22.3958H21.3542V24.4791ZM17.1875 20.8333H18.2292V18.75H17.1875V20.8333ZM17.1875 17.1875H18.2292V15.1041H17.1875V17.1875Z"
        fill="black"
      />
    </g>
  </svg>
);

export default Shower;
