export default {
  location: {
    pathname: '/',
  },
  token: {
    header: {
      colorBgHeader: '#304456',
      colorTextMenu: '#FFFFFF',
      colorMenuBackground: '#0E2E5C',
      colorTextMenuSecondary: '#0E2E5C',
      colorTextMenuSelected: '#fff',
      colorTextMenuActive: '#fff',
      colorBgMenuItemSelected: '',
      colorBgMenuItemHover: '',
      colorHeaderTitle: '#FFFFFF',
    },
    sider: {
      colorMenuBackground: '#304456',
      colorTextMenu: '#200600',
      colorTextMenuTitle: '#200600',
      colorTextMenuSecondary: '#200600',
      colorTextMenuSelected: '#200600',
      colorTextMenuActive: '#200600',
      colorBgMenuItemSelected: '#FFFFFF',
      colorBgMenuItemHover: '#FFFFFF',
      colorMenuItemDivider: '#FFFFFF',
      colorTextMenuItemHover: '#200600',
      colorTextCollapsedButton: '#200600',
    },
  },
  siderWidth: 250,
};
