const LockSvg = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.57 32.27C11.48 32.27 6.57996 27.37 6.57996 21.28V18.06C6.57996 15.75 8.46996 13.93 10.71 13.93H24.36C26.67 13.93 28.49 15.82 28.49 18.06V21.28C28.56 27.3 23.59 32.27 17.57 32.27ZM10.71 15.26C9.16996 15.26 7.97996 16.52 7.97996 17.99V21.21C7.97996 26.53 12.25 30.8 17.57 30.8C22.89 30.8 27.16 26.53 27.16 21.21V17.99C27.16 16.45 25.9 15.26 24.43 15.26H10.71Z"
      fill="#0C0C0C"
    />
    <path
      d="M24.0099 15.26C23.5899 15.26 23.3099 14.98 23.3099 14.56V12.6C23.3099 9.45 20.7199 6.86 17.5699 6.86C14.4199 6.86 11.8299 9.45 11.8299 12.6V14.56C11.8299 14.98 11.5499 15.26 11.1299 15.26C10.7099 15.26 10.4299 14.98 10.4299 14.56V12.6C10.4299 8.68 13.6499 5.46 17.5699 5.46C21.4899 5.46 24.7099 8.68 24.7099 12.6V14.56C24.7099 14.91 24.3599 15.26 24.0099 15.26Z"
      fill="#0C0C0C"
    />
    <path
      d="M17.57 25.48C17.15 25.48 16.87 25.2 16.87 24.78V20.93C16.87 20.51 17.15 20.23 17.57 20.23C17.99 20.23 18.27 20.51 18.27 20.93V24.78C18.27 25.2 17.92 25.48 17.57 25.48Z"
      fill="#0C0C0C"
    />
  </svg>
);

export default LockSvg;
