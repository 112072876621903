const Light = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="12684649916313443234150 1">
      <path
        id="Vector"
        d="M16 5.63C16.1989 5.63 16.3897 5.551 16.5303 5.41035C16.671 5.2697 16.75 5.0789 16.75 4.88V2.5C16.75 2.30109 16.671 2.11032 16.5303 1.96967C16.3897 1.82902 16.1989 1.75 16 1.75C15.8011 1.75 15.6103 1.82902 15.4697 1.96967C15.329 2.11032 15.25 2.30109 15.25 2.5V4.88C15.25 5.0789 15.329 5.2697 15.4697 5.41035C15.6103 5.551 15.8011 5.63 16 5.63Z"
        fill="black"
      />
      <path
        id="Vector_2"
        d="M23.29 8.64995C23.4888 8.64925 23.6792 8.5702 23.82 8.42995L25.5 6.74495C25.5702 6.67565 25.6259 6.59315 25.6639 6.50215C25.7019 6.41115 25.7215 6.31355 25.7215 6.21495C25.7215 6.11635 25.7019 6.01875 25.6639 5.92775C25.6259 5.83675 25.5702 5.75425 25.5 5.68495C25.3588 5.5457 25.1684 5.46765 24.97 5.46765C24.7717 5.46765 24.5813 5.5457 24.44 5.68495L22.76 7.36995C22.6899 7.43925 22.6342 7.52175 22.5962 7.61275C22.5582 7.70375 22.5386 7.80135 22.5386 7.89995C22.5386 7.99855 22.5582 8.09615 22.5962 8.18715C22.6342 8.27815 22.6899 8.36065 22.76 8.42995C22.9009 8.5702 23.0913 8.64925 23.29 8.64995Z"
        fill="black"
      />
      <path
        id="Vector_3"
        d="M28.6901 14.4399H26.3101C26.1112 14.4399 25.9204 14.5189 25.7797 14.6596C25.6391 14.8002 25.5601 14.991 25.5601 15.1899C25.5601 15.3888 25.6391 15.5796 25.7797 15.7202C25.9204 15.8609 26.1112 15.9399 26.3101 15.9399H28.6901C28.889 15.9399 29.0798 15.8609 29.2204 15.7202C29.3611 15.5796 29.4401 15.3888 29.4401 15.1899C29.4401 14.991 29.3611 14.8002 29.2204 14.6596C29.0798 14.5189 28.889 14.4399 28.6901 14.4399Z"
        fill="black"
      />
      <path
        id="Vector_4"
        d="M23.8198 21.9499C23.754 21.8681 23.6717 21.801 23.5783 21.753C23.4849 21.705 23.3825 21.6771 23.2776 21.6712C23.1728 21.6653 23.0679 21.6815 22.9697 21.7187C22.8715 21.7559 22.7822 21.8133 22.7076 21.8872C22.633 21.9611 22.5747 22.0498 22.5366 22.1477C22.4984 22.2455 22.4813 22.3503 22.4862 22.4552C22.4911 22.5601 22.518 22.6628 22.5651 22.7566C22.6123 22.8504 22.6786 22.9333 22.7598 22.9999L24.4398 24.6799C24.5812 24.8193 24.7712 24.8982 24.9698 24.8999C25.116 24.8979 25.2584 24.8532 25.3795 24.7713C25.5007 24.6893 25.5952 24.5738 25.6515 24.4389C25.7078 24.3039 25.7235 24.1554 25.6965 24.0117C25.6696 23.868 25.6012 23.7353 25.4998 23.6299L23.8198 21.9499Z"
        fill="black"
      />
      <path
        id="Vector_5"
        d="M8.18011 21.95L6.50011 23.63C6.39531 23.7349 6.32401 23.8685 6.29511 24.0139C6.26626 24.1593 6.28111 24.31 6.33781 24.447C6.39451 24.5839 6.49056 24.701 6.61376 24.7834C6.73696 24.8658 6.88186 24.9099 7.03011 24.91C7.22861 24.9082 7.41866 24.8294 7.56011 24.69L9.24011 23C9.31376 22.9314 9.37286 22.8486 9.41386 22.7566C9.45486 22.6646 9.47691 22.5653 9.47866 22.4646C9.48046 22.3638 9.46191 22.2638 9.42421 22.1704C9.38651 22.077 9.33036 21.9922 9.25911 21.921C9.18791 21.8498 9.10306 21.7936 9.00971 21.7559C8.91631 21.7182 8.81626 21.6997 8.71556 21.7014C8.61486 21.7032 8.51556 21.7253 8.42356 21.7662C8.33156 21.8072 8.24876 21.8663 8.18011 21.94V21.95Z"
        fill="black"
      />
      <path
        id="Vector_6"
        d="M5.69006 14.4399H3.31006C3.11114 14.4399 2.92038 14.5189 2.77973 14.6596C2.63907 14.8002 2.56006 14.991 2.56006 15.1899C2.56006 15.3888 2.63907 15.5796 2.77973 15.7202C2.92038 15.8609 3.11114 15.9399 3.31006 15.9399H5.69006C5.88896 15.9399 6.07976 15.8609 6.22041 15.7202C6.36106 15.5796 6.44006 15.3888 6.44006 15.1899C6.44006 14.991 6.36106 14.8002 6.22041 14.6596C6.07976 14.5189 5.88896 14.4399 5.69006 14.4399Z"
        fill="black"
      />
      <path
        id="Vector_7"
        d="M8.17977 8.42995C8.32037 8.5704 8.51102 8.6493 8.70977 8.6493C8.90852 8.6493 9.09912 8.5704 9.23977 8.42995C9.30992 8.36065 9.36562 8.27815 9.40362 8.18715C9.44162 8.09615 9.46122 7.99855 9.46122 7.89995C9.46122 7.80135 9.44162 7.70375 9.40362 7.61275C9.36562 7.52175 9.30992 7.43925 9.23977 7.36995L7.55977 5.68495C7.41852 5.5457 7.22812 5.46765 7.02977 5.46765C6.83142 5.46765 6.64102 5.5457 6.49977 5.68495C6.42962 5.75425 6.37392 5.83675 6.33592 5.92775C6.29792 6.01875 6.27832 6.11635 6.27832 6.21495C6.27832 6.31355 6.29792 6.41115 6.33592 6.50215C6.37392 6.59315 6.42962 6.67565 6.49977 6.74495L8.17977 8.42995Z"
        fill="black"
      />
      <path
        id="Vector_8"
        d="M24.2299 14.5C24.1177 13.1494 23.6749 11.8472 22.9406 10.7081C22.2062 9.56911 21.2029 8.62826 20.0191 7.96851C18.8353 7.30881 17.5073 6.95056 16.1523 6.92526C14.7973 6.90001 13.4569 7.20851 12.2493 7.82361C11.0417 8.43871 10.004 9.34151 9.22768 10.4524C8.45138 11.5633 7.96033 12.8481 7.79788 14.1935C7.63538 15.539 7.80643 16.9038 8.29598 18.1675C8.78548 19.4312 9.57848 20.5551 10.6049 21.44C11.194 21.9408 11.6681 22.5627 11.9952 23.2633C12.3222 23.964 12.4943 24.7268 12.4999 25.5V25.85C12.4999 26.0489 12.5789 26.2397 12.7196 26.3803C12.8602 26.521 13.051 26.6 13.2499 26.6H13.6299V27.875C13.6299 28.5036 13.8796 29.1064 14.3241 29.5508C14.7685 29.9953 15.3713 30.245 15.9999 30.245C16.6285 30.245 17.2313 29.9953 17.6758 29.5508C18.1202 29.1064 18.3699 28.5036 18.3699 27.875V26.605H18.7499C18.9488 26.605 19.1396 26.526 19.2802 26.3853C19.4209 26.2447 19.4999 26.0539 19.4999 25.855V25.5C19.5124 24.7171 19.692 23.9459 20.0266 23.238C20.3611 22.53 20.843 21.9017 21.4399 21.395C22.4152 20.5467 23.1763 19.48 23.6611 18.2818C24.1459 17.0836 24.3408 15.7878 24.2299 14.5ZM16.8699 27.88C16.8699 28.1107 16.7783 28.332 16.6151 28.4952C16.4519 28.6583 16.2306 28.75 15.9999 28.75C15.7692 28.75 15.5479 28.6583 15.3847 28.4952C15.2216 28.332 15.1299 28.1107 15.1299 27.88V26.605H16.8699V27.88ZM20.4549 20.27C19.746 20.8732 19.1639 21.6113 18.7425 22.4413C18.321 23.2713 18.0686 24.1767 17.9999 25.105H13.9999C13.9401 24.1848 13.6965 23.2859 13.2834 22.4615C12.8703 21.6371 12.2962 20.9038 11.5949 20.305C10.552 19.4056 9.80863 18.209 9.46438 16.8755C9.12013 15.542 9.19138 14.1352 9.66863 12.8433C10.1458 11.5514 11.0063 10.4361 12.1347 9.64666C13.2632 8.85721 14.6059 8.43121 15.9831 8.42581C17.3603 8.42036 18.7063 8.83571 19.841 9.61626C20.9757 10.3968 21.8449 11.5053 22.3323 12.7933C22.8197 14.0814 22.902 15.4877 22.5683 16.8238C22.2346 18.16 21.5007 19.3624 20.4649 20.27H20.4549Z"
        fill="black"
      />
    </g>
  </svg>
);

export default Light;
