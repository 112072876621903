// Libs
import { Avatar, Badge, Space } from 'antd';
import { useAuthUser } from 'react-auth-kit';
import Cookies from 'js-cookie';

// Constants
import {
  DEFAULT_CURRENCY_CODE,
  COOKIES,
  LANGUAGE_COUNTRY_CODE_MAPPING,
} from '@app/constants';

// Components
import CustomIcon from '@app/components/common/Icons/Index';

// Reducers
import { useGetCurrentCartQuery } from '@app/slices/cartsApiSlice';

// Hooks
import useLanguage from '@app/hooks/useLanguage';

export default (props) => {
  const authUserData = useAuthUser();
  const user = authUserData();
  const { language } = useLanguage();
  const guestId = Cookies.get(COOKIES.GUEST_ID);
  const userId = !!user && user.uid;
  let cart;

  if (!userId && !guestId) {
    cart = null;
  }

  if (!userId && guestId) {
    cart = useGetCurrentCartQuery({
      guestId,
      currencyCode: (!!user && user.currencyCode) || DEFAULT_CURRENCY_CODE,
      languageCountryCode: LANGUAGE_COUNTRY_CODE_MAPPING[language],
    }).data;
  }

  if (userId) {
    cart = useGetCurrentCartQuery({
      userId,
      currencyCode: (!!user && user.currencyCode) || DEFAULT_CURRENCY_CODE,
      languageCountryCode: LANGUAGE_COUNTRY_CODE_MAPPING[language],
    }).data;
  }

  return (
    <Space {...props} className="cart-icon">
      <Badge
        count={(cart && cart.cartItems && cart.cartItems.length) || 0}
        showZero
        color="#1D4A8E"
      >
        <Avatar src={<CustomIcon name="cart3" />} shape="square" size={40} />
      </Badge>
    </Space>
  );
};
