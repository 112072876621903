const AppleAltSvg = () => (
  <svg
    width="32"
    height="37"
    viewBox="0 0 32 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_967_398)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2096 6.0067C22.4314 4.449 23.3576 2.24735 23.0226 0C21.0256 0.13706 18.6915 1.3989 17.3295 3.04362C16.088 4.53388 15.0677 6.75078 15.4662 8.90241C17.6492 8.96985 19.9023 7.67753 21.2096 6.0067ZM32 27.1466C31.1263 29.0698 30.7059 29.9292 29.5805 31.6326C28.0105 34.0105 25.7968 36.9715 23.051 36.9932C20.614 37.0193 19.9855 35.4159 16.677 35.4355C13.3685 35.4529 12.6788 37.0236 10.2374 36.9997C7.49376 36.9758 5.39611 34.3042 3.82615 31.9263C-0.566227 25.2821 -1.02824 17.4828 1.68032 13.334C3.60719 10.3883 6.64638 8.66524 9.50165 8.66524C12.4073 8.66524 14.2356 10.2491 16.642 10.2491C18.9761 10.2491 20.3972 8.66091 23.7583 8.66091C26.3026 8.66091 28.998 10.038 30.9161 12.4137C24.6275 15.8381 25.6457 24.76 32 27.1466Z"
        fill="#0B0B0A"
      />
    </g>
    <defs>
      <clipPath id="clip0_967_398">
        <rect width="32" height="37" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AppleAltSvg;
