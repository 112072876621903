const FlightsAndHotelSvg = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="2821534671670476976 1" clipPath="url(#clip0_92_257)">
      <g id="Layer_x0020_1">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0022 28C13.8841 28 13.7738 27.937 13.7187 27.8386L6.11525 14.7619C5.06786 13.1987 4.5166 11.3756 4.5166 9.48559C4.5166 4.2565 8.76917 0 14.0022 0C19.2313 0 23.4838 4.2565 23.4838 9.48559C23.4838 11.3756 22.9326 13.1987 21.8852 14.7619L14.2857 27.8386C14.2266 27.937 14.1164 28 14.0022 28ZM14.0022 0.657573C9.13142 0.657573 5.17024 4.61876 5.17024 9.48559C5.17024 11.2457 5.68999 12.9467 6.66651 14.4036C6.67045 14.4115 6.67439 14.4154 6.67832 14.4233L14.0022 27.0195L21.3221 14.4233C21.3261 14.4154 21.33 14.4115 21.3339 14.4036C22.3104 12.9467 22.8302 11.2457 22.8302 9.48559C22.8302 4.61876 18.869 0.657573 14.0022 0.657573Z"
          fill="white"
        />
        <path
          id="Vector_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0021 16.6716C10.1276 16.6716 6.97754 13.5216 6.97754 9.64703C6.97754 5.77641 10.1276 2.62636 14.0021 2.62636C17.8728 2.62636 21.0228 5.77641 21.0228 9.64703C21.0228 13.5216 17.8728 16.6716 14.0021 16.6716ZM14.0021 3.28393C10.4898 3.28393 7.63511 6.13866 7.63511 9.64703C7.63511 13.1593 10.4898 16.0141 14.0021 16.0141C17.5105 16.0141 20.3652 13.1593 20.3652 9.64703C20.3652 6.13866 17.5105 3.28393 14.0021 3.28393Z"
          fill="white"
        />
        <path
          id="Vector_3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.7614 12.4466C9.5094 12.4466 9.20227 12.3915 9.1432 12.1395L9.00933 11.5646C8.96995 11.3953 9.06839 11.226 9.2377 11.1787L9.43065 11.1197L8.399 9.87147C8.28088 9.72972 8.30056 9.52103 8.44232 9.40684L8.80457 9.11152C8.90301 9.02884 9.04476 9.01309 9.15895 9.07215L11.1947 10.1077C11.2695 10.1077 11.553 10.0723 12.0846 9.76122L11.0529 9.15484C10.8285 9.0249 10.8403 8.70202 11.0687 8.57995L11.8207 8.19013C11.8916 8.1547 11.9664 8.14682 12.0412 8.16257L14.2305 8.6272L16.8726 7.29631C16.9947 7.21756 17.6208 6.85136 18.491 6.85136C18.6839 6.85136 19.2273 6.89468 19.3848 6.99705C19.5147 7.07974 19.6683 7.26875 19.6762 7.52075C19.7077 8.22951 18.6288 8.82802 17.0577 9.60766C15.8174 10.2219 14.3014 11.1433 13.065 11.5449C12.0846 11.8639 10.6001 12.4466 9.7614 12.4466ZM9.73777 11.793C10.4308 11.8048 12.0137 11.1984 12.8603 10.9228C14.0297 10.5409 15.5654 9.61553 16.7663 9.02096C17.9712 8.42245 18.928 7.87119 19.0186 7.56406C18.2429 7.39081 17.5026 7.6625 17.2191 7.8515L14.4195 9.26509C14.3526 9.29659 14.2778 9.3084 14.2069 9.29265L12.0216 8.82802L11.8995 8.89102L12.876 9.46197C13.0808 9.58403 13.0926 9.87935 12.8996 10.0172C12.0609 10.6039 11.2774 10.8913 10.9505 10.722L9.22589 9.84391L10.2536 11.0803C10.4032 11.2614 10.3166 11.5371 10.0922 11.604L9.71809 11.7182L9.73777 11.793Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_92_257">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FlightsAndHotelSvg;
