const SortingSvg = () => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="9296728661556283416 1" clipPath="url(#clip0_70_454)">
      <g id="Icons">
        <g id="Two-Tone">
          <g id="AV">
            <g id="Two-Tone-/-AV-/-sort_by_alpha">
              <g id="Group">
                <path
                  id="&#240;&#159;&#148;&#185;-Primary-Color"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.9484 2.261L9.92559 0.255005L7.90273 2.261H11.9484ZM8.04845 13.3705H9.62559L5.77702 3.6295H4.3713L0.51416 13.3705H2.0913L2.87987 11.288H7.25987L8.04845 13.3705ZM3.40273 9.894L5.06559 5.491L6.72845 9.894H3.40273ZM9.92559 16.745L11.9227 14.7645H7.92845L9.92559 16.745ZM17.7084 4.709V3.63801H10.5942V4.998H15.6342L10.5599 12.274V13.3705H17.8713V12.019H12.6256L17.7084 4.709Z"
                  fill="#656161"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_70_454">
        <rect width="18" height="17" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SortingSvg;
