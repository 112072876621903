const DateSvg = () => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="date-svgrepo-com (1) 1">
      <path
        id="Vector"
        d="M2.9043 2.90453V26.0955H26.0952V2.90453H2.9043ZM25.1292 3.8706V8.21878H3.87127V3.8706H25.1292ZM3.87036 25.1294V9.18575H25.1283V25.1294H3.87036Z"
        fill="#898B8C"
      />
      <path
        id="Vector_2"
        d="M10.1509 5.32059H11.6009V6.76969H10.1509V5.32059Z"
        fill="#898B8C"
      />
      <path
        id="Vector_3"
        d="M17.3989 5.32059H18.848V6.76969H17.3989V5.32059Z"
        fill="#898B8C"
      />
      <path
        id="Vector_4"
        d="M11.1904 12.6232C11.0173 13.7986 10.4582 13.8194 9.33262 13.8602L9.15771 13.8665V14.6858H11.0645V20.0453H12.0758V12.4682H11.2122L11.1904 12.6232Z"
        fill="#898B8C"
      />
      <path
        id="Vector_5"
        d="M16.8941 14.9006C16.4446 14.9006 15.9833 15.0474 15.6181 15.293L15.9625 13.5212H19.1479V12.5606H15.2085L14.44 16.6641H15.3145L15.368 16.5807C15.6734 16.105 16.2307 15.8086 16.8234 15.8086C17.7877 15.8086 18.4873 16.5218 18.4873 17.5033C18.4873 18.3914 17.9299 19.2904 16.8651 19.2904C15.9552 19.2904 15.3009 18.676 15.2737 17.796L15.2683 17.6202H14.2578L14.2623 17.806C14.295 19.2379 15.3199 20.1994 16.8143 20.1994C18.3205 20.1994 19.4996 19.0467 19.4996 17.5758C19.4996 15.9754 18.4538 14.8997 16.8968 14.8997L16.8941 14.9006Z"
        fill="#898B8C"
      />
    </g>
  </svg>
);

export default DateSvg;
