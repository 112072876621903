const UserIconSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.116 15.108C8.117 15.109 8.119 15.11 8.12 15.111C8.196 15.154 8.242 15.234 8.242 15.321L8.25 15.865C8.25 15.978 8.174 16.077 8.064 16.107L3.291 17.379C2.088 17.7 1.25 18.791 1.25 20.037V22C1.25 22.414 1.586 22.75 2 22.75H22C22.414 22.75 22.75 22.414 22.75 22V20.037C22.75 18.791 21.912 17.7 20.709 17.379L15.936 16.107C15.826 16.077 15.75 15.978 15.75 15.865V15.322C15.75 15.234 15.796 15.153 15.872 15.108C16.234 14.906 16.564 14.654 16.859 14.359C17.75 13.468 18.25 12.26 18.25 11C18.25 9.412 18.25 7.588 18.25 6C18.25 4.74 17.75 3.532 16.859 2.641C15.968 1.75 14.76 1.25 13.5 1.25C12.523 1.25 11.477 1.25 10.5 1.25C9.24 1.25 8.032 1.75 7.141 2.641C6.25 3.532 5.75 4.74 5.75 6C5.75 7.588 5.75 9.412 5.75 11C5.75 12.26 6.25 13.468 7.141 14.359C7.435 14.652 7.763 14.903 8.116 15.108ZM8.869 13.811C8.627 13.671 8.403 13.499 8.202 13.298C7.592 12.689 7.25 11.862 7.25 11C7.25 9.412 7.25 7.588 7.25 6C7.25 5.138 7.592 4.311 8.202 3.702C8.811 3.092 9.638 2.75 10.5 2.75H13.5C14.362 2.75 15.189 3.092 15.798 3.702C16.408 4.311 16.75 5.138 16.75 6C16.75 7.588 16.75 9.412 16.75 11C16.75 11.862 16.408 12.689 15.798 13.298C15.601 13.495 15.381 13.665 15.141 13.798C15.138 13.799 15.135 13.801 15.133 13.802C14.588 14.113 14.251 14.693 14.25 15.321V15.865C14.25 16.658 14.783 17.352 15.549 17.556L20.322 18.829C20.869 18.975 21.25 19.47 21.25 20.037V21.25H2.75V20.037C2.75 19.47 3.131 18.975 3.678 18.829L8.451 17.556C9.217 17.352 9.75 16.658 9.75 15.865L9.742 15.298C9.735 14.684 9.402 14.115 8.864 13.808L8.869 13.811Z"
      fill="black"
    />
  </svg>
);

export default UserIconSvg;
