const LanguageSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="18461902111628783618 1">
      <path
        id="Vector"
        d="M10.167 15.7752C10.6497 16.8792 11.2638 17.9209 11.996 18.8778C12.7298 17.921 13.3458 16.8793 13.8306 15.7752C12.6131 15.7191 11.3845 15.7191 10.167 15.7752Z"
        fill="white"
      />
      <path
        id="Vector_2"
        d="M5.85254 16.2016C6.87704 17.6543 8.38088 18.6992 10.0996 19.1527C9.40092 18.1396 8.82463 17.0473 8.38282 15.8986C7.53567 15.9716 6.68849 16.0725 5.85254 16.2016Z"
        fill="white"
      />
      <path
        id="Vector_3"
        d="M4.99442 9.13245C4.29873 10.8934 4.29873 12.8529 4.99442 14.6139C5.96502 14.4512 6.94126 14.3221 7.88942 14.2267C7.54716 12.6744 7.54716 11.0662 7.88942 9.51396C6.93564 9.4242 5.96502 9.29517 4.99442 9.13245Z"
        fill="white"
      />
      <path
        id="Vector_4"
        d="M9.60622 14.0977C10.4029 14.0528 11.1996 14.0304 11.9962 14.0304C12.7986 14.0304 13.5952 14.0528 14.3919 14.0977C14.751 12.6345 14.751 11.1062 14.3919 9.64301C12.7981 9.73838 11.2 9.73838 9.60622 9.64301C9.24715 11.1062 9.24715 12.6345 9.60622 14.0977Z"
        fill="white"
      />
      <path
        id="Vector_5"
        d="M19.0034 14.6139C19.6916 12.8515 19.6916 10.8948 19.0034 9.13245C18.0328 9.29517 17.0566 9.4242 16.1084 9.51396C16.4506 11.0662 16.4506 12.6744 16.1084 14.2267C17.0566 14.3221 18.0328 14.4512 19.0034 14.6139Z"
        fill="white"
      />
      <path
        id="Vector_6"
        d="M13.8926 4.59363C14.5951 5.60534 15.1734 6.69787 15.615 7.84769C16.4622 7.77475 17.3093 7.66815 18.1453 7.54473C17.1186 6.09178 15.6129 5.04694 13.8926 4.59363Z"
        fill="white"
      />
      <path
        id="Vector_7"
        d="M13.8306 7.97108C13.347 6.86637 12.731 5.82454 11.996 4.86853C11.2626 5.82465 10.6485 6.86649 10.167 7.97108C11.3845 8.02721 12.6131 8.02721 13.8306 7.97108Z"
        fill="white"
      />
      <path
        id="Vector_8"
        d="M2.45443 7.91452C2.57065 7.98758 2.70678 8.02255 2.84382 8.01455C2.98087 8.00655 3.112 7.95599 3.21894 7.86992C3.43811 7.68512 3.53522 7.34134 3.63151 7.08298L3.97974 6.14773H6.94459L7.29281 7.06304C7.42113 7.39964 7.57744 7.89993 7.97448 7.99486C8.0773 8.01865 8.18405 8.01993 8.28741 7.99862C8.39077 7.97731 8.4883 7.93392 8.57333 7.8714C8.65836 7.80889 8.72887 7.72873 8.78003 7.63643C8.83119 7.54413 8.8618 7.44186 8.86976 7.33662C8.84073 7.06533 8.765 6.80111 8.6459 6.55563L6.74063 1.84969C6.635 1.51976 6.49248 1.20281 6.31578 0.904851C6.04391 0.509235 5.51939 0.49236 5.0711 0.654581C4.53139 0.849877 4.37204 1.50398 4.1837 1.9821C4.18243 1.98535 2.31823 6.59544 2.31823 6.59544C2.13941 7.03862 1.94663 7.60934 2.45443 7.91452ZM5.44725 2.15123L6.54663 5.0384H4.36777L5.44725 2.15123Z"
        fill="white"
      />
      <path
        id="Vector_9"
        d="M21.0447 18.365C21.1949 18.365 21.339 18.3054 21.4452 18.1991C21.5515 18.0929 21.6111 17.9488 21.6111 17.7986C21.6111 17.6483 21.5515 17.5042 21.4452 17.398C21.339 17.2918 21.1949 17.2321 21.0447 17.2321H17.2605L17.3249 16.5157C17.3382 16.3661 17.2916 16.2173 17.1952 16.1021C17.0989 15.9868 16.9608 15.9146 16.8112 15.9011C16.6616 15.8877 16.5128 15.9342 16.3975 16.0305C16.2822 16.1267 16.2098 16.2648 16.1963 16.4144L16.1229 17.2321H14.831C14.6808 17.2321 14.5367 17.2918 14.4304 17.398C14.3242 17.5042 14.2645 17.6483 14.2645 17.7986C14.2645 17.9488 14.3242 18.0929 14.4304 18.1991C14.5367 18.3054 14.6808 18.365 14.831 18.365H16.0211L15.8423 20.3561C15.829 20.5036 15.8741 20.6505 15.9678 20.7652C16.2457 21.0829 16.5549 21.3717 16.8908 21.6271C16.1791 22.0728 15.3698 22.3392 14.5325 22.4035C14.3873 22.4165 14.2527 22.485 14.1567 22.5948C14.0607 22.7046 14.0107 22.8471 14.0172 22.9928C14.0236 23.1385 14.086 23.2761 14.1913 23.3769C14.2966 23.4778 14.4368 23.5341 14.5826 23.5343C14.5995 23.5343 14.6166 23.5334 14.6338 23.532C15.822 23.4463 16.96 23.019 17.911 22.3014C18.7068 22.7446 19.736 23.2642 20.9913 23.3829C21.0095 23.3845 21.0275 23.3854 21.0452 23.3854C21.1908 23.3852 21.3307 23.3291 21.436 23.2285C21.5412 23.1279 21.6038 22.9907 21.6105 22.8453C21.6173 22.6999 21.5679 22.5574 21.4725 22.4475C21.377 22.3376 21.243 22.2686 21.098 22.2549C20.1929 22.1693 19.4272 21.7855 18.7979 21.4778C19.562 20.5766 20.1082 19.5115 20.3941 18.365H21.0447ZM17.801 20.8857C17.5137 20.682 17.2435 20.4551 16.9932 20.2072L17.1587 18.3651H19.2244C18.9558 19.306 18.4681 20.1698 17.801 20.8857Z"
        fill="white"
      />
      <path
        id="Vector_10"
        d="M12.0002 1.0025C10.7608 1.00175 9.53038 1.21398 8.36279 1.62994L8.88698 2.92051C10.6259 2.31409 12.5039 2.2277 14.2912 2.67194C16.0785 3.11617 17.6975 4.07175 18.9502 5.42175C20.2028 6.77175 21.0348 8.45763 21.3443 10.2731C21.6538 12.0885 21.4274 13.9549 20.6928 15.6437H21.0462C21.4103 15.644 21.7683 15.7369 22.0866 15.9137C22.6048 14.629 22.8704 13.2564 22.8689 11.8712C22.8689 6.20548 17.9925 1.0025 12.0002 1.0025Z"
        fill="white"
      />
      <path
        id="Vector_11"
        d="M2.52122 11.9936C2.52017 11.228 2.61353 10.4651 2.79919 9.72222C2.32163 9.72292 1.85654 9.56975 1.47285 9.2854C1.06063 10.8911 1.02104 12.5698 1.35709 14.1932C1.69315 15.8166 2.39595 17.3416 3.41177 18.6517C4.42759 19.9618 5.72953 21.0223 7.21805 21.7521C8.70657 22.4819 10.3422 22.8616 12 22.8623C12.147 22.8623 12.2979 22.8583 12.4448 22.8504C12.4894 22.4308 12.6581 22.034 12.9293 21.7107C13.0304 21.5883 13.1465 21.4789 13.2747 21.3851C12.8524 21.4444 12.4265 21.4736 12 21.4725C9.48685 21.47 7.07728 20.4706 5.30019 18.6935C3.52309 16.9164 2.52365 14.5068 2.52122 11.9936Z"
        fill="white"
      />
    </g>
  </svg>
);

export default LanguageSvg;
