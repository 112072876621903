// Libs
import { Outlet as ChildRouteElement } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Layout, Button, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useState } from 'react';

// Components
import LanguageDropdown from '@app/components/common/LanguageDropdown/LanguageDropdown';
import Footer from '@app/components/common/Footer/Footer';

// Styles
import './UserAuthLayout.scss';

// Variables
const { Header, Content } = Layout;

const Auth = () => {
  const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div className="user-auth">
      <Layout>
        <Header style={{ backgroundColor: '#4B6385' }}>
          <div className="left-header">
            <a href="/">
              <h1>
                <span style={{ color: '#FAC917' }}>
                  {t('common.header.title.before')}
                </span>
                <span style={{ color: 'white' }}>
                  {t('common.header.title.after')}
                </span>
              </h1>
            </a>
          </div>

          <div className="right-header">
            <div className="register">
              <Button className="header-button" type="link" href="/user/login">
                {t('common.header.right.sign-in')}
              </Button>
            </div>
            <LanguageDropdown />
          </div>
          <div className="menu-mobile">
            <MenuOutlined
              style={{ color: '#fff' }}
              className="menu-icon"
              onClick={() => setOpenDrawer(true)}
            />
            <Drawer
              onClose={() => setOpenDrawer(false)}
              open={openDrawer}
              placement="left"
              closeIcon={
                <MenuOutlined
                  style={{ color: '#fff' }}
                  className="menu-icon"
                  onClick={() => setOpenDrawer(false)}
                />
              }
            >
              <div className="register">
                <Button
                  className="header-button"
                  type="link"
                  href="/user/login"
                >
                  {t('common.header.right.sign-in')}
                </Button>
              </div>
              <LanguageDropdown />
            </Drawer>
          </div>
        </Header>
        <Content>
          <ChildRouteElement />
        </Content>
        <Layout.Footer className="front-footer">
          <Footer />
        </Layout.Footer>
      </Layout>
    </div>
  );
};

export default Auth;
