// Libs
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { SyncOutlined } from '@ant-design/icons';

// Styles
import './Loading.scss';

export default () => {
  const { t } = useTranslation();

  return (
    <div className="global-spinner">
      <Spin
        indicator={
          <SyncOutlined
            spin
            title={t('common.loading')}
            style={{ fontSize: 50 }}
          />
        }
        tip={t('common.loading')}
        size="large"
        fullscreen
      >
        <div className="content" />
      </Spin>
    </div>
  );
};
