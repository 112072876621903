const SearchSvg = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="3028692271535694869 1">
      <g id="Group">
        <path
          id="Vector"
          d="M11.1998 20.9892C13.5874 20.9892 15.7579 20.1426 17.451 18.7101L25.0479 26.307C25.2215 26.4806 25.4386 26.5674 25.6773 26.5674C25.9161 26.5674 26.1331 26.4806 26.3068 26.307C26.6541 25.9597 26.6541 25.3954 26.3068 25.0481L18.7099 17.4512C20.1207 15.7581 20.9889 13.5659 20.9889 11.2C20.9889 5.79535 16.6044 1.41086 11.1998 1.41086C5.81685 1.41086 1.41064 5.81706 1.41064 11.2C1.41064 16.6047 5.81685 20.9892 11.1998 20.9892ZM11.1998 3.1907C15.6277 3.1907 19.2091 6.7938 19.2091 11.2C19.2091 15.6279 15.6277 19.2093 11.1998 19.2093C6.77188 19.2093 3.19049 15.6062 3.19049 11.2C3.19049 6.7938 6.79359 3.1907 11.1998 3.1907Z"
          fill="black"
        />
      </g>
    </g>
  </svg>
);

export default SearchSvg;
