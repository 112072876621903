const TopSvg = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="8555092561586787934 1">
      <g id="Layer_1_1_">
        <path
          id="Vector"
          d="M2.76431 12.7327L4.15947 21.1021V24.1795H21.8395V21.1026L23.2346 12.7332C24.4285 12.8274 25.4795 11.8617 25.4795 10.66C25.4795 9.51288 24.5466 8.58 23.3995 8.58C22.2523 8.58 21.3195 9.51288 21.3195 10.66C21.3195 11.0869 21.4515 11.4977 21.6897 11.8409L17.4096 14.5652L13.8923 5.772C14.599 5.43504 15.08 4.71536 15.08 3.90052C15.08 2.7534 14.1471 1.82052 13 1.82052C11.8529 1.82052 10.92 2.7534 10.92 3.90052C10.92 4.71536 11.4005 5.43556 12.1077 5.772L8.59039 14.5652L4.31027 11.8414C4.54843 11.4982 4.68051 11.0874 4.68051 10.6605C4.68051 9.5134 3.74763 8.58052 2.60051 8.58052C1.45339 8.58052 0.520509 9.5134 0.520509 10.6605C0.519469 11.8622 1.56415 12.8284 2.76431 12.7327ZM5.19947 23.1395V21.5795H20.8047L20.7995 23.1395H5.19947ZM23.3995 9.61948C23.973 9.61948 24.4395 10.0859 24.4395 10.6595C24.4395 11.233 23.973 11.6995 23.3995 11.6995C23.2669 11.6995 23.1353 11.6735 23.0084 11.6215L22.9034 11.5788L22.8056 11.5102C22.5264 11.3147 22.3595 10.9964 22.3595 10.6595C22.3595 10.0859 22.8259 9.61948 23.3995 9.61948ZM11.9595 3.89948C11.9595 3.32592 12.4259 2.85948 12.9995 2.85948C13.573 2.85948 14.0395 3.32592 14.0395 3.89948C14.0395 4.41636 13.6495 4.8568 13.1326 4.92388L12.9995 4.94104L12.8663 4.92388C12.3495 4.8568 11.9595 4.41636 11.9595 3.89948ZM9.08907 16.1148L12.9995 6.33984L16.9099 16.1148L22.1749 12.7644L20.879 20.5395H5.11315L3.82407 12.765L9.08907 16.1148ZM2.59947 9.61948C3.17303 9.61948 3.63947 10.0859 3.63947 10.6595C3.63947 10.9964 3.47255 11.3147 3.19331 11.5102L3.09555 11.5788L2.99051 11.6215C2.86363 11.6735 2.73155 11.6995 2.59947 11.6995C2.02591 11.6995 1.55947 11.233 1.55947 10.6595C1.55947 10.0859 2.02591 9.61948 2.59947 9.61948Z"
          fill="#125AAF"
        />
        <path
          id="Vector_2"
          d="M13.5195 17.9395H12.4795V18.9795H13.5195V17.9395Z"
          fill="#125AAF"
        />
      </g>
    </g>
  </svg>
);

export default TopSvg;
