export default () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.519531 20.4324H5.71953V25.3276L10.6148 20.4324H25.4795V0.672363H0.519531V20.4324ZM1.55953 1.71236H24.4395V19.3924H10.1843L6.75953 22.8171V19.3924H1.55953V1.71236Z"
      fill="#575757"
    />
  </svg>
);
