const WifiSvg = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="8407015331543238954 (1) 1">
      <path
        id="Vector"
        d="M3.54175 8.88958C4.94181 7.72343 6.7063 7.08484 8.52841 7.08484C10.3505 7.08484 12.115 7.72343 13.5151 8.88958"
        stroke="#1E79E4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M1.00586 6.375C3.07591 4.55031 5.74057 3.54355 8.50003 3.54355C11.2595 3.54355 13.9241 4.55031 15.9942 6.375"
        stroke="#1E79E4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M6.04199 11.4112C6.76109 10.9004 7.62134 10.6259 8.50345 10.6259C9.38556 10.6259 10.2458 10.9004 10.9649 11.4112"
        stroke="#1E79E4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        id="Ellipse"
        cx="8.50008"
        cy="14.1667"
        r="0.708333"
        fill="#1E79E4"
      />
    </g>
  </svg>
);

export default WifiSvg;
