const TickBlue = () => (
  <svg
    width="15"
    height="12"
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="19301342491536572528 1" clipPath="url(#clip0_417_419)">
      <path
        id="Vector"
        d="M15 0.221093L6.24568 11.7918C6.05989 12.0245 5.78079 12.0707 5.54794 11.885C5.50109 11.8388 5.50109 11.8388 5.45404 11.7918L0.0206442 4.63783C-0.0258053 4.59162 0.0206442 4.54541 0.0206442 4.54541C0.0668936 4.49921 0.113343 4.54541 0.113343 4.54541L5.78639 8.6749L14.7676 0.0348617C14.8138 -0.0119461 14.9071 -0.0119461 14.9534 0.0348617C15 0.0808694 15 0.174085 15 0.221093Z"
        fill="#1E79E4"
      />
    </g>
    <defs>
      <clipPath id="clip0_417_419">
        <rect width="15" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TickBlue;
