// Libs
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';

//Components
import { MainRouter } from './MainRouter';

function App() {
  return (
    <HelmetProvider>
      <RouterProvider router={MainRouter} />
    </HelmetProvider>
  );
}

export default App;
