const Window = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="9220783201644410978 1">
      <g id="Window">
        <path
          id="Vector"
          d="M23.2917 21.125V3.25C23.2917 2.81902 23.1205 2.4057 22.8157 2.10095C22.511 1.7962 22.0976 1.625 21.6667 1.625H4.33333C3.90236 1.625 3.48903 1.7962 3.18428 2.10095C2.87954 2.4057 2.70833 2.81902 2.70833 3.25V21.125C2.42102 21.125 2.14547 21.2391 1.9423 21.4423C1.73914 21.6455 1.625 21.921 1.625 22.2083V23.2917C1.625 23.579 1.73914 23.8545 1.9423 24.0577C2.14547 24.2609 2.42102 24.375 2.70833 24.375H23.2917C23.579 24.375 23.8545 24.2609 24.0577 24.0577C24.2609 23.8545 24.375 23.579 24.375 23.2917V22.2083C24.375 21.921 24.2609 21.6455 24.0577 21.4423C23.8545 21.2391 23.579 21.125 23.2917 21.125ZM3.79167 3.25C3.79167 3.10634 3.84874 2.96857 3.95032 2.86698C4.0519 2.7654 4.18967 2.70833 4.33333 2.70833H21.6667C21.8103 2.70833 21.9481 2.7654 22.0497 2.86698C22.1513 2.96857 22.2083 3.10634 22.2083 3.25V21.125H3.79167V3.25ZM23.2917 23.2917H2.70833V22.2083H23.2917V23.2917Z"
          fill="black"
        />
        <path
          id="Vector_2"
          d="M20.5833 3.79163H5.41667C5.27301 3.79163 5.13523 3.84869 5.03365 3.95028C4.93207 4.05186 4.875 4.18963 4.875 4.33329V19.5C4.875 19.6436 4.93207 19.7814 5.03365 19.883C5.13523 19.9846 5.27301 20.0416 5.41667 20.0416H20.5833C20.727 20.0416 20.8648 19.9846 20.9664 19.883C21.0679 19.7814 21.125 19.6436 21.125 19.5V4.33329C21.125 4.18963 21.0679 4.05186 20.9664 3.95028C20.8648 3.84869 20.727 3.79163 20.5833 3.79163ZM20.0417 4.87496V11.375H13.5417V4.87496H20.0417ZM5.95833 4.87496H12.4583V11.375H5.95833V4.87496ZM5.95833 18.9583V12.4583H12.4583V18.9583H5.95833ZM20.0417 18.9583H13.5417V12.4583H20.0417V18.9583Z"
          fill="black"
        />
        <path
          id="Vector_3"
          d="M7.96644 7.96627L9.04977 6.88293C9.14844 6.78077 9.20304 6.64395 9.2018 6.50193C9.20057 6.3599 9.1436 6.22405 9.04317 6.12362C8.94274 6.02319 8.80689 5.96622 8.66486 5.96499C8.52284 5.96375 8.38601 6.01835 8.28385 6.11702L7.20052 7.20035C7.14879 7.25032 7.10752 7.31009 7.07913 7.37617C7.05074 7.44226 7.0358 7.51334 7.03518 7.58526C7.03455 7.65718 7.04826 7.72851 7.07549 7.79508C7.10273 7.86165 7.14295 7.92212 7.19381 7.97298C7.24467 8.02384 7.30514 8.06406 7.37171 8.0913C7.43828 8.11853 7.50961 8.13224 7.58153 8.13161C7.65345 8.13099 7.72453 8.11604 7.79062 8.08766C7.8567 8.05927 7.91647 8.018 7.96644 7.96627Z"
          fill="black"
        />
        <path
          id="Vector_4"
          d="M18.0339 15.867L16.9505 16.9504C16.8988 17.0003 16.8575 17.0601 16.8291 17.1262C16.8007 17.1923 16.7858 17.2633 16.7852 17.3353C16.7846 17.4072 16.7983 17.4785 16.8255 17.5451C16.8527 17.6116 16.8929 17.6721 16.9438 17.723C16.9947 17.7738 17.0551 17.8141 17.1217 17.8413C17.1883 17.8685 17.2596 17.8822 17.3315 17.8816C17.4035 17.881 17.4745 17.866 17.5406 17.8377C17.6067 17.8093 17.6665 17.768 17.7164 17.7163L18.7998 16.6329C18.8984 16.5308 18.953 16.3939 18.9518 16.2519C18.9506 16.1099 18.8936 15.974 18.7932 15.8736C18.6927 15.7732 18.5569 15.7162 18.4149 15.715C18.2728 15.7138 18.136 15.7683 18.0339 15.867Z"
          fill="black"
        />
        <path
          id="Vector_5"
          d="M11.2163 6.65871C11.1147 6.55717 10.9769 6.50012 10.8333 6.50012C10.6897 6.50012 10.5519 6.55717 10.4504 6.65871L7.74202 9.36705C7.69029 9.41701 7.64902 9.47679 7.62064 9.54287C7.59225 9.60896 7.57731 9.68003 7.57668 9.75196C7.57606 9.82388 7.58976 9.8952 7.617 9.96177C7.64423 10.0283 7.68445 10.0888 7.73531 10.1397C7.78617 10.1905 7.84665 10.2308 7.91322 10.258C7.97979 10.2852 8.05111 10.2989 8.12303 10.2983C8.19496 10.2977 8.26603 10.2827 8.33212 10.2544C8.3982 10.226 8.45797 10.1847 8.50794 10.133L11.2163 7.42463C11.3178 7.32305 11.3749 7.1853 11.3749 7.04167C11.3749 6.89804 11.3178 6.76029 11.2163 6.65871Z"
          fill="black"
        />
        <path
          id="Vector_6"
          d="M14.7836 17.1746C14.8852 17.2762 15.0229 17.3332 15.1666 17.3332C15.3102 17.3332 15.4479 17.2762 15.5495 17.1746L18.2578 14.4663C18.3565 14.3641 18.4111 14.2273 18.4099 14.0853C18.4086 13.9433 18.3517 13.8074 18.2512 13.707C18.1508 13.6066 18.015 13.5496 17.8729 13.5484C17.7309 13.5471 17.5941 13.6017 17.4919 13.7004L14.7836 16.4087C14.682 16.5103 14.625 16.6481 14.625 16.7917C14.625 16.9353 14.682 17.0731 14.7836 17.1746Z"
          fill="black"
        />
      </g>
    </g>
  </svg>
);

export default Window;
