// Libs
import { Suspense } from 'react';

// Components
import Loading from '../components/common/Loading/Loading';

export default (Component) => (props) =>
  (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );
