const MoonSvg = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="41785437316276572803767 1">
      <path
        id="Vector"
        d="M21.0939 18.7266C19.0141 18.9028 16.9296 18.4467 15.1135 17.418C12.699 16.0584 10.9226 13.7964 10.1741 11.1285C9.4257 8.4605 9.76632 5.60461 11.1213 3.18753C11.3797 2.73089 11.6708 2.29355 11.9924 1.87893C12.0494 1.80365 12.0837 1.71367 12.0913 1.61957C12.0989 1.52547 12.0795 1.43114 12.0353 1.34768C11.99 1.26384 11.921 1.19511 11.837 1.14999C11.753 1.10488 11.6576 1.08533 11.5627 1.09378C9.67574 1.25507 7.85855 1.8832 6.27476 2.92157C4.69097 3.95996 3.39034 5.37597 2.49 7.04211C1.58967 8.70824 1.1179 10.5721 1.11719 12.466C1.11648 14.3598 1.58685 16.2241 2.48593 17.8909C3.38502 19.5577 4.6846 20.9747 6.26761 22.0143C7.85058 23.0538 9.66734 23.6833 11.5541 23.846C13.441 24.0087 15.3387 23.6996 17.0762 22.9464C18.8139 22.1931 20.3368 21.0196 21.508 19.5313C21.5664 19.456 21.6018 19.3655 21.6101 19.2706C21.6185 19.1757 21.5993 19.0804 21.5549 18.9961C21.5114 18.9122 21.445 18.8424 21.3634 18.7947C21.2818 18.747 21.1884 18.7234 21.0939 18.7266ZM7.37519 21.586C5.50632 20.5353 4.00601 18.935 3.07795 17.0024C2.14988 15.0697 1.83901 12.8983 2.18753 10.7828C2.53605 8.66742 3.52708 6.71046 5.02609 5.17765C6.52511 3.64487 8.45945 2.61046 10.5666 2.21487C10.465 2.37503 10.3635 2.543 10.2697 2.70706C9.29964 4.43175 8.79394 6.37879 8.80214 8.35754C8.81031 10.3363 9.33206 12.2791 10.3164 13.9957C11.3007 15.7123 12.7138 17.144 14.4174 18.1506C16.121 19.1572 18.0568 19.7043 20.0353 19.7383C18.4192 21.3911 16.3048 22.4672 14.0174 22.8011C11.73 23.1349 9.39628 22.708 7.37519 21.586Z"
        fill="black"
      />
      <path
        id="Vector_2"
        d="M23.1209 5.97266C23.0926 5.88676 23.0411 5.81035 22.9722 5.75176C22.9033 5.6932 22.8196 5.65473 22.7303 5.64062L19.9217 5.23437L18.6678 2.69532C18.6274 2.6138 18.565 2.5452 18.4877 2.49724C18.4104 2.44927 18.3212 2.42386 18.2303 2.42386C18.1393 2.42386 18.0501 2.44927 17.9728 2.49724C17.8955 2.5452 17.8332 2.6138 17.7928 2.69532L16.5389 5.23437L13.7342 5.625C13.6448 5.6391 13.5611 5.67758 13.4922 5.73613C13.4233 5.79473 13.3719 5.87113 13.3435 5.95703C13.3162 6.04441 13.3135 6.13762 13.3355 6.22648C13.3576 6.31535 13.4036 6.39645 13.4685 6.46094L15.4959 8.4375L15.0193 11.2305C15.0067 11.3005 15.0097 11.3725 15.028 11.4414C15.0464 11.5102 15.0797 11.5741 15.1256 11.6285C15.1715 11.683 15.2288 11.7266 15.2935 11.7564C15.3581 11.7862 15.4286 11.8013 15.4998 11.8008C15.5785 11.8004 15.6561 11.7816 15.7264 11.7461L18.2342 10.4258L20.742 11.7461C20.8231 11.788 20.9143 11.8066 21.0053 11.7997C21.0964 11.7929 21.1837 11.7607 21.2576 11.707C21.3313 11.6538 21.3885 11.5808 21.4223 11.4965C21.4562 11.4121 21.4655 11.3199 21.449 11.2305L20.9724 8.4375L22.9998 6.46094C23.062 6.39797 23.1061 6.31937 23.1274 6.23344C23.1487 6.1475 23.1465 6.05742 23.1209 5.97266ZM20.1014 7.92578C20.045 7.98078 20.0028 8.04855 19.9783 8.12336C19.9538 8.1982 19.9478 8.27781 19.9607 8.35547L20.3162 10.4258L18.4568 9.44922C18.3869 9.41262 18.3092 9.39348 18.2303 9.39348C18.1514 9.39348 18.0736 9.41262 18.0037 9.44922L16.1443 10.4258L16.4998 8.35547C16.5127 8.27781 16.5067 8.1982 16.4822 8.12336C16.4577 8.04855 16.4155 7.98078 16.3592 7.92578L14.8435 6.46484L16.9217 6.16406C17 6.1534 17.0745 6.12359 17.1385 6.07727C17.2026 6.03094 17.2542 5.96949 17.2889 5.89844L18.2185 4.01562L19.1482 5.89844C19.1829 5.96949 19.2345 6.03094 19.2985 6.07727C19.3626 6.12359 19.4371 6.1534 19.5154 6.16406L21.5935 6.46484L20.1014 7.92578Z"
        fill="black"
      />
    </g>
  </svg>
);

export default MoonSvg;
