const StarSvg = () => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="20627715151555590645 1" clipPath="url(#clip0_45_363)">
      <path
        id="Vector"
        d="M8.99998 1.1618L11.7808 6.79704L18 7.70065L13.5 12.0871L14.562 18.2808L8.99998 15.3566L3.43765 18.2808L4.50001 12.0871L0 7.70065L6.21881 6.79704L8.99998 1.1618Z"
        fill="#FAC917"
      />
    </g>
    <defs>
      <clipPath id="clip0_45_363">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0 0.721313)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default StarSvg;
