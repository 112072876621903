// Libs
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Locales
import translationEN from '@app/locales/en/translation.json';
import translationVI from '@app/locales/vi/translation.json';

// Constants
import { LANGUAGE_CODE } from './constants';

const resources = {
  en: {
    translation: translationEN,
  },
  vi: {
    translation: translationVI,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: LANGUAGE_CODE.VIETNAMESE,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
