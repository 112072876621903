const InformationSvg = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="9.42102" cy="9.79999" rx="8.95813" ry="8.94" fill="#BDBDBD" />
    <rect
      x="7.92798"
      y="7.81335"
      width="2.98602"
      height="7.94665"
      rx="1.49301"
      fill="white"
    />
    <rect
      x="7.92798"
      y="3.83997"
      width="2.98602"
      height="2.98"
      rx="1.49"
      fill="white"
    />
  </svg>
);

export default InformationSvg;
