const HandWithCoinSvg = () => (
  <svg
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00113 37.923C7.61714 37.923 8.11651 37.4237 8.11651 36.8076C8.11651 36.1916 7.61714 35.6923 7.00113 35.6923C6.38512 35.6923 5.88574 36.1916 5.88574 36.8076C5.88574 37.4237 6.38512 37.923 7.00113 37.923Z"
      fill="#3366CC"
    />
    <path
      d="M33.7704 33.4615C34.3864 33.4615 34.8858 32.9621 34.8858 32.3461C34.8858 31.7301 34.3864 31.2307 33.7704 31.2307C33.1544 31.2307 32.655 31.7301 32.655 32.3461C32.655 32.9621 33.1544 33.4615 33.7704 33.4615Z"
      fill="#3366CC"
    />
    <path
      d="M42.6935 31.2308C43.3095 31.2308 43.8089 30.7314 43.8089 30.1154C43.8089 29.4994 43.3095 29 42.6935 29C42.0775 29 41.5781 29.4994 41.5781 30.1154C41.5781 30.7314 42.0775 31.2308 42.6935 31.2308Z"
      fill="#3366CC"
    />
    <path
      d="M51.6166 20.0768C52.2326 20.0768 52.732 19.5775 52.732 18.9615C52.732 18.3454 52.2326 17.8461 51.6166 17.8461C51.0006 17.8461 50.5012 18.3454 50.5012 18.9615C50.5012 19.5775 51.0006 20.0768 51.6166 20.0768Z"
      fill="#3366CC"
    />
    <path
      d="M23.7318 20.0768C24.3478 20.0768 24.8472 19.5775 24.8472 18.9615C24.8472 18.3454 24.3478 17.8461 23.7318 17.8461C23.1158 17.8461 22.6165 18.3454 22.6165 18.9615C22.6165 19.5775 23.1158 20.0768 23.7318 20.0768Z"
      fill="#3366CC"
    />
    <path
      d="M33.7704 7.80767C34.3864 7.80767 34.8858 7.3083 34.8858 6.69229C34.8858 6.07628 34.3864 5.5769 33.7704 5.5769C33.1544 5.5769 32.655 6.07628 32.655 6.69229C32.655 7.3083 33.1544 7.80767 33.7704 7.80767Z"
      fill="#3366CC"
    />
    <path
      d="M7.00113 6.69232C7.61714 6.69232 8.11651 6.19294 8.11651 5.57693C8.11651 4.96092 7.61714 4.46155 7.00113 4.46155C6.38512 4.46155 5.88574 4.96092 5.88574 5.57693C5.88574 6.19294 6.38512 6.69232 7.00113 6.69232Z"
      fill="#3366CC"
    />
    <path
      d="M44.9242 53.5384C45.5402 53.5384 46.0396 53.039 46.0396 52.423C46.0396 51.807 45.5402 51.3076 44.9242 51.3076C44.3082 51.3076 43.8088 51.807 43.8088 52.423C43.8088 53.039 44.3082 53.5384 44.9242 53.5384Z"
      fill="#3366CC"
    />
    <path
      d="M12.8665 16.3137C12.6471 16.2396 12.4505 16.1622 12.2763 16.0815C12.1022 16.0009 11.9587 15.9235 11.846 15.8493C11.733 15.7753 11.6636 15.7221 11.6381 15.6897L10.9417 17.0729C11.2898 17.3051 11.6817 17.4922 12.1168 17.634C12.5522 17.7758 12.9923 17.8661 13.4373 17.9048V19.0366H14.1045V17.9145C14.4141 17.9014 14.7092 17.8531 14.9897 17.7694C15.2703 17.6856 15.5184 17.5613 15.7346 17.3969C15.9507 17.2325 16.1229 17.0214 16.2522 16.7634C16.3812 16.5055 16.4456 16.1927 16.4456 15.825C16.4456 15.5092 16.3955 15.2383 16.2956 15.0125C16.1957 14.7868 16.0538 14.595 15.8701 14.4369C15.6864 14.279 15.4622 14.1467 15.1978 14.0403C14.9334 13.9339 14.6368 13.8357 14.3079 13.7453L13.9984 13.6679V12.3524C14.1788 12.3782 14.3579 12.4218 14.5352 12.4829C14.7125 12.5442 14.8738 12.6086 15.0188 12.6763C15.1641 12.744 15.2833 12.8069 15.3768 12.8649C15.4702 12.9229 15.5269 12.965 15.5462 12.9906L16.2425 11.6751C15.9717 11.5203 15.6526 11.3688 15.285 11.2205C14.9173 11.0722 14.5239 10.9787 14.1048 10.94V9.96301H13.4375V10.94C13.128 10.9723 12.8393 11.0416 12.5717 11.148C12.3041 11.2544 12.0719 11.4012 11.8752 11.5881C11.6784 11.7753 11.5222 11.9993 11.4061 12.2604C11.29 12.5216 11.232 12.82 11.232 13.1551C11.232 13.4131 11.269 13.6388 11.3432 13.8323C11.4174 14.0258 11.5302 14.1967 11.6818 14.3449C11.8332 14.4934 12.0251 14.6239 12.2572 14.7366C12.4894 14.8496 12.7634 14.9542 13.0795 15.0511L13.5437 15.1865V16.473C13.3114 16.4411 13.0856 16.3879 12.8665 16.3137ZM13.9981 15.3318C14.2238 15.4092 14.4045 15.4948 14.5398 15.5881C14.6752 15.6817 14.7429 15.8123 14.7429 15.9798C14.7429 16.3024 14.4945 16.4732 13.998 16.4926L13.9981 15.3318ZM13.0358 13.286C12.9229 13.1926 12.8665 13.0652 12.8665 12.9038C12.8665 12.5687 13.0921 12.3815 13.5435 12.3428V13.5326C13.3178 13.4618 13.1484 13.3795 13.0358 13.286Z"
      fill="#3366CC"
    />
    <path
      d="M25.4051 9.4807C26.6351 9.4807 27.6359 8.47998 27.6359 7.24993C27.6359 6.01989 26.6351 5.01917 25.4051 5.01917C24.175 5.01917 23.1743 6.01989 23.1743 7.24993C23.1743 8.47998 24.175 9.4807 25.4051 9.4807Z"
      fill="#3366CC"
    />
    <path
      d="M6.44336 27.8846C6.44336 28.8072 7.1939 29.5577 8.11644 29.5577C9.03897 29.5577 9.78951 28.8072 9.78951 27.8846C9.78951 26.9621 9.03897 26.2115 8.11644 26.2115C7.1939 26.2115 6.44336 26.9621 6.44336 27.8846Z"
      fill="#3366CC"
    />
    <path
      d="M46.5974 25.6538C46.5974 26.5763 47.348 27.3269 48.2705 27.3269C49.193 27.3269 49.9436 26.5763 49.9436 25.6538C49.9436 24.7313 49.193 23.9807 48.2705 23.9807C47.348 23.9807 46.5974 24.7313 46.5974 25.6538Z"
      fill="#3366CC"
    />
    <path
      d="M22.5506 32.7109C22.5506 32.5431 22.4829 32.4126 22.3475 32.3192C22.2121 32.2257 22.0316 32.1402 21.8059 32.0627V33.2234C22.3024 33.204 22.5506 33.0332 22.5506 32.7109Z"
      fill="#3366CC"
    />
    <path
      d="M21.5011 37.3653C24.8837 37.3653 27.6357 34.6133 27.6357 31.2307C27.6357 27.8481 24.8837 25.0961 21.5011 25.0961C18.1184 25.0961 15.3665 27.8481 15.3665 31.2307C15.3665 34.6133 18.1184 37.3653 21.5011 37.3653ZM20.8868 31.782C20.5707 31.6853 20.2967 31.5804 20.0646 31.4676C19.8325 31.3548 19.6405 31.2243 19.4891 31.0758C19.3376 30.9276 19.2247 30.7568 19.1505 30.5631C19.0763 30.3697 19.0393 30.144 19.0393 29.8861C19.0393 29.5508 19.0973 29.2523 19.2134 28.9913C19.3295 28.7303 19.4857 28.5062 19.6825 28.3191C19.8792 28.1321 20.1114 27.9854 20.379 27.879C20.6465 27.7725 20.9352 27.7033 21.2449 27.6709V26.694H21.9122V27.6709C22.3313 27.7096 22.7246 27.8032 23.0923 27.9515C23.4599 28.0999 23.779 28.2513 24.0498 28.4061L23.3535 29.7217C23.3342 29.696 23.2775 29.6541 23.1841 29.5959C23.0906 29.5378 22.9714 29.475 22.8261 29.4074C22.6811 29.3398 22.5199 29.2751 22.3425 29.214C22.1652 29.1528 21.9863 29.1092 21.8057 29.0833V30.3989L22.1152 30.4761C22.444 30.5667 22.7406 30.6647 23.0052 30.7713C23.2695 30.8777 23.4937 31.0101 23.6774 31.168C23.8611 31.3259 24.003 31.5179 24.1029 31.7433C24.2029 31.9692 24.2529 32.24 24.2529 32.5559C24.2529 32.9236 24.1885 33.2365 24.0595 33.4941C23.9304 33.7522 23.7581 33.9634 23.5419 34.1279C23.3257 34.2925 23.0777 34.4166 22.797 34.5002C22.5165 34.5841 22.2215 34.6326 21.912 34.6453V35.7676H21.2446V34.636C20.7996 34.5971 20.3595 34.507 19.9241 34.3651C19.489 34.2234 19.0971 34.0362 18.749 33.804L19.4454 32.4209C19.471 32.4531 19.5404 32.5064 19.6533 32.5804C19.7661 32.6547 19.9096 32.732 20.0836 32.8126C20.2579 32.8936 20.4544 32.9708 20.6738 33.0449C20.893 33.1189 21.1187 33.1725 21.3508 33.2044V31.9178L20.8868 31.782Z"
      fill="#3366CC"
    />
    <path
      d="M48.8283 47.4038C48.2133 47.4038 47.7129 47.9042 47.7129 48.5192C47.7129 49.1342 48.2133 49.6346 48.8283 49.6346C49.4434 49.6346 49.9437 49.1342 49.9437 48.5192C49.9437 47.9042 49.4432 47.4038 48.8283 47.4038Z"
      fill="#3366CC"
    />
    <path
      d="M34.7356 15.6891C34.2842 15.7278 34.0586 15.9148 34.0586 16.2501C34.0586 16.4114 34.1151 16.5388 34.228 16.6323C34.3409 16.7258 34.5101 16.808 34.7359 16.8789L34.7356 15.6891Z"
      fill="#3366CC"
    />
    <path
      d="M49.5319 32.8464C48.8262 32.8464 48.1628 33.1214 47.6637 33.6203C47.6593 33.6248 47.6547 33.6291 47.6501 33.6335L37.62 43.0067C37.503 43.6666 37.1978 44.2856 36.7297 44.7861C36.0303 45.534 35.0423 45.9379 34.0195 45.8929L22.7029 45.3957C22.3952 45.3822 22.1567 45.1218 22.1702 44.8141C22.1837 44.5064 22.446 44.2705 22.7517 44.2815L34.0682 44.7786C34.767 44.8094 35.4383 44.5342 35.9151 44.0243C36.3918 43.5143 36.6208 42.8242 36.5437 42.1306C36.4184 41.0024 35.5065 40.1131 34.3756 40.0161C34.3711 40.0157 34.3667 40.0152 34.3623 40.0147L13.9955 37.7779L6.05688 45.7163L14.2053 53.8646L16.2232 51.847L11.6248 47.2484C11.4069 47.0307 11.4069 46.6775 11.6248 46.4598C11.8427 46.242 12.1957 46.2421 12.4135 46.4598L17.2514 51.2979L36.7275 52.0296L51.4006 37.3566C52.4307 36.3264 52.4307 34.6502 51.4006 33.6201C50.9011 33.1214 50.2376 32.8464 49.5319 32.8464Z"
      fill="#3366CC"
    />
    <path
      d="M34.8858 23.9808C38.2685 23.9808 41.0205 21.2288 41.0205 17.8462C41.0205 14.4635 38.2685 11.7115 34.8858 11.7115C31.5033 11.7115 28.7512 14.4635 28.7512 17.8462C28.7512 21.2288 31.503 23.9808 34.8858 23.9808ZM34.2715 18.3975C33.9554 18.3007 33.6816 18.1961 33.4493 18.0831C33.2171 17.9703 33.0252 17.8397 32.8738 17.6913C32.7223 17.5431 32.6093 17.3721 32.5353 17.1787C32.461 16.9853 32.4241 16.7596 32.4241 16.5016C32.4241 16.1664 32.4821 15.8679 32.5984 15.6068C32.7143 15.3457 32.8705 15.1216 33.0674 14.9346C33.264 14.7476 33.4962 14.6008 33.7639 14.4944C34.0313 14.388 34.32 14.3188 34.6296 14.2864V13.3094H35.2971V14.2864C35.716 14.3251 36.1094 14.4187 36.4772 14.5669C36.8447 14.7154 37.1638 14.8667 37.4348 15.0216L36.7384 16.337C36.719 16.3114 36.6623 16.2693 36.5688 16.2113C36.4753 16.1533 36.3562 16.0904 36.2111 16.0227C36.0659 15.955 35.9046 15.8907 35.7272 15.8293C35.5498 15.7681 35.3711 15.7246 35.1903 15.6988V17.0143L35.4999 17.0917C35.8287 17.1822 36.1251 17.2803 36.3898 17.3867C36.6545 17.4931 36.8785 17.6254 37.0621 17.7834C37.2458 17.9414 37.3878 18.1333 37.4876 18.3589C37.5875 18.5847 37.6376 18.8556 37.6376 19.1715C37.6376 19.5391 37.5731 19.852 37.4442 20.1098C37.3153 20.3678 37.1427 20.579 36.9268 20.7434C36.7105 20.9078 36.4623 21.032 36.1818 21.1158C35.9012 21.1996 35.606 21.2479 35.2966 21.2609V22.383H34.6292V21.2512C34.1845 21.2125 33.744 21.1224 33.3088 20.9804C32.8737 20.8386 32.4816 20.6515 32.1335 20.4194L32.83 19.0362C32.8555 19.0685 32.9251 19.1217 33.038 19.1958C33.1506 19.27 33.2942 19.3474 33.4683 19.4279C33.6425 19.5086 33.8388 19.5859 34.0582 19.6601C34.2775 19.7343 34.5033 19.7875 34.7353 19.8197V18.5332L34.2715 18.3975Z"
      fill="#3366CC"
    />
    <path
      d="M46.0396 16.173C49.4223 16.173 52.1743 13.4211 52.1743 10.0384C52.1743 6.6558 49.4223 3.90381 46.0396 3.90381C42.6571 3.90381 39.905 6.6558 39.905 10.0384C39.905 13.4211 42.6569 16.173 46.0396 16.173ZM45.4253 10.5898C45.1092 10.4929 44.8354 10.3883 44.6031 10.2753C44.3709 10.1626 44.179 10.032 44.0277 9.88361C43.8761 9.73537 43.7633 9.56439 43.6892 9.37098C43.6149 9.17757 43.5779 8.95182 43.5779 8.69383C43.5779 8.35865 43.6359 8.06029 43.7522 7.79907C43.8681 7.53795 44.0243 7.31387 44.2212 7.12682C44.4178 6.93989 44.65 6.7931 44.9177 6.68669C45.1851 6.58029 45.4738 6.51102 45.7834 6.47867V5.50171H46.4509V6.47867C46.8698 6.51738 47.2632 6.61096 47.631 6.75919C47.9985 6.90765 48.3176 7.05901 48.5886 7.21382L47.8922 8.52931C47.8728 8.50377 47.8161 8.46161 47.7227 8.4036C47.6291 8.3456 47.5101 8.2827 47.3649 8.21499C47.2197 8.14729 47.0584 8.08293 46.881 8.02159C46.7036 7.96035 46.525 7.91685 46.3441 7.89109V9.20657L46.6537 9.28398C46.9825 9.37444 47.2789 9.47259 47.5436 9.579C47.8083 9.6854 48.0323 9.81769 48.2159 9.97563C48.3996 10.1337 48.5416 10.3255 48.6414 10.5512C48.7413 10.7769 48.7914 11.0478 48.7914 11.3637C48.7914 11.7314 48.7269 12.0442 48.598 12.3021C48.4691 12.5601 48.2965 12.7712 48.0806 12.9356C47.8643 13.1 47.6161 13.2243 47.3356 13.3081C47.055 13.3918 46.7599 13.4401 46.4504 13.4532V14.5753H45.783V13.4435C45.3383 13.4048 44.8978 13.3146 44.4626 13.1727C44.0274 13.0309 43.6354 12.8437 43.2873 12.6116L43.9838 11.2284C44.0094 11.2608 44.0789 11.314 44.1918 11.388C44.3044 11.4622 44.448 11.5396 44.6221 11.6202C44.7963 11.7009 44.9926 11.7782 45.212 11.8524C45.4313 11.9265 45.6571 11.9798 45.8891 12.012V10.7252L45.4253 10.5898Z"
      fill="#3366CC"
    />
    <path
      d="M35.9353 19.3261C35.9353 19.1586 35.8677 19.028 35.7324 18.9344C35.5968 18.8411 35.4163 18.7555 35.1907 18.6781V19.8389C35.6869 19.8194 35.9353 19.6487 35.9353 19.3261Z"
      fill="#3366CC"
    />
    <path
      d="M21.3511 29.0736C20.8997 29.1124 20.6741 29.2992 20.6741 29.6345C20.6741 29.7959 20.7305 29.9232 20.8434 30.0168C20.9562 30.1104 21.1254 30.1924 21.3512 30.2634L21.3511 29.0736Z"
      fill="#3366CC"
    />
    <path
      d="M47.0891 11.5184C47.0891 11.3509 47.0215 11.2203 46.8862 11.1267C46.7506 11.0333 46.5701 10.9478 46.3445 10.8704V12.0311C46.8407 12.0116 47.0891 11.841 47.0891 11.5184Z"
      fill="#3366CC"
    />
    <path
      d="M45.8897 7.88135C45.4383 7.92005 45.2126 8.1071 45.2126 8.44239C45.2126 8.60367 45.2692 8.73105 45.3821 8.82452C45.4949 8.9181 45.6642 9.0003 45.8899 9.07113L45.8897 7.88135Z"
      fill="#3366CC"
    />
  </svg>
);

export default HandWithCoinSvg;
