const ArrowPrevBlue = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="9953692481543238867 (1) 2">
      <path
        id="Vector"
        d="M15 6L9 12L15 18"
        stroke="#1E79E4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default ArrowPrevBlue;
