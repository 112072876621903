const DotThree = () => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="5992412641579237606 1">
      <path
        id="XMLID_30_"
        d="M7.53284 19.0109C6.88719 19.0109 6.20567 18.7239 5.73936 18.2576C5.27306 17.7913 5.02197 17.1457 5.02197 16.5C5.02197 15.8544 5.30893 15.2087 5.73936 14.7065C6.20567 14.2402 6.85132 13.9891 7.53284 13.9891C8.17849 13.9891 8.82415 14.2761 9.29045 14.7065C9.75676 15.1728 10.0437 15.8185 10.0437 16.5C10.0437 17.1457 9.75676 17.7913 9.29045 18.2576C8.82415 18.7239 8.17849 19.0109 7.53284 19.0109ZM18.2578 18.2576C18.7241 17.7913 19.0111 17.1457 19.0111 16.5C19.0111 15.8544 18.7241 15.2087 18.2578 14.7065C17.7915 14.2402 17.1459 13.9891 16.5002 13.9891C15.8546 13.9891 15.1731 14.2761 14.7068 14.7065C14.2405 15.1728 13.9894 15.8185 13.9894 16.5C13.9894 17.1457 14.2763 17.7913 14.7068 18.2576C15.1731 18.7239 15.8187 19.0109 16.5002 19.0109C17.1459 19.0109 17.7915 18.7239 18.2578 18.2576ZM27.2252 18.2576C27.6915 17.7913 27.9785 17.1457 27.9785 16.5C27.9785 15.8544 27.6915 15.2087 27.2252 14.7065C26.7589 14.2402 26.1133 13.9891 25.4676 13.9891C24.822 13.9891 24.1405 14.2761 23.6741 14.7065C23.2078 15.1728 22.9568 15.8185 22.9568 16.5C22.9568 17.1457 23.2437 17.7913 23.6741 18.2576C24.1405 18.7239 24.7861 19.0109 25.4676 19.0109C26.1133 19.0109 26.7589 18.7239 27.2252 18.2576Z"
        fill="black"
      />
    </g>
  </svg>
);

export default DotThree;
