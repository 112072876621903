export default () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9987 24H1.7082C0.766816 24 0.0234375 23.2393 0.0234375 22.3153V4.45682C0.0234375 3.51196 0.784184 2.76859 1.7082 2.76859H22.9987C23.9435 2.76859 24.6834 3.53281 24.6834 4.45682V22.3153C24.7043 23.2393 23.9435 24 22.9987 24ZM1.7082 3.45291C1.16629 3.45291 0.725131 3.89407 0.725131 4.43598V22.2944C0.725131 22.8363 1.16629 23.2775 1.7082 23.2775H22.9987C23.5406 23.2775 23.9818 22.8363 23.9818 22.2944V4.43598C23.9818 3.89407 23.5406 3.45291 22.9987 3.45291H1.7082Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.3404 8.46897H0.361268C0.159792 8.46897 0 8.30917 0 8.1077C0 7.90622 0.159792 7.74643 0.361268 7.74643H24.3404C24.5419 7.74643 24.7017 7.90622 24.7017 8.1077C24.7017 8.30917 24.5419 8.46897 24.3404 8.46897Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1444 6.20061C18.9429 6.20061 18.7831 6.04082 18.7831 5.83934C18.7831 5.63786 18.9429 5.47807 19.1444 5.47807C20.4678 5.47807 21.5308 4.39427 21.5308 3.09162C21.5308 1.7855 20.4678 0.722537 19.1444 0.722537C17.8209 0.722537 16.7579 1.80634 16.7579 3.10899C16.7579 3.31047 16.5946 3.47026 16.3966 3.47026C16.1952 3.47026 16.0527 3.28962 16.0527 3.10899C16.0527 1.40339 17.4388 0.0208435 19.1444 0.0208435C20.85 0.0208435 22.236 1.40339 22.236 3.10899C22.236 4.81459 20.85 6.20061 19.1444 6.20061Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.359 6.20061C12.161 6.20061 11.9977 6.04082 11.9977 5.83934C11.9977 5.63786 12.161 5.47807 12.359 5.47807C13.686 5.47807 14.7489 4.39427 14.7489 3.09162C14.7489 1.7855 13.6651 0.701693 12.359 0.701693C11.0563 0.701693 9.97254 1.7855 9.97254 3.09162C9.97254 3.28962 9.81274 3.45289 9.61127 3.45289C9.40979 3.45289 9.25 3.28962 9.25 3.09162C9.25 1.38602 10.636 0 12.3416 0C14.0472 0 15.4506 1.40339 15.4506 3.10899C15.4506 4.81459 14.0646 6.20061 12.359 6.20061Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.57905 6.20061C5.37757 6.20061 5.21778 6.04082 5.21778 5.83934C5.21778 5.63786 5.37757 5.49892 5.57905 5.49892C6.90254 5.49892 7.9655 4.41511 7.9655 3.10899C7.9655 1.80634 6.8817 0.722537 5.57905 0.722537C4.27293 0.722537 3.17175 1.7855 3.17175 3.10899C3.17175 3.31047 3.01196 3.47026 2.81049 3.47026C2.60901 3.47026 2.44922 3.31047 2.44922 3.10899C2.44922 1.40339 3.83176 0.0208435 5.53736 0.0208435C7.24297 0.0208435 8.62898 1.40339 8.62898 3.10899C8.62898 4.81459 7.28465 6.20061 5.57905 6.20061Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0572 21.1724H7.66776C6.84448 21.1724 6.16016 20.4881 6.16016 19.6648V12.099C6.16016 11.2792 6.84448 10.5949 7.66776 10.5949H17.0572C17.8805 10.5949 18.5614 11.2792 18.5614 12.099V19.6648C18.5614 20.4881 17.8805 21.1724 17.0572 21.1724ZM7.66776 11.2792C7.22659 11.2792 6.86532 11.6405 6.86532 12.0816V19.6439C6.86532 20.0886 7.22659 20.4499 7.66776 20.4499H17.0572C17.4984 20.4499 17.8597 20.0886 17.8597 19.6439V12.099C17.8597 11.6578 17.4984 11.2966 17.0572 11.2966H7.66776V11.2792Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9169 18.984C10.8335 18.984 10.7362 18.9423 10.6737 18.8832L8.02674 16.2328C7.88432 16.0938 7.88432 15.8715 8.02674 15.7325C8.16569 15.5936 8.38801 15.5936 8.52696 15.7325L10.9169 18.1398L16.1935 12.8632C16.3324 12.7243 16.5547 12.7243 16.6937 12.8632C16.8361 13.0022 16.8361 13.2245 16.6937 13.3635L11.1566 18.8832C11.0975 18.9423 10.9968 18.984 10.9169 18.984Z"
      fill="black"
    />
  </svg>
);
