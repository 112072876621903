const BlackHeartSvg = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="11121507221641072514 1">
      <path
        id="Vector"
        d="M6.99998 13.4168C6.92334 13.4168 6.84745 13.4017 6.77664 13.3724C6.70584 13.3431 6.64152 13.3001 6.58736 13.2459L1.82955 8.48805C1.12166 7.78143 0.708418 6.83243 0.67333 5.83284C0.638243 4.83325 0.983927 3.85761 1.64052 3.10309C2.29712 2.34857 3.21566 1.87145 4.21052 1.76813C5.20537 1.66482 6.20235 1.94303 6.99998 2.54653C7.7599 1.97219 8.70199 1.69221 9.65224 1.7583C10.6025 1.82439 11.4968 2.23209 12.1699 2.9061L11.7578 3.31871L12.1704 2.9061C12.5373 3.27244 12.8283 3.70751 13.0269 4.18644C13.2255 4.66537 13.3277 5.17874 13.3277 5.69721C13.3277 6.21567 13.2255 6.72905 13.0269 7.20798C12.8283 7.6869 12.5373 8.12198 12.1704 8.48832L7.41259 13.2461C7.35845 13.3004 7.29412 13.3433 7.22331 13.3726C7.1525 13.4019 7.0766 13.4169 6.99998 13.4168ZM4.62107 2.91676C4.07093 2.9163 3.53303 3.0791 3.07548 3.38455C2.61794 3.69 2.26132 4.12436 2.0508 4.63263C1.84028 5.14089 1.78533 5.7002 1.89289 6.23971C2.00046 6.77923 2.26571 7.2747 2.65505 7.66336L6.99998 12.0083L11.3449 7.66336C11.8663 7.14187 12.1592 6.43463 12.1592 5.69721C12.1592 4.95978 11.8663 4.25255 11.3449 3.73106C10.823 3.21034 10.1159 2.9179 9.37875 2.9179C8.64154 2.9179 7.93444 3.21034 7.41259 3.73106C7.30317 3.84041 7.15481 3.90184 7.00011 3.90184C6.84542 3.90184 6.69705 3.84041 6.58763 3.73106C6.32953 3.4727 6.02294 3.26787 5.68546 3.12831C5.34798 2.98876 4.98626 2.91724 4.62107 2.91785V2.91676Z"
        fill="black"
      />
    </g>
  </svg>
);

export default BlackHeartSvg;
