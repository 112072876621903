const FaceBookAltSvg = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_967_384)">
      <path
        d="M26.6667 0H3.33333C1.49238 0 0 1.49238 0 3.33333V26.6667C0 28.5076 1.49238 30 3.33333 30H26.6667C28.5076 30 30 28.5076 30 26.6667V3.33333C30 1.49238 28.5076 0 26.6667 0Z"
        fill="#4460A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1934 5.59051V9.46988L22.891 9.47587C21.0862 9.47587 20.7384 10.3333 20.7384 11.5864V14.3626H25.0375L24.4799 18.7036H20.7384V30H16.2541V18.7036H12.5V14.3626H16.2541V11.1607C16.2541 7.44325 18.5199 5.41663 21.8417 5.41663C23.4252 5.41663 24.7917 5.53654 25.1934 5.59051Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_967_384">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FaceBookAltSvg;
