const AppleSvg = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_134_1084)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24ZM27.9072 14.1615C28.8235 12.9786 29.5182 11.3066 29.2669 9.6C27.7692 9.70408 26.0186 10.6623 24.9972 11.9113C24.066 13.043 23.3007 14.7265 23.5996 16.3604C25.2369 16.4116 26.9268 15.4303 27.9072 14.1615ZM35.1871 19.0269C30.4707 21.6273 31.2343 28.4026 36 30.215C35.3448 31.6754 35.0294 32.328 34.1854 33.6216C33.0079 35.4274 31.3476 37.6759 29.2883 37.6924C28.4417 37.7016 27.8862 37.4453 27.2871 37.169C26.5927 36.8487 25.8399 36.5015 24.5078 36.5095C23.1834 36.5165 22.4186 36.8596 21.7148 37.1752C21.1 37.451 20.5318 37.7058 19.678 37.6974C17.6203 37.6792 16.0471 35.6504 14.8696 33.8446C11.5753 28.7991 11.2288 22.8763 13.2602 19.7258C14.7054 17.4888 16.9848 16.1803 19.1262 16.1803C20.2672 16.1803 21.1866 16.51 22.0628 16.8242C22.8601 17.11 23.6216 17.3831 24.4815 17.3831C25.2772 17.3831 25.9315 17.1339 26.6452 16.862C27.5016 16.5358 28.4437 16.177 29.8187 16.177C31.727 16.177 33.7485 17.2228 35.1871 19.0269Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_134_1084">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AppleSvg;
