const HeartSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="4127559071586787810 1">
      <g id="Layer_1">
        <path
          id="Vector"
          d="M18.1125 10.366C20.0921 8.36438 20.0921 5.10758 18.1125 3.10598C17.1525 2.13478 15.8753 1.59998 14.5169 1.59998C13.1585 1.59998 11.8805 2.13478 10.9201 3.10598L10.0001 4.03598L9.08005 3.10598C8.11965 2.13478 6.84245 1.59998 5.48365 1.59998C4.12525 1.59998 2.84805 2.13478 1.88765 3.10598C-0.0919459 5.10758 -0.0919459 8.36438 1.88765 10.366L10.0001 18.5688L18.1125 10.366ZM2.45645 3.66838C3.26525 2.85038 4.34085 2.39998 5.48365 2.39998C6.62685 2.39998 7.70245 2.85038 8.51125 3.66838L10.0001 5.17398L11.4889 3.66838C12.2977 2.85038 13.3733 2.39998 14.5165 2.39998C15.6597 2.39998 16.7349 2.85038 17.5437 3.66838C19.2169 5.35998 19.2169 8.11198 17.5437 9.80358L10.0001 17.4312L2.45645 9.80358C0.783254 8.11198 0.783254 5.35958 2.45645 3.66838Z"
          fill="black"
        />
      </g>
    </g>
  </svg>
);

export default HeartSvg;
