const Note = () => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="5880383181586787931 1" clipPath="url(#clip0_560_169)">
      <g id="Layer_1_1_">
        <path
          id="Vector"
          d="M9.87842 17.1218H13.342L26.302 4.1618L22.8384 0.698242L9.87842 13.6582V17.1218ZM24.7749 4.1618L22.8384 6.09824L20.902 4.1618L22.8384 2.22536L24.7749 4.1618ZM10.9584 14.1054L20.1384 4.92536L22.0749 6.8618L12.8949 16.0418H10.9584V14.1054Z"
          fill="black"
        />
        <path
          id="Vector_2"
          d="M23.3782 10.6418H22.2982V25.2218H1.77824V4.70183H16.8982V3.62183H0.698242V26.3018H23.3782V10.6418Z"
          fill="black"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_560_169">
        <rect width="27" height="27" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Note;
