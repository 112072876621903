const FilterSvg = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="13432916241537184103 1" clipPath="url(#clip0_23_623)">
      <path
        id="Vector"
        d="M22.4468 3.35076C22.4468 2.21485 21.2736 1.36943 18.8601 0.766048C16.8841 0.272052 14.2702 0 11.4999 0C8.72972 0 6.11575 0.272052 4.13977 0.766048C1.72623 1.36943 0.552979 2.21485 0.552979 3.35076C0.552979 3.77107 0.714114 4.15153 1.03879 4.49476L8.48911 13.5316V22.1811C8.48911 22.4644 8.63551 22.7275 8.87623 22.8769C9.008 22.9586 9.15767 22.9999 9.30789 22.9999C9.43213 22.9999 9.55658 22.9716 9.67154 22.9147L14.0465 20.7463C14.3249 20.6083 14.5012 20.3246 14.5017 20.0138L14.5101 13.5321L21.9614 4.49421C22.2858 4.15109 22.4468 3.77096 22.4468 3.35076ZM4.90604 2.26714C6.71029 1.86114 9.052 1.63755 11.4999 1.63755C13.9478 1.63755 16.2897 1.86114 18.0937 2.26714C20.0861 2.7155 20.6461 3.19738 20.7801 3.35076C20.646 3.50415 20.0861 3.98603 18.0937 4.43439C16.2897 4.84039 13.9477 5.06397 11.4999 5.06397C9.0521 5.06397 6.71029 4.84039 4.90604 4.4345C2.91379 3.98614 2.35363 3.50426 2.21968 3.35087C2.35363 3.19738 2.91379 2.7155 4.90604 2.26714ZM13.0601 12.7168C12.9394 12.8632 12.8733 13.0468 12.8731 13.2366L12.8649 19.5044L10.1268 20.8616V13.2377C10.1268 13.0476 10.0607 12.8634 9.93977 12.7168L4.40014 5.9976C6.34272 6.45197 8.84905 6.70164 11.4999 6.70164C14.1509 6.70164 16.6571 6.45197 18.5997 5.9976L13.0601 12.7168Z"
        fill="#726C6C"
      />
    </g>
    <defs>
      <clipPath id="clip0_23_623">
        <rect width="23" height="23" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FilterSvg;
