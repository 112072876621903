const ArrowDownWhite = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="20004601431537182966 1">
      <path
        id="Vector"
        d="M0 3.26062L6.50002 9.73947L13 3.26062H0Z"
        fill="white"
      />
    </g>
  </svg>
);

export default ArrowDownWhite;
