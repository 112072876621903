export default {
  layout: 'top',
  // contentWidth: 'Fluid',
  splitMenus: true,
  //   loading: true,
  siderMenuType: 'group',
  logo: null,
  prefixCls: 'sapphirebooking-storefront',
  collapsedButtonRender: false,
  menu: {
    collapsedShowGroupTitle: false,
  },
  fixSiderbar: true,
};
