export const LOCAL_STORAGE = {
  LANGUAGE: 'LANGUAGE',
};

export const LANGUAGE_CODE = {
  ENGLISH: 'en',
  VIETNAMESE: 'vi',
  JAPANESE: 'ja',
  CHINESE: 'cn',
};

export const LANGUAGE_COUNTRY_CODE = {
  ENGLISH: 'enUS',
  VIETNAMESE: 'vi',
  JAPANESE: 'ja',
  CHINESE: 'cn',
};

export const SUPPORTED_LANGUAGE_COUNTRY_CODES = [
  LANGUAGE_COUNTRY_CODE.VIETNAMESE,
  LANGUAGE_COUNTRY_CODE.ENGLISH,
  LANGUAGE_COUNTRY_CODE.JAPANESE,
  LANGUAGE_COUNTRY_CODE.CHINESE,
];

export const LANGUAGE_COUNTRY_CODE_MAPPING = {
  [LANGUAGE_CODE.ENGLISH]: LANGUAGE_COUNTRY_CODE.ENGLISH,
  [LANGUAGE_CODE.VIETNAMESE]: LANGUAGE_COUNTRY_CODE.VIETNAMESE,
  [LANGUAGE_CODE.JAPANESE]: LANGUAGE_COUNTRY_CODE.JAPANESE,
  [LANGUAGE_CODE.CHINESE]: LANGUAGE_COUNTRY_CODE.CHINESE,
};

export const DEFAULT_CURRENCY_CODE = 'VND';

export const PRODUCT = {
  NAME: 'SapphireBooking',
};

export const TOKEN = {
  TYPE: 'Bearer',
};

export const CONTENT_TYPE = {
  JSON: 'application/json',
};

export const PATH_NAME = {
  HOME: '',
  USER: {
    INDEX: 'user',
    LOGIN: 'login',
    SIGN_UP: 'sign-up',
    PROFILE: 'profile',
    BOOKING_TRIP: 'booking-trip',
    REVIEW: 'review',
  },
  BOOKING: {
    INDEX: 'booking',
    CART: 'cart',
  },
  SEARCH: 'search',
  NOT_FOUND: 'not-found',
  PROPERTY_DETAIL: 'property',
};

export const ROUTES = {
  HOME: '/',
  CART: [PATH_NAME.HOME, PATH_NAME.BOOKING.CART].join('/'),
  NOT_FOUND: [PATH_NAME.HOME, PATH_NAME.NOT_FOUND].join('/'),
  BOOKING: [PATH_NAME.HOME, PATH_NAME.BOOKING.INDEX].join('/'),
  USER: {
    LOGIN: [PATH_NAME.HOME, PATH_NAME.USER.INDEX, PATH_NAME.USER.LOGIN].join(
      '/'
    ),
    SIGN_UP: [
      PATH_NAME.HOME,
      PATH_NAME.USER.INDEX,
      PATH_NAME.USER.SIGN_UP,
    ].join('/'),
    PROFILE: [
      PATH_NAME.HOME,
      PATH_NAME.USER.INDEX,
      PATH_NAME.USER.PROFILE,
    ].join('/'),
    BOOKING_TRIP: [
      PATH_NAME.HOME,
      PATH_NAME.USER.INDEX,
      PATH_NAME.USER.PROFILE,
      PATH_NAME.USER.BOOKING_TRIP,
    ].join('/'),
    REVIEW: [
      PATH_NAME.HOME,
      PATH_NAME.USER.INDEX,
      PATH_NAME.USER.PROFILE,
      PATH_NAME.USER.REVIEW,
    ].join('/'),
  },
  PROPERTY_DETAIL: [
    PATH_NAME.HOME,
    PATH_NAME.PROPERTY_DETAIL,
    ':propertyType/:propertyId/:languageCountryCode',
  ].join('/'),
  SEARCH: [PATH_NAME.HOME, PATH_NAME.SEARCH].join('/'),
};

export const COOKIES = {
  GUEST_ID: '_guestId',
  CART_ID: '_cartId',
  AUTH: '_auth_storefront',
};

export const LAYOUT_COLUMN = {
  left: {
    colSpan: {
      md: 4,
      sm: 0,
      xs: 0,
    },
  },
  center: {
    colSpan: {
      md: 16,
      sm: 24,
      xs: 24,
    },
  },
  right: {
    colSpan: {
      md: 4,
      sm: 0,
      xs: 0,
    },
  },
};

export const DESTINATION_TYPE = {
  ACCOMMODATION: 'accommodation',
  CITY: 'city',
  STATE: 'state',
  COUNTRY: 'country',
};

export const DATE_FORMAT = {
  DEFAULT: 'DD/MM/YYYY',
  DATA_FORMAT: 'YYYY-MM-DD',
};
